import * as React from 'react';
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import {
  DevelopmentInputs,
  ListNewDevelopmentInputName,
} from '../../listNewDevelopment/models';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../../listNewDevelopment/context';
import { setInput } from '../../listNewDevelopment/actions';

// ========== TYPES ========== //

interface ComponentProps {
  flatId?: string;
  category?: keyof DevelopmentInputs;
  id: string;
  label?: string;
  name: ListNewDevelopmentInputName;
  menuItems: Record<string, string> | string[];
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      maxWidth: theme.breakpoints.values.sm,
      width: '100%',
    },
  })
);

// ========== COMPONENT ========== //

const NewPropertyFormSelect = ({
  flatId,
  category,
  id,
  label,
  name,
  menuItems,
}: ComponentProps) => {
  const classes = useStyles();

  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  const value = flatId
    ? state.propertyInputs[flatId][name].value
    : category
    ? state.developmentInputs[category][name].value
    : '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setInput({
        flatId,
        category,
        name,
        value: e.target.value,
      })
    );
  };

  const menuItemEls = Array.isArray(menuItems)
    ? menuItems.map((it) => (
        <MenuItem key={it} value={it}>
          {it}
        </MenuItem>
      ))
    : Object.entries(menuItems).map(([itLabel, itValue]) => (
        <MenuItem key={itValue} value={itValue}>
          {itLabel}
        </MenuItem>
      ));

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${id}-label`} shrink>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={handleChange}
      >
        {menuItemEls}
      </Select>
    </FormControl>
  );
};

export default NewPropertyFormSelect;
