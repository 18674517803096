import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function Calendar(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 2048 2048" {...props}>
      <g>
        <rect className="fil-0" width="2048" height="2048" />
        <rect
          className="fil-0"
          x="255.999"
          y="255.999"
          width="1536"
          height="1536"
        />
        <path
          className="fil-1"
          d="M671.525 1136l-223.524 0 -31.9996 0 -0.0011811 -32.0008 0 -224 0.0011811 -32.0008 31.9996 0 223.524 0 32.0008 0 0 32.0008 0 224 0 32.0008 -32.0008 0zm-191.523 -64.0016l159.522 0 0 -159.998 -159.522 0 0 159.998z"
        />
        <path
          className="fil-1"
          d="M302.072 448c-3.87284,0 -7.35119,1.54843 -9.82678,4.05473 -2.61732,2.64921 -4.24489,6.3815 -4.24489,10.5744l0 1282.29c0,4.22481 1.69961,8.07993 4.4315,10.8732l-0.0637796 0.0625985c2.54055,2.56654 5.97638,4.16221 9.70394,4.16221l1443.85 0c3.75827,0 7.22363,-1.62165 9.77127,-4.22599 2.65748,-2.71654 4.30985,-6.56575 4.30985,-10.8721l0 -1282.29c0,-4.19174 -1.62874,-7.92284 -4.24725,-10.5732 -2.47795,-2.50748 -5.95867,-4.05591 -9.83387,-4.05591l-337.197 0 0 -64.0016 90.8198 0 246.377 0c21.4914,0 41.0941,8.89843 55.3347,23.3079 14.0327,14.1992 22.748,33.7453 22.748,55.3229l0 1282.29c0,21.5244 -8.74253,41.2418 -22.8106,55.6217 -14.1591,14.4709 -33.7264,23.478 -55.2721,23.478l-1443.85 0c-21.5457,0 -41.1095,-9.00827 -55.2662,-23.4768l-0.125197 0c-13.989,-14.302 -22.6819,-34.0134 -22.6819,-55.6229l0 -1282.29c0,-21.5764 8.713,-41.1237 22.7457,-55.3241 14.2347,-14.4083 33.8339,-23.3067 55.3276,-23.3067l246.377 0 32.0008 0 58.8272 0 0 64.0016 -122.829 0 -214.376 0zm978.649 0l-407.795 0 -105.649 0 0 -64.0016 41.6469 0 32.0008 0 439.796 0 0 64.0016z"
        />
        <path
          className="fil-1"
          d="M1135.52 1136l-223.524 0 -32.0008 0 0 -32.0008 0 -224 0 -32.0008 32.0008 0 223.524 0 32.0008 0 0 32.0008 0 224 0 32.0008 -32.0008 0zm-191.523 -64.0016l159.522 0 0 -159.998 -159.522 0 0 159.998z"
        />
        <path
          className="fil-1"
          d="M1599.52 1136l-223.524 0 -32.0008 0 0 -32.0008 0 -224 0 -32.0008 32.0008 0 223.524 0 32.0008 0 0 32.0008 0 224 0 32.0008 -32.0008 0zm-191.523 -64.0016l159.522 0 0 -159.998 -159.522 0 0 159.998z"
        />
        <path
          className="fil-1"
          d="M671.525 1600l-223.524 0 -31.9996 0 -0.0011811 -32.0008 0 -224 0.0011811 -32.0008 31.9996 0 223.524 0 32.0008 0 0 32.0008 0 224 0 32.0008 -32.0008 0zm-191.523 -64.0016l159.522 0 0 -159.998 -159.522 0 0 159.998z"
        />
        <path
          className="fil-1"
          d="M1135.52 1600l-223.524 0 -32.0008 0 0 -32.0008 0 -224 0 -32.0008 32.0008 0 223.524 0 32.0008 0 0 32.0008 0 224 0 32.0008 -32.0008 0zm-191.523 -64.0016l159.522 0 0 -159.998 -159.522 0 0 159.998z"
        />
        <path
          className="fil-1"
          d="M1599.52 1600l-223.524 0 -32.0008 0 0 -32.0008 0 -224 0 -32.0008 32.0008 0 223.524 0 32.0008 0 0 32.0008 0 224 0 32.0008 -32.0008 0zm-191.523 -64.0016l159.522 0 0 -159.998 -159.522 0 0 159.998z"
        />
        <path
          className="fil-1"
          d="M1376.72 287.999c0,-17.6728 -14.328,-32.0008 -32.0008,-32.0008 -17.6728,0 -32.0008,14.328 -32.0008,32.0008l0 255.999c0,17.6728 14.328,32.0008 32.0008,32.0008 17.6728,0 32.0008,-14.328 32.0008,-32.0008l0 -255.999z"
        />
        <path
          className="fil-1"
          d="M735.278 287.999c0,-17.6728 -14.328,-32.0008 -32.0008,-32.0008 -17.6728,0 -32.0008,14.328 -32.0008,32.0008l0 255.999c0,17.6728 14.328,32.0008 32.0008,32.0008 17.6728,0 32.0008,-14.328 32.0008,-32.0008l0 -255.999z"
        />
      </g>
    </SvgIcon>
  );
}
