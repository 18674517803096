import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function DiningTableI(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <g>
            <g>
              <path d="M4.69917 5.06319l0 -2.59978 0.16 0 0 2.59978 -0.16 0zm-2.57167 -2.59978l0 2.59978 -0.16 0 0 -2.59978 0.16 0z" />
            </g>
            <g>
              <g>
                <g></g>
              </g>
              <g>
                <g></g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <path d="M2.74342 2.08121c0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08 -0.0441811,0 -0.08,0.0358189 -0.08,0.08l0 0.360004c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -0.360004z" />
              <path d="M2.43188 1.76967l0.463083 0c0.0441811,0 0.08,0.0358189 0.08,0.08 0,0.0860197 -0.034878,0.163909 -0.0912559,0.220287 -0.0563701,0.056378 -0.134264,0.0912559 -0.220283,0.0912559 -0.0860197,0 -0.163909,-0.034878 -0.220287,-0.0912559 -0.056378,-0.056378 -0.0912559,-0.134268 -0.0912559,-0.220287 0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm0.360272 0.16l-0.257461 0c0.00614567,0.00987008 0.0133937,0.0189843 0.0215709,0.0271614 0.0274173,0.0274173 0.065311,0.0443819 0.107161,0.0443819 0.0418504,0 0.0797441,-0.0169646 0.107157,-0.0443819 0.00817717,-0.00817717 0.0154252,-0.0172913 0.0215709,-0.0271614z" />
            </g>
            <g>
              <path d="M4.24324 2.08121c0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08 -0.0441811,0 -0.08,0.0358189 -0.08,0.08l0 0.360004c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -0.360004z" />
              <path d="M3.9317 1.76967l0.463083 0c0.0441811,0 0.08,0.0358189 0.08,0.08 0,0.0860197 -0.034878,0.163909 -0.0912559,0.220287 -0.0563701,0.056378 -0.13426,0.0912559 -0.220283,0.0912559 -0.0860197,0 -0.163909,-0.034878 -0.220287,-0.0912559 -0.056378,-0.056378 -0.0912559,-0.134268 -0.0912559,-0.220287 0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm0.360272 0.16l-0.257461 0c0.00614567,0.00987008 0.0133937,0.0189843 0.0215709,0.0271614 0.0274173,0.0274173 0.065311,0.0443819 0.107161,0.0443819 0.0418504,0 0.0797441,-0.0169646 0.107157,-0.0443819 0.00817717,-0.00817717 0.0154252,-0.0172913 0.0215709,-0.0271614z" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
