import * as React from 'react';
import {
  Box,
  Checkbox,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../../listNewDevelopment/context';
import { setInput } from '../../listNewDevelopment/actions';
import {
  DevelopmentInputs,
  ListNewDevelopmentInputName,
} from '../../listNewDevelopment/models';

// ========== TYPES ========== //

interface ComponentProps {
  flatId?: string;
  category?: keyof DevelopmentInputs;
  label?: string;
  name: ListNewDevelopmentInputName;
  icon: React.ReactNode;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxCtn: {
      display: 'flex',
      alignItems: 'center',

      height: theme.spacing(6),

      maxWidth: theme.breakpoints.values.sm,
      width: '100%',
      color: theme.palette.grey['600'],
    },

    checkbox: {
      marginRight: theme.spacing(2),
    },

    iconCtn: {
      display: 'flex',
      alignItems: 'center',
    },

    label: {
      marginLeft: theme.spacing(2),
    },
  })
);

// ========== COMPONENT ========== //

const NewPropertyFormCheckboxInput = ({
  flatId,
  category,
  label,
  name,
  icon,
}: ComponentProps) => {
  const classes = useStyles();

  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  const value = flatId
    ? state.propertyInputs[flatId][name].value
    : category
    ? state.developmentInputs[category][name].value
    : false;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setInput({
        flatId,
        category,
        name,
        value: e.target.checked,
      })
    );
  };

  return (
    <Box className={classes.checkboxCtn}>
      <Checkbox
        className={classes.checkbox}
        color="primary"
        inputProps={{
          'aria-label': label,
        }}
        checked={!!value}
        onChange={handleChange}
      />
      <Box className={classes.iconCtn}>{icon}</Box>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};

export default NewPropertyFormCheckboxInput;
