import React, { useContext } from 'react';
import { ActionCreator } from '@reduxjs/toolkit';
import useListNewDevelopment from './useListNewDevelopment';
import { ListNewDevelopmentState } from './state';

export const ListNewDevelopmentStateContext = React.createContext<
  ListNewDevelopmentState | undefined
>(undefined);
export const ListNewDevelopmentDispatchContext = React.createContext<
  ((action: ReturnType<ActionCreator<any>>) => void) | undefined
>(undefined);

export const ListNewDevelopmentProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [listNewDevelopmentState, dispatchListNewDevelopmentAction] =
    useListNewDevelopment();

  return (
    <ListNewDevelopmentStateContext.Provider value={listNewDevelopmentState}>
      <ListNewDevelopmentDispatchContext.Provider
        value={dispatchListNewDevelopmentAction}
      >
        {children}
      </ListNewDevelopmentDispatchContext.Provider>
    </ListNewDevelopmentStateContext.Provider>
  );
};

export function useListNewDevelopmentState() {
  const context = useContext(ListNewDevelopmentStateContext);

  if (context === undefined) {
    throw new Error(
      'useListNewDevelopmentState must be used within a ListNewDevelopmentProvider'
    );
  }

  return context;
}

export function useListNewDevelopmentDispatch() {
  const context = useContext(ListNewDevelopmentDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useListNewDevelopmentDispatch must be used within a ListNewDevelopmentProvider'
    );
  }

  return context;
}
