/**
 * An image upload area but for multiple images.
 *
 * Images will be displayed in a grid view, with a primary image taking the
 * largest amount of space.
 *
 * Included features:
 * - Add image
 * - Remove image
 * - Make image primary
 */

import React, { useEffect } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ImageFile, ImageUploadStatus } from '../image/types';
import Image from './Image';
import ImageUploadSuggestionArea from '../image/ImageUploadSuggestionArea';
import { executeSequentially } from '../../../../../../../utils/timer';

// ========== TYPES ========== //

interface ImageDisplayProps {
  imageFiles: ImageFile[];
  onDrop: (files: File[]) => void;
  removeImageFiles: (imageFiles: ImageFile[]) => void;
  uploadImage: (imageFile: ImageFile) => void;
  setPrimaryImage: (imageFile: ImageFile) => void;
}

interface MultipleImagesUploadAreaProps {
  imageFiles: ImageFile[];
  addImageFiles: (imageFiles: ImageFile[]) => void;
  removeImageFiles: (imageFiles: ImageFile[]) => void;
  setPrimaryImage: (imageFile: ImageFile) => void;

  idGenerator: () => string;

  shouldUpload: boolean;
  uploadImage: (imageFile: ImageFile) => void;

  onError: (err: Error) => void;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      position: 'relative',

      width: '100%',
      height: '100%',
      padding: theme.spacing(1),

      border: `3px dashed ${theme.palette.grey['400']}`,
      backgroundColor: theme.palette.grey['200'],
    },

    imgsDisplayArea: {
      display: 'grid',
      grid: 'auto-flow / repeat(4, 1fr)',
      justifyItems: 'center',
      alignItems: 'center',
      justifyContent: 'start',
      alignContent: 'stretch',
      gridGap: theme.spacing(1),

      width: '100%',
      minHeight: 300,

      '& > *:first-child': {
        gridColumn: '1 / 3',
        gridRow: '1 / 3',
      },
    },
  })
);

// ========== COMPONENT ========== //

const UPLOAD_DEBOUNCE = 250; // ms

const ImagesDisplay = ({
  imageFiles,
  onDrop,
  removeImageFiles,
  uploadImage,
  setPrimaryImage,
}: ImageDisplayProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.imgsDisplayArea}>
      {imageFiles.map((imageFile, i) => (
        <Image
          key={imageFile.id}
          imageFile={imageFile}
          imageAlt={`image ${imageFile.file?.name ?? 'unknown'}`}
          isPrimary={i === 0}
          deleteImage={() => removeImageFiles([imageFile])}
          retryUpload={() => uploadImage(imageFile)}
          makeImagePrimary={() => setPrimaryImage(imageFile)}
        />
      ))}
      <ImageUploadSuggestionArea onDrop={onDrop} />
    </Box>
  );
};

const MultipleImagesUploadArea = ({
  imageFiles,
  addImageFiles,
  removeImageFiles,
  setPrimaryImage,
  idGenerator,
  shouldUpload,
  uploadImage,
  onError,
}: MultipleImagesUploadAreaProps) => {
  const classes = useStyles();

  // Upload images after they're loaded, if all conditions are met
  useEffect(() => {
    if (imageFiles.length > 0 && shouldUpload) {
      const uploadFns = imageFiles
        .filter((f) => f.uploadStatus === ImageUploadStatus.NOT_UPLOADED)
        .map((f) => () => uploadImage(f));

      void executeSequentially(uploadFns, UPLOAD_DEBOUNCE);
    }
  }, [imageFiles, shouldUpload, uploadImage]);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      if (onError) {
        onError(new Error(`No files were accepted`));
      } else {
        console.error('No files were accepted');
      }

      return;
    }

    const newImageFiles: ImageFile[] = [];

    acceptedFiles.forEach((f) => {
      const id = idGenerator();

      newImageFiles.push({
        id,
        preview: URL.createObjectURL(f),
        uploadStatus: ImageUploadStatus.NOT_UPLOADED,
        file: f,
      });
    });

    addImageFiles(newImageFiles);
  };

  return (
    <Box className={classes.ctn}>
      <ImagesDisplay
        imageFiles={imageFiles}
        onDrop={onDrop}
        removeImageFiles={removeImageFiles}
        uploadImage={uploadImage}
        setPrimaryImage={setPrimaryImage}
      />
    </Box>
  );
};

export default MultipleImagesUploadArea;
