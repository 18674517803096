import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function SBell(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <path
            className="fil-1"
            d="M1.73573 0.853331l3.3552 0 0.0588819 0 0.0173701 0.056874 0.293961 0.962677 0.0314882 0.103126 -0.10774 0 -0.99563 0 -0.0510157 0 -0.0214843 -0.046874c-0.067685,-0.147657 -0.190811,-0.274327 -0.349339,-0.363768 -0.156417,-0.0882441 -0.347323,-0.139976 -0.554091,-0.139976 -0.206768,0 -0.397673,0.0517323 -0.554091,0.139976 -0.158528,0.0894409 -0.281654,0.21611 -0.349339,0.363768l-0.0214843 0.046874 -0.0510157 0 -0.99563 0 -0.10774 0 0.0314882 -0.103126 0.293961 -0.962677 0.0173701 -0.056874 0.0588819 0zm3.29631 0.16l-3.23743 0 -0.245102 0.802677 0.838693 0c0.0848661,-0.158181 0.221831,-0.292874 0.392906,-0.38939 0.180165,-0.101646 0.398114,-0.161228 0.632217,-0.161228 0.234102,0 0.452051,0.0595827 0.632217,0.161228 0.171075,0.0965157 0.308039,0.231209 0.392906,0.38939l0.838693 0 -0.245102 -0.802677z"
          />
          <g>
            <path
              className="fil-1"
              d="M4.58678 3.73099c0,0.225858 0.0554764,0.458177 0.144417,0.688197 0.0904646,0.233957 0.215382,0.465693 0.352142,0.686217l0.0755787 0.121874 -0.14339 0 -3.2044 0 -0.14339 0 0.0755787 -0.121874c0.13676,-0.220524 0.261677,-0.45226 0.352142,-0.686217 0.0889409,-0.23002 0.144417,-0.462339 0.144417,-0.688197 0,-0.650374 0.303575,-1.06842 0.692142,-1.25283 0.152051,-0.0721614 0.316791,-0.108248 0.481311,-0.108248 0.16452,0 0.329264,0.0360866 0.481311,0.108248 0.388567,0.184413 0.692142,0.602461 0.692142,1.25283zm-0.00433071 0.745701c-0.0958701,-0.247941 -0.155669,-0.499461 -0.155669,-0.745701 0,-0.579469 -0.263276,-0.948524 -0.600268,-1.10846 -0.130102,-0.0617441 -0.271539,-0.092626 -0.413185,-0.092626 -0.141642,0 -0.283075,0.030878 -0.413185,0.092626 -0.336992,0.159933 -0.600268,0.528988 -0.600268,1.10846 0,0.24624 -0.0597992,0.49776 -0.155669,0.745701 -0.0776929,0.200933 -0.179213,0.399406 -0.291622,0.590587l2.92149 0c-0.112409,-0.191181 -0.213929,-0.389654 -0.291622,-0.590587z"
            />
            <path
              className="fil-1"
              d="M3.41333 5.396c0.0797087,0 0.151874,0.032315 0.20411,0.0845551 0.0522402,0.0522362 0.0845551,0.124406 0.0845551,0.20411 0,0.0797008 -0.032315,0.15187 -0.0845512,0.204114 -0.0522441,0.0522362 -0.124413,0.0845512 -0.204114,0.0845512 -0.0797087,0 -0.151874,-0.032315 -0.20411,-0.0845551 -0.0522402,-0.0522362 -0.0845551,-0.124402 -0.0845551,-0.20411 0,-0.0797008 0.032315,-0.151874 0.0845551,-0.20411 0.0522362,-0.0522362 0.124406,-0.0845551 0.20411,-0.0845551zm0.0909843 0.197681c-0.0232795,-0.0232795 -0.0554488,-0.0376811 -0.0909843,-0.0376811 -0.0355354,0 -0.0677087,0.0144055 -0.0909843,0.0376811 -0.0232795,0.0232756 -0.0376811,0.0554528 -0.0376811,0.0909843 0,0.0355354 0.0144016,0.0677047 0.0376811,0.0909843 0.0232795,0.0232795 0.0554488,0.0376811 0.0909843,0.0376811 0.0355394,0 0.0677087,-0.0144016 0.0909882,-0.0376772 0.0232756,-0.0232795 0.0376772,-0.0554488 0.0376772,-0.0909882 0,-0.0355354 -0.0144016,-0.0677087 -0.0376811,-0.0909843z"
            />
          </g>
          <path
            className="fil-1"
            d="M3.41333 1.82508c0.083685,0 0.15802,0.0425827 0.210539,0.111421 0.047878,0.0627441 0.0774961,0.148118 0.0774961,0.241228 0,0.0931102 -0.0296181,0.178484 -0.0774961,0.241228 -0.0525197,0.0688386 -0.126854,0.111421 -0.210539,0.111421 -0.083685,0 -0.15802,-0.0425827 -0.210539,-0.111421 -0.047878,-0.0627441 -0.0774961,-0.148118 -0.0774961,-0.241228 0,-0.0931102 0.0296181,-0.178484 0.0774961,-0.241228 0.0525197,-0.0688386 0.126854,-0.111421 0.210539,-0.111421zm0.0836654 0.208295c-0.0227638,-0.0298386 -0.0524567,-0.0482953 -0.0836654,-0.0482953 -0.0312087,0 -0.0609016,0.0184567 -0.0836654,0.0482953 -0.0274094,0.0359213 -0.0443701,0.0868819 -0.0443701,0.144354 0,0.0574724 0.0169606,0.108433 0.0443701,0.144354 0.0227638,0.0298386 0.0524567,0.0482953 0.0836654,0.0482953 0.0312087,0 0.0609016,-0.0184567 0.0836654,-0.0482953 0.0274094,-0.0359213 0.0443701,-0.0868819 0.0443701,-0.144354 0,-0.0574724 -0.0169606,-0.108433 -0.0443701,-0.144354z"
          />
        </g>
      </g>
      <rect className="fil-1" width="6.82666" height="6.82666" />
    </SvgIcon>
  );
}
