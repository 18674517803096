import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Lift = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 510 510" {...props}>
      <path d="m45 0v510h420v-15-375-120zm30 150h165.159v330h-165.159zm195.159 0h164.841v330h-164.841zm-195.159-30v-90h360v90z" />
      <path d="m189.367 278.184-31.796 31.813-31.817-31.816-21.213 21.213 53.036 53.035 53.009-53.038z" />
      <path d="m299.562 331.823 21.218 21.207 31.796-31.814 31.818 31.817 21.212-21.213-53.036-53.036z" />
      <path d="m120 60h30v30h-30z" />
      <path d="m180 60h30v30h-30z" />
      <path d="m240 60h30v30h-30z" />
      <path d="m300 60h30v30h-30z" />
      <path d="m360 60h30v30h-30z" />
    </SvgIcon>
  );
};

export default Lift;
