/**
 * The Upload Suggestion Area suggests the user to upload the image. This is the
 * area where drag-n-drop / file select actions happen.
 *
 * It has an icon and a text component to visually guide the user.
 */

import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { AddPhotoAlternate } from '@material-ui/icons';
import { DropzoneOptions } from 'react-dropzone';
import { UploadArea } from '../../../../../../atoms';

// ========== TYPES ========== //

interface ComponentProps {
  onDrop: (acceptedFiles: File[]) => void;
  dropzoneOpts?: DropzoneOptions;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // This defines the styles for the "image upload suggestion" box, which
    // prompts the user to upload photos
    uploadSuggestionCtn: {
      position: 'relative',

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      width: '100%',
      height: '100%',
      minHeight: theme.spacing(10),

      border: `2px dashed ${theme.palette.grey['400']}`,
    },

    uploadSuggestionHintCtn: {
      position: 'absolute',
      top: 0,
      left: 0,

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      width: '100%',
      height: '100%',
    },

    uploadSuggestionUploadArea: {
      width: '100%',
      height: '100%',

      opacity: 0,

      cursor: 'pointer',
    },

    img: {},
  })
);

// ========== COMPONENT ========== //

const MAX_FILE_SIZE = 10e7; // 10Mb

const ACCEPTED_IMAGE_TYPES = 'image/jpeg, image/png, image/tiff, image/webp';

const ImageUploadSuggestionArea = ({
  onDrop,
  dropzoneOpts,
}: ComponentProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.uploadSuggestionCtn}>
      <Box className={classes.uploadSuggestionHintCtn}>
        <AddPhotoAlternate fontSize="large" />
        <Typography align="center">Add photos</Typography>
      </Box>

      <UploadArea
        onDrop={onDrop}
        ctnProps={{
          className: classes.uploadSuggestionUploadArea,
        }}
        dropzoneOpts={{
          accept: ACCEPTED_IMAGE_TYPES,
          maxSize: MAX_FILE_SIZE,
          ...dropzoneOpts,
        }}
      />
    </Box>
  );
};

export default ImageUploadSuggestionArea;
