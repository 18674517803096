import * as React from 'react';
import TabPanel from '../../../../atoms/Tabs/TabPanel/TabPanel';
import { newPropertyTabsInfo } from './TabsSelectionPanel';
import DevelopmentForm from './DevelopmentForm';
import NewPropertyFormSelect from '../inputs/NewPropertyFormSelect';
import {
  DepositLengthType,
  DevelopmentInputs,
  TermLengthType,
} from '../../listNewDevelopment/models';
import NewPropertyFormCheckboxInput from '../inputs/NewPropertyFormCheckboxInput';
import Pet from '../../../../../images/icons/Pet';
import Family from '../../../../../images/icons/Family';
import Student from '../../../../../images/icons/Student';
import NoSmoking from '../../../../../images/icons/NoSmoking';
import ShortRent from '../../../../../images/icons/ShortRent';
import ZeroDeposit from '../../../../../images/icons/ZeroDeposit';

// ========== TYPES ========== //

interface ComponentProps {
  currentTab: string | number;
}

// ========== COMPONENT ========== //

const category: keyof DevelopmentInputs = 'livingAtmosphere';

const Tab5 = ({ currentTab }: ComponentProps) => {
  return (
    <TabPanel
      currentTabIndex={currentTab}
      thisTabIndex={Object.keys(newPropertyTabsInfo)[4]}
    >
      <DevelopmentForm title="Living atmosphere">
        <NewPropertyFormCheckboxInput
          category={category}
          label="Pet friendly"
          name="isPetFriendly"
          icon={<Pet />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Family friendly"
          name="isFamilyFriendly"
          icon={<Family />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Student friendly"
          name="isStudentFriendly"
          icon={<Student />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="No smoking"
          name="isNoSmoking"
          icon={<NoSmoking />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Short rent available"
          name="canShortRent"
          icon={<ShortRent />}
        />
        <NewPropertyFormSelect
          category={category}
          label="Minimum term"
          name="minTermLengthType"
          id="min-term"
          menuItems={Object.values(TermLengthType)}
        />
        <NewPropertyFormSelect
          category={category}
          id="max-term"
          label="Maximum term"
          name="maxTermLengthType"
          menuItems={Object.values(TermLengthType)}
        />
        <NewPropertyFormSelect
          category={category}
          label="Deposit"
          name="depositLengthType"
          id="deposit-length"
          menuItems={Object.values(DepositLengthType)}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Zero-deposit available"
          name="canZeroDeposit"
          icon={<ZeroDeposit />}
        />
      </DevelopmentForm>
    </TabPanel>
  );
};

export default Tab5;
