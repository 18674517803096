/**
 * This component displays an image with frill buttons and labels.
 */

import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ImageFile, ImageUploadStatus } from '../image/types';
import clsx from 'clsx';
import {
  Delete,
  Error as ErrorIcon,
  HourglassEmpty,
  Refresh,
  Star,
  StarBorder,
} from '@material-ui/icons';

// ========== TYPES ========== //

interface ImageProps {
  imageFile: ImageFile;
  imageAlt: string;

  // If an image is 'primary', a tag will be displayed showing it as such.
  // A 'primary' image is also known as a 'thumbnail'.
  isPrimary?: boolean;
  makeImagePrimary?: (imageFile: ImageFile) => void;

  deleteImage?: (imageFile: ImageFile) => void;
  retryUpload?: (imageFile: ImageFile) => void;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      position: 'relative',
      placeSelf: 'center',

      '&:hover > $hoverBtnImgTag': {
        opacity: 90,
      },
    },

    imgCtn: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',

      backgroundColor: theme.palette.grey['100'],
      borderRadius: 3,
      boxShadow: `2px 2px 8px 0px rgba(117,117,117,0.75)`,
    },

    imgWithIndicator: {
      opacity: 0.25,
    },

    imgIndicator: {
      position: 'absolute',
      top: 0,
      left: 0,

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',

      width: '100%',
      height: '100%',

      '& > *:first-child': {
        marginBottom: theme.spacing(1),
      },

      '& > *:last-child': {
        fontWeight: 'bold',
      },
    },

    imgLoadingIndicator: {
      color: theme.palette.grey['900'],
    },

    imgErrorIndicator: {
      color: theme.palette.error.main,
    },

    imgTag: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    hoverBtnImgTag: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      backgroundColor: theme.palette.common.white,
      borderRadius: 5,
      cursor: 'pointer',
      opacity: 0,
      transition: `opacity .25s`,
    },

    makePrimaryImgTag: {
      top: 5,
      right: 5 + theme.spacing(5) + 5,
    },

    retryImgTag: {
      top: 5,
      right: 5 + theme.spacing(5 * 2) + 5 * 2,
    },

    deleteImgTag: {
      top: 5,
      right: 5,
    },

    mainPhotoImgTag: {
      top: 5,
      left: 0,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      borderRadius: `0 5px 5px 0`,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      '& > *:first-child': {
        marginRight: theme.spacing(1),
      },
    },
  })
);

// ========== COMPONENT ========== //

export default function Image({
  imageFile,
  imageAlt,
  isPrimary,
  deleteImage,
  retryUpload,
  makeImagePrimary,
}: ImageProps) {
  const classes = useStyles();

  const { id, preview } = imageFile;

  const handleMakePrimary = () => {
    if (makeImagePrimary) makeImagePrimary(imageFile);
  };

  const handleDelete = () => {
    if (deleteImage) deleteImage(imageFile);
  };

  const handleRetryUpload = () => {
    if (retryUpload) retryUpload(imageFile);
  };

  const imgHasIndicator = imageFile.uploadStatus !== ImageUploadStatus.UPLOADED;

  return (
    <Box className={classes.ctn}>
      {/* THE ACTUAL IMAGE */}

      <Box className={classes.imgCtn}>
        <img
          id={id}
          alt={imageAlt}
          src={preview}
          className={clsx(
            classes.img,
            imgHasIndicator && classes.imgWithIndicator
          )}
        />
        {imageFile.uploadStatus === ImageUploadStatus.ERROR && (
          <Box
            className={clsx(classes.imgIndicator, classes.imgErrorIndicator)}
          >
            <ErrorIcon fontSize="large" />
            <Typography color="inherit">Could not upload file</Typography>
          </Box>
        )}
        {imageFile.uploadStatus === ImageUploadStatus.UPLOADING && (
          <Box
            className={clsx(classes.imgIndicator, classes.imgLoadingIndicator)}
          >
            <HourglassEmpty fontSize="large" />
            <Typography color="inherit">Uploading image...</Typography>
          </Box>
        )}
      </Box>

      {/* TAGS E.G. MAKE PRIMARY BUTTON, DELETE BUTTON, ETC. */}

      {makeImagePrimary && !isPrimary && (
        <Box
          className={clsx(
            classes.imgTag,
            classes.hoverBtnImgTag,
            classes.makePrimaryImgTag
          )}
          onClick={handleMakePrimary}
        >
          <StarBorder fontSize="large" />
        </Box>
      )}
      {retryUpload && imageFile.uploadStatus === ImageUploadStatus.ERROR && (
        <Box
          className={clsx(
            classes.imgTag,
            classes.hoverBtnImgTag,
            classes.retryImgTag
          )}
          onClick={handleRetryUpload}
        >
          <Refresh fontSize="large" />
        </Box>
      )}
      {deleteImage && (
        <Box
          className={clsx(
            classes.imgTag,
            classes.hoverBtnImgTag,
            classes.deleteImgTag
          )}
          onClick={handleDelete}
        >
          <Delete fontSize="large" />
        </Box>
      )}
      {isPrimary && (
        <Box className={clsx(classes.imgTag, classes.mainPhotoImgTag)}>
          <Star fontSize="large" />
          <Typography>Main photo</Typography>
        </Box>
      )}
    </Box>
  );
}
