import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function HomeAppliance_WashingMachine(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 64 64" {...props}>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g>
        {' '}
        <g>
          {' '}
          <path d="M53.7075,1h-43.415C8.9346,1,7.8301,2.1045,7.8301,3.4624v57.0752C7.8301,61.8955,8.9346,63,10.2925,63h43.415			c1.3579,0,2.4624-1.1045,2.4624-2.4624V3.4624C56.1699,2.1045,55.0654,1,53.7075,1z M9.8301,55.6597V15.0269h31.1943h13.1455			v40.6328v0.2207H9.8301V55.6597z M54.1699,3.4624v9.5645H42.0244V3h11.6831C53.9624,3,54.1699,3.2075,54.1699,3.4624z			 M9.8301,3.4624C9.8301,3.2075,10.0376,3,10.2925,3h29.7319v10.0269H9.8301V3.4624z M53.7075,61h-43.415			c-0.2549,0-0.4624-0.2075-0.4624-0.4624v-2.6572h44.3398v2.6572C54.1699,60.7925,53.9624,61,53.7075,61z" />{' '}
          <path d="M45.3662,9.0117h5.4619c0.5522,0,1-0.4478,1-1s-0.4478-1-1-1h-5.4619c-0.5522,0-1,0.4478-1,1			S44.814,9.0117,45.3662,9.0117z" />{' '}
          <path d="M42.4928,34.057c-0.4749-6.3063-5.7457-11.2953-12.171-11.2953c-6.7368,0-12.2178,5.481-12.2178,12.2183			c0,6.7349,5.481,12.2144,12.2178,12.2144S42.5396,41.7148,42.5396,34.98C42.5396,34.7109,42.4993,34.0996,42.4928,34.057z			 M30.3218,24.7617c5.1789,0,9.4597,3.8762,10.1194,8.8779c-5.2538,2.7471-7.8745,1.9372-10.6351,1.0709			c-2.4592-0.7708-5.2241-1.6292-9.6912,0.0544C20.231,29.2305,24.7601,24.7617,30.3218,24.7617z M30.3218,45.1943			c-4.9961,0-9.1584-3.6054-10.0389-8.3485c4.1451-1.7214,6.4725-0.9964,8.9247-0.2272c1.4766,0.4639,2.9766,0.9346,4.8184,0.9346			c1.7532,0,3.823-0.4366,6.4696-1.7035C40.0517,41.0756,35.6624,45.1943,30.3218,45.1943z" />{' '}
          <path d="M47.5234,26.3018h-0.9575c-0.5626,0-1.0841,0.1573-1.5455,0.4111c-2.9634-5.2583-8.598-8.5928-14.7006-8.5928			c-9.2964,0-16.8599,7.5635-16.8599,16.8599s7.5635,16.8599,16.8599,16.8599c5.9944,0,11.4031-3.0969,14.4429-8.1655			c0.5157,0.3474,1.1361,0.5508,1.8032,0.5508h0.9575c1.7847,0,3.2363-1.4521,3.2363-3.2368V29.542			C50.7598,27.7554,49.3081,26.3018,47.5234,26.3018z M30.3198,49.8398c-8.1938,0-14.8599-6.666-14.8599-14.8599			s6.666-14.8599,14.8599-14.8599c5.6023,0,10.7509,3.1913,13.2642,8.1616c-0.1641,0.3876-0.2549,0.8135-0.2549,1.2603v11.4463			c0,0.3136,0.0588,0.6108,0.1425,0.8978C40.8931,46.7994,35.895,49.8398,30.3198,49.8398z M48.7598,40.9883			c0,0.6821-0.5547,1.2368-1.2363,1.2368h-0.9575c-0.6821,0-1.2368-0.5547-1.2368-1.2368V29.542			c0-0.6841,0.5547-1.2402,1.2368-1.2402h0.9575c0.6816,0,1.2363,0.5562,1.2363,1.2402V40.9883z" />{' '}
          <path d="M16.2798,10.8438c1.6265,0,2.9497-1.3232,2.9497-2.9497s-1.3232-2.9497-2.9497-2.9497s-2.9497,1.3232-2.9497,2.9497			S14.6533,10.8438,16.2798,10.8438z M16.2798,6.9443c0.5234,0,0.9497,0.4263,0.9497,0.9497s-0.4263,0.9497-0.9497,0.9497			s-0.9497-0.4263-0.9497-0.9497S15.7563,6.9443,16.2798,6.9443z" />{' '}
          <path d="M24.5693,10.8438c1.6265,0,2.9497-1.3232,2.9497-2.9497s-1.3232-2.9497-2.9497-2.9497s-2.9497,1.3232-2.9497,2.9497			S22.9429,10.8438,24.5693,10.8438z M24.5693,6.9443c0.5234,0,0.9497,0.4263,0.9497,0.9497s-0.4263,0.9497-0.9497,0.9497			s-0.9497-0.4263-0.9497-0.9497S24.0459,6.9443,24.5693,6.9443z" />{' '}
          <path d="M32.8555,10.8438c1.6265,0,2.9497-1.3232,2.9497-2.9497s-1.3232-2.9497-2.9497-2.9497			c-1.6245,0-2.9463,1.3232-2.9463,2.9497S31.231,10.8438,32.8555,10.8438z M32.8555,6.9443c0.5234,0,0.9497,0.4263,0.9497,0.9497			s-0.4263,0.9497-0.9497,0.9497c-0.522,0-0.9463-0.4263-0.9463-0.9497S32.3335,6.9443,32.8555,6.9443z" />{' '}
        </g>
      </g>
    </SvgIcon>
  );
}
