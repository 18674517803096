import * as React from 'react';
import TabPanel from '../../../../atoms/Tabs/TabPanel/TabPanel';
import { newPropertyTabsInfo } from './TabsSelectionPanel';
import PropertiesForm from './PropertiesForm';

// ========== TYPES ========== //

interface ComponentProps {
  currentTab: string | number;
}

// ========== COMPONENT ========== //

const Tab7 = ({ currentTab }: ComponentProps) => {
  return (
    <TabPanel
      currentTabIndex={currentTab}
      thisTabIndex={Object.keys(newPropertyTabsInfo)[6]}
    >
      <PropertiesForm />
    </TabPanel>
  );
};

export default Tab7;
