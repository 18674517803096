import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Pet = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 394.47 394.47" {...props}>
      <path
        id="path-1_64_"
        d="m140.882 98.618c23.306 0 42.265-22.123 42.265-49.309s-18.959-49.309-42.265-49.309-42.264 22.123-42.264 49.309 18.958 49.309 42.264 49.309zm0-70.442c7.512 0 14.088 9.878 14.088 21.132s-6.576 21.132-14.088 21.132-14.088-9.878-14.088-21.132 6.576-21.132 14.088-21.132z"
        transform="translate(4.5 1)"
      />
      <path d="m84.529 119.75c0-27.186-18.959-49.309-42.265-49.309s-42.264 22.123-42.264 49.309 18.959 49.309 42.265 49.309 42.264-22.123 42.264-49.309zm-56.353 0c0-11.254 6.576-21.132 14.088-21.132s14.088 9.878 14.088 21.132-6.576 21.132-14.088 21.132-14.088-9.878-14.088-21.132z" />
      <path
        id="path-1_63_"
        d="m253.588 98.618c23.306 0 42.265-22.123 42.265-49.309s-18.959-49.309-42.265-49.309-42.265 22.123-42.265 49.309 18.959 49.309 42.265 49.309zm0-70.442c7.512 0 14.088 9.878 14.088 21.132s-6.576 21.132-14.088 21.132-14.088-9.877-14.088-21.131 6.576-21.133 14.088-21.133z"
        transform="translate(8.5 1)"
      />
      <path d="m352.206 70.441c-23.306 0-42.265 22.123-42.265 49.309s18.959 49.309 42.265 49.309 42.265-22.123 42.265-49.309-18.959-49.309-42.265-49.309zm0 70.441c-7.512 0-14.088-9.878-14.088-21.132s6.576-21.132 14.088-21.132 14.088 9.878 14.088 21.132-6.576 21.132-14.088 21.132z" />
      <path d="m305.071 193.204c-14.13-47.754-57.481-80.498-107.836-80.498s-93.706 32.744-107.835 80.498c-37.436 17.28-61.223 54.042-61.223 95.605 0 58.265 47.396 105.662 105.662 105.662 23.045 0 45.126-7.484 63.397-21.297 18.271 13.813 40.352 21.297 63.397 21.297 58.265 0 105.662-47.396 105.662-105.662-.001-41.563-23.789-78.325-61.224-95.605zm-44.439 173.09c-20.004 0-39.045-7.732-53.629-21.765-2.724-2.628-6.246-3.935-9.768-3.935s-7.044 1.307-9.768 3.935c-14.584 14.033-33.625 21.765-53.629 21.765-42.732 0-77.485-34.753-77.485-77.485 0-32.249 19.605-60.577 49.928-72.161 4.403-1.678 7.663-5.448 8.709-10.03 8.833-38.701 42.65-65.736 82.246-65.736s73.413 27.035 82.246 65.736c1.046 4.581 4.306 8.351 8.709 10.03 30.323 11.584 49.928 39.912 49.928 72.161-.001 42.732-34.754 77.485-77.487 77.485z" />
    </SvgIcon>
  );
};

export default Pet;
