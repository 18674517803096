import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function SRealEstate(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <path
            className="fil-1"
            d="M0.96735 3.48341l4.89196 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 2.32992c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-4.89196 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -2.32992c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm4.81196 0.16l-4.73196 0 0 2.16992 4.73196 0 0 -2.16992z"
          />
          <path
            className="fil-1"
            d="M1.25776 3.48342l2.19886 0 0 -1.41805 -2.19886 0 0 1.41805zm2.27886 0.16l-2.35886 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.57805c0,-0.0441811 0.0358189,-0.08 0.08,-0.08l2.35886 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 1.57805c0,0.0441811 -0.0358189,0.08 -0.08,0.08z"
          />
          <path
            className="fil-1"
            d="M0.922787 1.91892l1.38703 -1.04929 -7.48031e-005 -9.44882e-005c0.0304961,-0.0230709 0.072189,-0.0209606 0.100134,0.00287795l1.38691 1.0492 -0.048126 0.063752 0.0482638 -0.0637992c0.0352323,0.0266535 0.042189,0.0768307 0.0155354,0.112063 -0.0157165,0.0207717 -0.0396142,0.0317126 -0.0637992,0.0317323l0 3.93701e-006 -0.000503937 0 -2.78081 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08 0,-0.027685 0.014063,-0.0520827 0.035437,-0.0664488zm1.43522 -0.885425l-1.1525 0.871874 2.30501 0 -1.15251 -0.871874z"
          />
          <path
            className="fil-1"
            d="M2.358 1.29983c0.0656614,0 0.125114,0.026622 0.16815,0.0696575 0.0430354,0.0430315 0.0696575,0.102488 0.0696575,0.16815 0,0.0656575 -0.026622,0.125114 -0.0696575,0.168146 -0.0430354,0.0430354 -0.102488,0.0696575 -0.16815,0.0696575 -0.0656654,0 -0.125114,-0.026622 -0.168146,-0.0696575 -0.0430354,-0.0430315 -0.0696575,-0.102488 -0.0696575,-0.168146 0,-0.0656614 0.026622,-0.125118 0.0696575,-0.16815 0.0430315,-0.0430354 0.10248,-0.0696575 0.168146,-0.0696575zm0.0550236 0.182783c-0.0140748,-0.0140748 -0.0335315,-0.0227835 -0.0550236,-0.0227835 -0.0214882,0 -0.0409488,0.00870866 -0.0550197,0.0227835 -0.0140748,0.0140709 -0.0227835,0.0335315 -0.0227835,0.0550236 0,0.0214882 0.00870866,0.0409488 0.0227835,0.0550197 0.0140709,0.0140748 0.0335315,0.0227835 0.0550197,0.0227835 0.0214921,0 0.0409488,-0.00870866 0.0550236,-0.0227835 0.0140748,-0.0140709 0.0227835,-0.0335315 0.0227835,-0.0550197 0,-0.0214921 -0.00870866,-0.0409528 -0.0227835,-0.0550236z"
          />
          <g>
            <path
              className="fil-1"
              d="M1.81312 2.30277l1.08813 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 0.783236c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-1.08813 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -0.783236c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm1.00813 0.16l-0.92813 0 0 0.623236 0.92813 0 0 -0.623236z"
            />
          </g>
          <g>
            <g>
              <path
                className="fil-1"
                d="M2.17743 4.40504l0 0.947591c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-0.783236 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -0.947591c0,-0.0441811 0.0358189,-0.08 0.08,-0.08l0.783236 0c0.0441811,0 0.08,0.0358189 0.08,0.08zm-0.16 0.867591l0 -0.787591 -0.623236 0 0 0.787591 0.623236 0z"
              />
            </g>
            <path
              className="fil-1"
              d="M2.47143 4.32504l0.928744 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 1.48829c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-0.928744 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.48829c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm0.848744 0.16l-0.768744 0 0 1.32829 0.768744 0 0 -1.32829z"
            />
          </g>
          <path
            className="fil-1"
            d="M2.79828 4.86531c0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08 -0.0441811,0 -0.08,0.0358189 -0.08,0.08l0 0.182358c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -0.182358z"
          />
          <path
            className="fil-1"
            d="M3.53661 3.03233c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l2.31711 0c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-2.31711 0z"
          />
          <path
            className="fil-1"
            d="M5.9393 3.11233c0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08 -0.0441811,0 -0.08,0.0358189 -0.08,0.08l0 0.451087c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -0.451087z"
          />
        </g>
      </g>
      <rect className="fil-0" width="6.82666" height="6.82666" />
    </SvgIcon>
  );
}
