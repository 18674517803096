import * as React from 'react';
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';

// ========== TYPES ========== //

interface ComponentProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContentRoot: {
      padding: 0,
    },
  })
);

// ========== COMPONENT ========== //

const FormDialog = ({ title, open, handleClose, children }: ComponentProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>

      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
