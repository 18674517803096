import * as React from 'react';
import TabPanel from '../../../../atoms/Tabs/TabPanel/TabPanel';
import { newPropertyTabsInfo } from './TabsSelectionPanel';
import DevelopmentForm from './DevelopmentForm';
import NewPropertyFormAddressInput from '../inputs/NewPropertyFormAddressInput';
import NewPropertyFormTextInput from '../inputs/NewPropertyFormTextInput';
import NewPropertyFormNumberInput from '../inputs/NewPropertyFormNumberInput';
import NewPropertyFormSelect from '../inputs/NewPropertyFormSelect';
import { setInput } from '../../listNewDevelopment/actions';
import {
  DevelopmentInputs,
  PropertyType,
} from '../../listNewDevelopment/models';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../../listNewDevelopment/context';
import NewPropertyFormTextAreaInput from '../inputs/NewPropertyFormTextAreaInput';
import useAutocompleteServices from '../../../../../api/googleMaps/gcpPlaces/hooks/useAutocompleteServices';

// ========== TYPES ========== //

interface ComponentProps {
  currentTab: string | number;
}

// ========== COMPONENT ========== //

const category: keyof DevelopmentInputs = 'basicDetails';

const Tab1 = ({ currentTab }: ComponentProps) => {
  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  const { autocompleteService, placesService } = useAutocompleteServices({
    googleMapsAttributeElementId: 'google-maps-att-el',
  });

  const handleAddressInputChangeFactory = (valueName: string) => {
    return (value: any) => {
      dispatch(
        setInput({
          category,
          name: 'address',
          valueName,
          value,
        })
      );
    };
  };

  return (
    <TabPanel
      currentTabIndex={currentTab}
      thisTabIndex={Object.keys(newPropertyTabsInfo)[0]}
    >
      <DevelopmentForm title="Add background information">
        <NewPropertyFormTextInput
          category={category}
          label="Development name"
          name="title"
        />
        <NewPropertyFormAddressInput
          variant="outlined"
          label="Development address"
          inputValue={state.developmentInputs.basicDetails.address.value}
          setInputValue={handleAddressInputChangeFactory('value')}
          autocompleteValue={
            state.developmentInputs.basicDetails.address.autocomplete
          }
          setAutocompleteValue={handleAddressInputChangeFactory('autocomplete')}
          predictions={state.developmentInputs.basicDetails.address.predictions}
          setPredictions={handleAddressInputChangeFactory('predictions')}
          placeDetails={
            state.developmentInputs.basicDetails.address.placeDetails
          }
          setPlaceDetails={handleAddressInputChangeFactory('placeDetails')}
          autocompleteService={autocompleteService}
          placesService={placesService}
        />
        <NewPropertyFormTextAreaInput
          category={category}
          label="Description"
          name="description"
        />
        <NewPropertyFormTextInput
          category={category}
          label="Owner"
          name="owner"
        />
        <NewPropertyFormTextInput
          category={category}
          label="Property manager"
          name="propertyManager"
        />
        <NewPropertyFormNumberInput
          category={category}
          label="Number of units in development"
          name="unitsCount"
          min={0}
          max={9999}
        />
        <NewPropertyFormSelect
          category={category}
          id="property-type"
          label="Property type"
          name="propertyType"
          menuItems={Object.values(PropertyType)}
        />
        <NewPropertyFormNumberInput
          category={category}
          label="Year of completion"
          name="completionYear"
          min={0}
          max={3000}
        />
      </DevelopmentForm>
    </TabPanel>
  );
};

export default Tab1;
