import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

// ========== TYPES ========== //

interface ComponentProps {
  title: string;
  children?: React.ReactNode;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentCtn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      padding: `0 ${theme.spacing(4)}px`,

      '& > *': {
        width: '100%',
      },

      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },

    titleCtn: {
      '& > *:first-child': {
        marginBottom: theme.spacing(1),
      },
    },

    formCtn: {
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },

    btnCtn: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    btn: {
      width: theme.spacing(15),
    },

    hidden: {
      visibility: 'hidden',
    },
  })
);

// ========== COMPONENT ========== //

const DevelopmentForm = ({ title, children }: ComponentProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.contentCtn}>
      <Box className={classes.titleCtn}>
        <Typography variant="h4">{title}</Typography>
      </Box>

      <Box className={classes.formCtn}>{children}</Box>
    </Box>
  );
};

export default DevelopmentForm;
