import * as React from 'react';
import ConnectedAddressAutocomplete, {
  ConnectedAddressAutocompleteProps as AddressInputProps,
} from '../../../../widgets/ConnectedAddressAutocomplete/ConnectedAddressAutocomplete';
import { Box, useTheme } from '@material-ui/core';

// ========== TYPES ========== //

type ComponentProps = AddressInputProps;

// ========== COMPONENT ========== //

const NewPropertyFormAddressInput = (props: ComponentProps) => {
  const theme = useTheme();

  return (
    <Box maxWidth={theme.breakpoints.values.sm}>
      <ConnectedAddressAutocomplete
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        {...props}
      />
    </Box>
  );
};

export default NewPropertyFormAddressInput;
