import React, { useCallback } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import FormDialog from '../layout/FormDialog';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../../listNewDevelopment/context';
import {
  addImages,
  makeImagePrimary,
  removeImages,
  uploadImageFailure,
} from '../../listNewDevelopment/actions';
import { ImageFile } from '../../../my-account/property/editDEPRECATED/file-upload/image/types';
import { v4 as uuidv4 } from 'uuid';
import useAuthToken from '../../../../../store/auth/hooks/useAuthToken';
import MultipleImagesUploadArea from '../../../my-account/property/editDEPRECATED/file-upload/imageDEPRECATED/MultipleImagesUploadArea';
import { uploadUserImages } from '../../listNewDevelopment/utils';

// ========== TYPES ========== //

interface ComponentProps {
  propertyId: string;
  dialogOpen: boolean;
  closeDialog: () => void;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      width: '100%',
      height: 500,

      [theme.breakpoints.up('mm')]: {
        width: theme.breakpoints.values.mm,
      },
    },
  })
);

// ========== COMPONENT ========== //

export default function PropertyImagesDialog({
  propertyId,
  dialogOpen,
  closeDialog,
}: ComponentProps) {
  const classes = useStyles();

  const authToken = useAuthToken();

  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  const imageFilesInput = state.propertyInputs[propertyId].imageFiles;

  // Notice how we go from fileIds. This maintain the order of the images
  const imageFiles = imageFilesInput.fileIds.map(
    (id) => imageFilesInput.fileDataById[id]
  );

  const handleAddImageFiles = (imageFiles: ImageFile[]) => {
    dispatch(
      addImages({
        flatId: propertyId,
        inputName: 'imageFiles',
        fileData: imageFiles,
      })
    );
  };

  const handleRemoveImageFiles = (imageFiles: ImageFile[]) => {
    dispatch(
      removeImages({
        flatId: propertyId,
        inputName: 'imageFiles',
        fileData: imageFiles,
      })
    );
  };

  const handleSetPrimaryImage = (imageFile: ImageFile) => {
    dispatch(
      makeImagePrimary({
        flatId: propertyId,
        inputName: 'imageFiles',
        fileData: imageFile,
      })
    );
  };

  const idGenerator = () => `prp-${uuidv4()}`;

  const handleUploadImage = useCallback(
    (imageFile: ImageFile) => {
      if (!authToken) {
        dispatch(
          uploadImageFailure({
            flatId: propertyId,
            inputName: 'imageFiles',
            imageFile,
            failureResponse: new Error(`Not logged in`),
          })
        );
        return;
      }

      void uploadUserImages(dispatch, {
        propertyId,
        inputName: 'imageFiles',
        imageFile,
        authToken,
      });
    },
    [authToken, propertyId]
  );

  const handleError = (err: Error) => {
    console.error(err);
  };

  return (
    <FormDialog
      title="Upload property-specific images"
      open={dialogOpen}
      handleClose={closeDialog}
    >
      <Box className={classes.ctn}>
        <MultipleImagesUploadArea
          imageFiles={imageFiles}
          addImageFiles={handleAddImageFiles}
          removeImageFiles={handleRemoveImageFiles}
          setPrimaryImage={handleSetPrimaryImage}
          idGenerator={idGenerator}
          shouldUpload={true}
          uploadImage={handleUploadImage}
          onError={handleError}
        />
      </Box>
    </FormDialog>
  );
}
