import * as React from 'react';
import TabPanel from '../../../../atoms/Tabs/TabPanel/TabPanel';
import { newPropertyTabsInfo } from './TabsSelectionPanel';
import DevelopmentForm from './DevelopmentForm';
import NewPropertyFormSelect from '../inputs/NewPropertyFormSelect';
import {
  BedSizeType,
  DevelopmentInputs,
  HobType,
  KitchenType,
} from '../../listNewDevelopment/models';
import NewPropertyFormCheckboxInput from '../inputs/NewPropertyFormCheckboxInput';
import Bed from '../../../../../images/icons/Bed';
import Wardrobe from '../../../../../images/icons/Wardrobe';
import ChestofDrawer from '../../../../../images/icons/ChestofDrawer';
import Sofa from '../../../../../images/icons/Sofa';
import Tea from '../../../../../images/icons/Tea';
import TV from '../../../../../images/icons/TV';
import Dining from '../../../../../images/icons/Dining';
import Fridge from '../../../../../images/icons/Fridge';
import Oven from '../../../../../images/icons/Oven';
import Microwave from '../../../../../images/icons/Microwave';
import Dishwasher from '../../../../../images/icons/Dishwasher';
import WashingMachine from '../../../../../images/icons/WashingMachine';

// ========== TYPES ========== //

interface ComponentProps {
  currentTab: string | number;
}

// ========== COMPONENT ========== //

const category: keyof DevelopmentInputs = 'propertyFacilities';

const Tab3 = ({ currentTab }: ComponentProps) => {
  return (
    <TabPanel
      currentTabIndex={currentTab}
      thisTabIndex={Object.keys(newPropertyTabsInfo)[2]}
    >
      <DevelopmentForm title="Add property facilities">
        <NewPropertyFormCheckboxInput
          category={category}
          label="Bed & mattress"
          name="hasBed"
          icon={<Bed />}
        />
        <NewPropertyFormSelect
          category={category}
          id="bed-size"
          label="Bed size"
          name="bedSizeType"
          menuItems={Object.values(BedSizeType)}
        />

        <NewPropertyFormCheckboxInput
          category={category}
          label="Built-in wardrobe"
          name="hasWardrobe"
          icon={<Wardrobe />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Chest of drawer"
          name="hasDrawer"
          icon={<ChestofDrawer />}
        />
        <NewPropertyFormSelect
          category={category}
          id="kitchen-type"
          label="Kitchen type"
          name="kitchenType"
          menuItems={Object.values(KitchenType)}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Sofa"
          name="hasSofa"
          icon={<Sofa />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Tea table"
          name="hasTeaTable"
          icon={<Tea />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="TV"
          name="hasTv"
          icon={<TV />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Dining table & chairs"
          name="hasDiningTable"
          icon={<Dining />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Fridge"
          name="hasFridge"
          icon={<Fridge />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Oven"
          name="hasOven"
          icon={<Oven />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Microwave"
          name="hasMicrowave"
          icon={<Microwave />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Dishwasher"
          name="hasDishwasher"
          icon={<Dishwasher />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Washing machine"
          name="hasWashingMachine"
          icon={<WashingMachine />}
        />
        <NewPropertyFormSelect
          category={category}
          id="hob"
          label="Kitchen hob"
          name="hobType"
          menuItems={Object.values(HobType)}
        />
      </DevelopmentForm>
    </TabPanel>
  );
};

export default Tab3;
