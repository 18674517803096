import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function Cupboard(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <path d="M1.47576 0.853331l3.87515 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 4.55007c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-3.87515 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -4.55007c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm3.79515 0.16l-3.71515 0 0 4.39007 3.71515 0 0 -4.39007z" />
        <path d="M3.49333 0.933331c0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08 -0.0441811,0 -0.08,0.0358189 -0.08,0.08l0 4.55007c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -4.55007z" />
        <path d="M5.28566 4.23115c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-3.74465 0c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l3.74465 0z" />
        <g>
          <g>
            <path d="M3.84887 2.32898c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l0.307441 0c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-0.307441 0z" />
          </g>
          <g>
            <path d="M2.97779 2.48898c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-0.307441 0c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l0.307441 0z" />
          </g>
        </g>
        <g>
          <path d="M3.85201 4.71479c0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08 -0.0441811,0 -0.08,0.0358189 -0.08,0.08l0 0.204965c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -0.204965z" />
          <path d="M3.13465 4.71479c0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08 -0.0441811,0 -0.08,0.0358189 -0.08,0.08l0 0.204965c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -0.204965z" />
        </g>
        <g>
          <path d="M1.83251 5.40341l0.409925 0c0.0441811,0 0.08,0.0358189 0.08,0.08 0,0.0262953 -0.012689,0.049626 -0.0322756,0.0642087 -0.0633583,0.0549173 -0.10735,0.103524 -0.138799,0.157445 -0.0321496,0.0551339 -0.0529528,0.118862 -0.0697008,0.203583l-0.078437 -0.015311 0.0784803 0.0155157c-0.00762992,0.0386024 -0.0418465,0.065185 -0.0798622,0.0644843l-0.169331 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -0.409925c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm0.221866 0.16l-0.141866 0 0 0.249925 0.0267126 0c0.0181811,-0.072063 0.0413583,-0.132272 0.0740118,-0.188272 0.0124134,-0.0212835 0.0260433,-0.0416969 0.0411417,-0.0616535z" />
          <path d="M4.91415 5.56341l-0.141866 0c0.0150984,0.0199567 0.0287283,0.0403701 0.0411417,0.0616535 0.0326535,0.056 0.0558307,0.116209 0.0740118,0.188272l0.0267126 0 0 -0.249925zm-0.329925 -0.16l0.409925 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 0.409925c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-0.169331 0c-0.0380157,0.000700787 -0.0722323,-0.0258819 -0.0798622,-0.0644843l0.0784803 -0.0155157 -0.078437 0.015311c-0.016748,-0.0847205 -0.0375512,-0.148449 -0.0697008,-0.203583 -0.0314488,-0.0539213 -0.0754409,-0.102528 -0.138799,-0.157445 -0.0195866,-0.0145827 -0.0322756,-0.0379134 -0.0322756,-0.0642087 0,-0.0441811 0.0358189,-0.08 0.08,-0.08z" />
        </g>
      </g>
    </SvgIcon>
  );
}
