import { ListNewDevelopmentState } from './state';
import { ActionCreator } from '@reduxjs/toolkit';
import {
  ImageFile,
  ImageUploadStatus,
} from '../../my-account/property/editDEPRECATED/file-upload/image/types';
import {
  uploadImageFailure,
  uploadImageRequest,
  uploadImageSuccess,
} from './actions';
import { newPropertyTabsInfo } from '../ListNewDevelopmentForm/layout/TabsSelectionPanel';
import { MyDetails } from '../../../../store/auth/models';
import { AddressInput, PrimitiveInput } from './models';

export enum CategoryStatus {
  NOT_STARTED,
  STARTED,
  COMPLETED,
  NOT_APPLICABLE,
}

const REQUIRED_INPUTS: Record<string, string[]> = {
  basicDetails: [
    'title',
    'address',
    // 'owner',
    // 'propertyManager',
    // 'unitsCount',
    // 'propertyType',
    // 'completionYear',
  ],
  // developmentFacilities: ['heatingType', 'hotWaterType', 'epcType'],
  // propertyFacilities: ['kitchenType', 'bedSizeType', 'hobType'],
};

/**
 * Given an input category (e.g. development description), return information on
 * whether all required inputs have been filled out or not.
 *
 * Note: only works for a few development input categories at the moment.
 */
export function getMissingInputs({
  categoryName,
  state,
}: {
  categoryName: string;
  state: ListNewDevelopmentState;
}): { missingInputs?: string[]; categoryStatus: CategoryStatus } {
  const requiredInputs = REQUIRED_INPUTS[categoryName];

  if (!requiredInputs) {
    return {
      missingInputs: [],
      categoryStatus: CategoryStatus.NOT_APPLICABLE,
    };
  }

  const inputsState = state.developmentInputs[categoryName];

  const missingInputs: string[] = [];

  requiredInputs.forEach((inputName) => {
    if (!inputsState[inputName].value) {
      missingInputs.push(inputName);
    }
  });

  const categoryStatus =
    missingInputs.length === 0
      ? CategoryStatus.COMPLETED
      : missingInputs.length === requiredInputs.length
      ? CategoryStatus.NOT_STARTED
      : CategoryStatus.STARTED;

  return {
    missingInputs,
    categoryStatus,
  };
}

/**
 * Upload images to the user images API.
 *
 * xmlHttpRequest is used to monitor progress.
 */
export function uploadUserImages(
  dispatch: (action: ReturnType<ActionCreator<any>>) => void,
  data: {
    propertyId?: string;
    inputName: 'floorPlanFiles' | 'imageFiles';
    imageFile: ImageFile;
    authToken: string;
  }
) {
  try {
    let url = `${process.env.GATSBY_API_HOST}/api/user-images?clientId=${data.imageFile.id}`;

    if (data.propertyId) url += `&propertyId=${data.propertyId}`;

    const req = new XMLHttpRequest();

    req.addEventListener('error', () => {
      console.error(`Error making the image upload request`);
    });

    req.addEventListener('loadstart', () => {
      console.log(`Image upload request started`);
    });

    req.addEventListener('progress', () => {
      console.log(`Image upload request progress...`);
    });

    req.addEventListener('load', () => {
      if (req.status !== 200) {
        dispatch(
          uploadImageFailure({
            flatId: data.propertyId,
            inputName: data.inputName,
            imageFile: data.imageFile,
            failureResponse: new Error(
              `${req.status} - ${req.statusText} - ${req.response}`
            ),
          })
        );
      } else {
        dispatch(
          uploadImageSuccess({
            flatId: data.propertyId,
            inputName: data.inputName,
            imageFile: data.imageFile,
            successResponse: req.response,
          })
        );
      }
    });

    req.open('POST', url);

    req.setRequestHeader('Authorization', `Bearer ${data.authToken}`);
    req.setRequestHeader('Accept', 'application/json');

    const formData = new FormData();

    formData.append(
      'images',
      data.imageFile.file ?? '',
      data.imageFile.file?.name ?? 'untitled'
    );

    dispatch(
      uploadImageRequest({
        flatId: data.propertyId,
        inputName: data.inputName,
        imageFile: data.imageFile,
      })
    );

    req.send(formData);
  } catch (err) {
    dispatch(
      uploadImageFailure({
        flatId: data.propertyId,
        inputName: data.inputName,
        imageFile: data.imageFile,
        failureResponse: err,
      })
    );
  }
}

/**
 * Call the API to list a new development. Validations are in place to ensure
 * all necessary information is provided.
 *
 * Will return an error if encounter any issue along the way.
 */
export async function listNewDevelopment(
  state: ListNewDevelopmentState,
  authToken: string,
  authProfile: MyDetails | null
): Promise<Record<string, any> | Error> {
  // ---------- VALIDATION - MISSING INPUTS ---------- //

  const missingInputsTextArr: string[] = [];

  // Populate the previously created missing inputs array
  Object.keys(REQUIRED_INPUTS).forEach((categoryName) => {
    const { missingInputs } = getMissingInputs({ categoryName, state });

    if (missingInputs && missingInputs.length > 0) {
      missingInputsTextArr.push(
        `You still need to fill in these information from tab ${
          newPropertyTabsInfo[categoryName].label
        }: ${missingInputs.join(', ').trim()}`
      );
    }
  });

  if (missingInputsTextArr.length > 0) {
    return new Error(
      `Could not publish new development. Reason: ${missingInputsTextArr
        .join('. ')
        .trim()}`
    );
  }

  console.log('Missing inputs validation completes ✔️');

  // ---------- VALIDATION - LOGGED IN ---------- //

  if (!authToken || !authProfile) {
    return new Error(
      `Could not publish new development. Reason: you are not logged in. Please log in.`
    );
  }

  // ---------- VALIDATION - BELONG TO AN ORGANIZATION ---------- //

  const userOrganizations = authProfile.user.organization_ids;

  if (userOrganizations.length === 0) {
    return new Error(
      `Could not publish new development. Reason: you don't belong to an organization. Please create your own organization or join one.`
    );
  }

  // ---------- PUBLISHING - CLEANSE DATA ---------- //

  // ----- Flatten and cleanse development data ----- //

  // Loop through each development inputs category and bring data up 1 level.
  // Ignore all file data.
  const developmentData = Object.values(state.developmentInputs).reduce(
    (developmentData, inputs) => {
      Object.entries(inputs).forEach(([inputName, input]) => {
        // We only populate normal, primitive inputs at this step.
        if (inputName !== 'imageFiles') {
          developmentData[inputName] = (
            input as PrimitiveInput<any> | AddressInput
          ).value;
        }
      });

      return developmentData;
    },
    {}
  );

  // Append development image IDs (only those that have been successfully
  // uploaded)
  developmentData.imgIds = state.developmentInputs.imageFiles.fileIds.filter(
    (id) =>
      state.developmentInputs.imageFiles.fileDataById[id].uploadStatus ===
      ImageUploadStatus.UPLOADED
  );

  // Append development address' geometry data. Note that this could be
  // undefined so an additional check is performed
  const addressGeometry =
    state?.developmentInputs?.basicDetails?.address?.placeDetails?.geometry?.location.toJSON();
  if (!addressGeometry) {
    return new Error(
      `Could not publish new development. Reason: invalid address. Please check the address field in the Basic Details section.`
    );
  }
  developmentData.geometry = addressGeometry;

  // ----- Cleanse properties data ----- //

  const propertiesData = Object.entries(state.propertyInputs).reduce(
    (propertiesData, [propertyId, propertyInputs]) => {
      propertiesData[propertyId] = {};

      Object.entries(propertyInputs).forEach(([inputName, input]) => {
        // We only populate normal, primitive inputs at this step.
        if (inputName !== 'imageFiles' && inputName !== 'floorPlanFiles') {
          propertiesData[propertyId][inputName] = (
            input as PrimitiveInput<any>
          ).value;
        }

        // Append property image and floor plan image IDs (only those that have
        // been successfully uploaded)
        if (inputName === 'imageFiles') {
          propertiesData[propertyId].imgIds = propertyInputs[
            inputName
          ].fileIds.filter(
            (id) =>
              propertyInputs[inputName].fileDataById[id].uploadStatus ===
              ImageUploadStatus.UPLOADED
          );
        }
        if (inputName === 'floorPlanFiles') {
          propertiesData[propertyId].floorPlanIds = propertyInputs[
            inputName
          ].fileIds.filter(
            (id) =>
              propertyInputs[inputName].fileDataById[id].uploadStatus ===
              ImageUploadStatus.UPLOADED
          );
        }
      });

      return propertiesData;
    },
    {}
  );

  // Loop through each property and ignore all

  console.log('Data cleansing completes ✔️');
  console.log(`Here is the cleansed data:`);
  console.log({
    developmentData,
    propertiesData,
  });

  // ---------- PUBLISHING - SUBMIT ---------- //

  console.log('All checks complete, ready for submission ✔️');

  try {
    const url = `${process.env.GATSBY_API_HOST}/api/development`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        'BTR-Organization': `${userOrganizations[0]}`,
      },
      body: JSON.stringify({ developmentData, propertiesData }),
    });

    if (!res.ok) {
      const msg = await res.text();

      return new Error(`${res.status} - ${res.statusText} - ${msg}`);
    }

    const json = await res.json();

    console.log('Development created successfully ✔️');
    console.log('Response from server: ');
    console.log(json);

    return json;
  } catch (err) {
    return new Error((err as Error).message);
  }
}
