import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function Subscribers(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 2048 2048" {...props}>
      <g>
        <rect className="fil-0" width="2048" height="2048" />
        <rect
          className="fil-0"
          x="255.999"
          y="255.999"
          width="1536"
          height="1536"
        />
        <rect
          className="fil-0"
          x="255.999"
          y="255.999"
          width="1536"
          height="1536"
        />
        <path
          className="fil-1"
          d="M1019.13 520.715c-44.2441,0 -84.28,18.0036 -113.229,47.041 -29.0421,29.1307 -47.0209,69.4004 -47.0209,113.797 0,44.3481 17.9811,84.5859 47.0268,113.7 28.9453,29.0126 68.9788,47.0055 113.223,47.0055 44.3374,0 84.5776,-18.0638 113.717,-47.1863 29.0705,-29.054 47.0965,-69.2233 47.0965,-113.519 0,-44.2618 -18.0709,-84.4678 -47.2158,-113.615 -29.1437,-29.1473 -69.3438,-47.2229 -113.597,-47.2229zm-158.479 2.04095c40.6429,-40.767 96.6887,-66.0426 158.479,-66.0426 61.9217,0 118.13,25.252 158.848,65.9741 40.7185,40.7221 65.967,96.9379 65.967,158.865 0,61.8012 -25.2992,117.995 -66.0981,158.77 -40.7185,40.6973 -96.8871,65.9375 -158.717,65.9375 -61.7847,0 -117.827,-25.2662 -158.473,-66.0071 -40.6276,-40.7244 -65.778,-96.8942 -65.778,-158.7 0,-61.8497 25.1481,-118.049 65.7721,-158.797z"
        />
        <path
          className="fil-1"
          d="M1388.33 764.28c-27.1524,0 -51.7134,11.0339 -69.4973,28.8201 -17.8335,17.8358 -28.8579,42.4571 -28.8579,69.5481 0,27.1217 10.9843,51.7217 28.7504,69.4784 17.7815,17.7721 42.3768,28.8047 69.6048,28.8047 27.1252,0 51.7595,-11.0693 69.6012,-28.8921 17.7803,-17.7626 28.8142,-42.3272 28.8142,-69.391 0,-27.1158 -11.0268,-51.6969 -28.7965,-69.4607 -17.8453,-17.8382 -42.4855,-28.9075 -69.619,-28.9075zm-114.748 -16.4303c29.3563,-29.3599 69.906,-47.5713 114.748,-47.5713 44.6268,0 85.1977,18.248 114.619,47.6587 29.4957,29.4851 47.7981,70.0902 47.7981,114.711 0,44.5713 -18.3095,85.1646 -47.8158,114.641 -29.4225,29.3906 -69.9851,47.6433 -114.601,47.6433 -44.7461,0 -85.2485,-18.215 -114.605,-47.5559 -29.511,-29.4945 -47.752,-70.1268 -47.752,-114.729 0,-44.7331 18.1866,-85.3725 47.6091,-114.799z"
        />
        <path
          className="fil-1"
          d="M1331 1546.09c-46.78,14.8453 -95.0422,26.2347 -144.482,33.8587 -48.443,7.47048 -98.0587,11.3492 -148.564,11.3492 -57.4075,0 -113.743,-5.02441 -168.577,-14.6563 -55.8591,-9.81024 -110.282,-24.5103 -162.801,-43.6441l-21.1252 -7.69725 0 -22.3028 0 -288.874c0,-91.9241 37.3843,-175.373 97.8249,-235.831 60.4406,-60.4583 143.818,-97.9229 235.844,-97.9229 92.0776,0 175.584,37.4433 236.101,97.8603 60.6178,60.5174 98.1544,143.985 98.1544,235.893l0 301.467 0 23.3977 -22.376 7.10197zm-154.233 -29.3918c38.4189,-5.92441 76.0087,-14.2547 112.608,-24.8268l0 -277.748c0,-74.393 -30.2705,-141.842 -79.1528,-190.643 -48.9213,-48.8398 -116.508,-79.1091 -191.101,-79.1091 -74.5111,0 -141.881,30.1949 -190.593,78.9213 -48.8552,48.8693 -79.0737,116.391 -79.0737,190.831l0 266.269c42.1725,14.1697 85.8993,25.3418 130.925,33.2492 51.0863,8.97402 103.741,13.6547 157.576,13.6547 47.2193,0 93.5764,-3.62244 138.813,-10.598z"
        />
        <path
          className="fil-1"
          d="M1320.72 999.461c10.4906,-3.10394 21.2693,-5.72717 32.3398,-7.57678 11.6669,-1.94882 23.4449,-3.07796 35.2666,-3.07796 241.017,0 240.437,237.666 240.037,401.035 -0.0448819,20.0103 0.0956694,-48.8185 0.0956694,18.1595l-7.50119 20.5004c-24.7169,29.6209 -52.0335,48.4996 -87.0343,61.5119 -32.6599,12.1406 -70.9205,18.5799 -120.063,23.9398l-6.75119 -63.5008c44.3681,-4.83898 78.1111,-10.3547 104.564,-20.1886 20.6729,-7.68426 37.5272,-18.4264 52.7859,-34.3618 0.00708662,-18.9024 0.0366142,-6.41103 0.0744095,-0.0779528l0.0602363 -0.0625985 0.0177165 -5.91969c0.335433,-137.297 0.82441,-337.034 -176.286,-337.034 -8.65985,0 -16.8969,0.761812 -24.7666,2.07638 -8.46497,1.41378 -16.7492,3.43229 -24.8398,5.82638l-18 -61.2497z"
        />
        <path
          className="fil-1"
          d="M637.645 700.278c44.8418,0 85.3914,18.2114 114.748,47.5713 29.4225,29.426 47.6091,70.0654 47.6091,114.799 0,44.602 -18.241,85.2343 -47.752,114.729 -29.3563,29.341 -69.8587,47.5559 -114.605,47.5559 -44.6162,0 -85.1788,-18.2528 -114.601,-47.6433 -29.5063,-29.4768 -47.8158,-70.0701 -47.8158,-114.641 0,-44.6209 18.3024,-85.226 47.7981,-114.711 29.4213,-29.4107 69.9922,-47.6587 114.619,-47.6587zm69.4973 92.8217c-17.7839,-17.7862 -42.3449,-28.8201 -69.4973,-28.8201 -27.1335,0 -51.7737,11.0693 -69.619,28.9075 -17.7697,17.7638 -28.7965,42.3449 -28.7965,69.4607 0,27.0638 11.0339,51.6284 28.8142,69.391 17.8417,17.8228 42.476,28.8921 69.6012,28.8921 27.228,0 51.8233,-11.0327 69.6048,-28.8047 17.7662,-17.7567 28.7504,-42.3567 28.7504,-69.4784 0,-27.091 -11.0244,-51.7122 -28.8579,-69.5481z"
        />
        <path
          className="fil-1"
          d="M687.251 1060.71c-8.09056,-2.3941 -16.3748,-4.4126 -24.8398,-5.82638 -7.86969,-1.31457 -16.1067,-2.07638 -24.7666,-2.07638 -177.11,0 -176.621,199.736 -176.286,337.034l0.0177165 5.91969 0.0602363 0.0625985c0.0377953,-6.33544 0.0673229,-18.8244 0.0744095,0.0779528 15.2575,15.9366 32.113,26.6776 52.7859,34.3618 26.4532,9.83387 60.1961,15.3496 104.564,20.1886l-6.75119 63.5008c-49.1422,-5.35985 -87.4028,-11.7992 -120.063,-23.9398 -35.0008,-13.0122 -62.3174,-31.891 -87.0343,-61.5119l-7.50119 -20.5004c0,-66.978 0.140551,1.85079 0.0956694,-18.1595 -0.400394,-163.369 -0.980316,-401.035 240.037,-401.035 11.8217,0 23.5996,1.12913 35.2666,3.07796 11.0705,1.84961 21.8492,4.47284 32.3398,7.57678l-18 61.2497z"
        />
      </g>
    </SvgIcon>
  );
}
