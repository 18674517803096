import * as React from 'react';
import TabPanel from '../../../../atoms/Tabs/TabPanel/TabPanel';
import { newPropertyTabsInfo } from './TabsSelectionPanel';
import DevelopmentForm from './DevelopmentForm';
import NewPropertyFormSelect from '../inputs/NewPropertyFormSelect';
import {
  DevelopmentInputs,
  EpcRatingType,
  HeatingType,
  HotWaterType,
} from '../../listNewDevelopment/models';
import NewPropertyFormCheckboxInput from '../inputs/NewPropertyFormCheckboxInput';
import Concierge from '../../../../../images/icons/Concierge';
import Maintenance from '../../../../../images/icons/Maintenance';
import Administrator from '../../../../../images/icons/Administrator';
import Gym from '../../../../../images/icons/Gym';
import CommonArea from '../../../../../images/icons/CommonArea';
import Bike from '../../../../../images/icons/Bike';
import CCTV from '../../../../../images/icons/CCTV';
import AccessControl from '../../../../../images/icons/AccessControl';
import Electricity from '../../../../../images/icons/Electricity';
import Water from '../../../../../images/icons/Water';
import Internet from '../../../../../images/icons/Internet';
import Lift from '../../../../../images/icons/Lift';
import Alarm from '../../../../../images/icons/Alarm';
import CouncilTax from '../../../../../images/icons/CouncilTax';
import { Typography } from '@material-ui/core';

// ========== TYPES ========== //

interface ComponentProps {
  currentTab: string | number;
}

// ========== COMPONENT ========== //

const category: keyof DevelopmentInputs = 'developmentFacilities';

const Tab2 = ({ currentTab }: ComponentProps) => {
  return (
    <TabPanel
      currentTabIndex={currentTab}
      thisTabIndex={Object.keys(newPropertyTabsInfo)[1]}
    >
      <DevelopmentForm title="Add development facilities">
        <NewPropertyFormCheckboxInput
          category={category}
          label="Concierge"
          name="hasConcierge"
          icon={<Concierge />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Onsite maintenance (daytime)"
          name="hasOnsiteMaintenance"
          icon={<Maintenance />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Onsite manager (daytime)"
          name="hasOnsiteManager"
          icon={<Administrator />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Gym"
          name="hasGym"
          icon={<Gym />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Common area"
          name="hasCommonArea"
          icon={<CommonArea />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Bike storage"
          name="hasBikeStorage"
          icon={<Bike />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Lift"
          name="hasLift"
          icon={<Lift />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="CCTV"
          name="hasCctv"
          icon={<CCTV />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Controlled gate access"
          name="hasGateAccess"
          icon={<AccessControl />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Smoke detector"
          name="hasSmokeDetector"
          icon={<Alarm />}
        />
        <NewPropertyFormSelect
          category={category}
          label="EPC Rating"
          name="epcType"
          id="epc-rating"
          menuItems={Object.values(EpcRatingType)}
        />
        <NewPropertyFormSelect
          category={category}
          label="Heating"
          name="heatingType"
          id="heating-type"
          menuItems={Object.values(HeatingType)}
        />
        <NewPropertyFormSelect
          category={category}
          label="Hot water"
          name="hotWaterType"
          id="hot-water"
          menuItems={Object.values(HotWaterType)}
        />

        <Typography variant="h4">Bills included:</Typography>

        <NewPropertyFormCheckboxInput
          category={category}
          label="Electricity"
          name="includeElectricityBill"
          icon={<Electricity />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Water"
          name="includeWaterBill"
          icon={<Water />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Internet"
          name="includeInternetBill"
          icon={<Internet />}
        />
        <NewPropertyFormCheckboxInput
          category={category}
          label="Council tax"
          name="includeCouncilTax"
          icon={<CouncilTax />}
        />
      </DevelopmentForm>
    </TabPanel>
  );
};

export default Tab2;
