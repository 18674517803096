import { v4 as uuidv4 } from 'uuid';
import {
  BedroomType,
  BedSizeType,
  CouncilTaxBandType,
  DepositLengthType,
  DevelopmentInputs,
  EpcRatingType,
  HeatingType,
  HobType,
  HotWaterType,
  KitchenType,
  PropertyInputs,
  PropertyType,
  TermLengthType,
} from './models';

export interface ListNewDevelopmentState {
  developmentInputs: DevelopmentInputs;
  propertyInputs: Record<string, PropertyInputs>;
}

/**
 * Return an empty property input object
 */
export function getNewPropertyInputs(): PropertyInputs {
  return {
    title: {
      value: '',
    },
    level: {
      value: 0,
    },
    bedroomCount: {
      value: BedroomType.null,
    },
    maxPax: {
      value: 0,
    },
    Sqm: {
      value: 0,
    },
    longTermRent: {
      value: 0,
    },
    shortTermRent: {
      value: 0,
    },
    availableDate: {
      value: null,
    },
    furnished: {
      value: false,
    },
    councilTaxBandType: {
      value: CouncilTaxBandType.null,
    },
    imageFiles: {
      fileIds: [],
      fileDataById: {},
    },
    floorPlanFiles: {
      fileIds: [],
      fileDataById: {},
    },
  };
}

export const initialListNewDevelopmentState: ListNewDevelopmentState = {
  developmentInputs: {
    basicDetails: {
      title: { value: '' },
      address: {
        value: '',
        autocomplete: null,
        predictions: null,
        placeDetails: null,
      },
      owner: { value: '' },
      propertyManager: { value: '' },
      unitsCount: { value: 0 },
      propertyType: { value: PropertyType.null },
      completionYear: { value: 0 },
      description: { value: '' },
    },

    developmentFacilities: {
      heatingType: { value: HeatingType.null },
      hotWaterType: { value: HotWaterType.null },
      epcType: { value: EpcRatingType.null },
      hasConcierge: { value: false },
      hasOnsiteManager: { value: false },
      hasOnsiteMaintenance: { value: false },
      hasGym: { value: false },
      hasCommonArea: { value: false },
      hasBikeStorage: { value: false },
      hasLift: { value: false },
      hasCctv: { value: false },
      hasGateAccess: { value: false },
      hasSmokeDetector: { value: false },
      includeElectricityBill: { value: false },
      includeWaterBill: { value: false },
      includeInternetBill: { value: false },
      includeCouncilTax: { value: false },
    },

    propertyFacilities: {
      kitchenType: { value: KitchenType.null },
      bedSizeType: { value: BedSizeType.null },
      hobType: { value: HobType.null },
      hasTv: { value: false },
      hasBed: { value: false },
      isEnsuite: { value: false },
      hasWardrobe: { value: false },
      hasDrawer: { value: false },
      hasSofa: { value: false },
      hasTeaTable: { value: false },
      hasDiningTable: { value: false },
      hasFridge: { value: false },
      hasOven: { value: false },
      hasMicrowave: { value: false },
      hasWashingMachine: { value: false },
      hasDishwasher: { value: false },
    },

    livingAtmosphere: {
      isPetFriendly: { value: false },
      isFamilyFriendly: { value: false },
      isStudentFriendly: { value: false },
      isNoSmoking: { value: false },
      canShortRent: { value: false },
      canZeroDeposit: { value: false },
      minTermLengthType: { value: TermLengthType.null },
      maxTermLengthType: { value: TermLengthType.null },
      depositLengthType: { value: DepositLengthType.null },
    },

    legal: {
      noticePeriod: { value: '' },
      keyLossFee: { value: '' },
      tenantChangeFee: { value: '' },
      latePaymentFee: { value: '' },
    },

    imageFiles: {
      fileIds: [],
      fileDataById: {},
    },
  },

  propertyInputs: {
    [uuidv4()]: getNewPropertyInputs(),
  },
};
