import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function HomeAppliance_Oven(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 64 64" {...props}>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g>
        {' '}
        <g>
          {' '}
          <path d="M9.4741,44.3101h28.6655c1.7563,0,3.1851-1.4287,3.1851-3.1851V18.0073c0-1.7563-1.4287-3.1851-3.1851-3.1851H9.4741			c-1.7563,0-3.1851,1.4287-3.1851,3.1851V41.125C6.2891,42.8813,7.7178,44.3101,9.4741,44.3101z M8.2891,18.0073			c0-0.6533,0.5317-1.1851,1.1851-1.1851h28.6655c0.6533,0,1.1851,0.5317,1.1851,1.1851V41.125			c0,0.6533-0.5317,1.1851-1.1851,1.1851H9.4741c-0.6533,0-1.1851-0.5317-1.1851-1.1851V18.0073z" />{' '}
          <path d="M54.2217,24.5767c2.5723,0,4.6646-2.0923,4.6646-4.6646s-2.0923-4.6646-4.6646-4.6646s-4.6646,2.0923-4.6646,4.6646			S51.6494,24.5767,54.2217,24.5767z M54.2217,17.2476c1.4692,0,2.6646,1.1953,2.6646,2.6646c0,0.4594-0.1274,0.8854-0.333,1.2631			l-3.1222-3.7943C53.6825,17.3021,53.9446,17.2476,54.2217,17.2476z M51.8881,18.6527l3.1202,3.7919			c-0.2502,0.0781-0.511,0.1321-0.7866,0.1321c-1.4692,0-2.6646-1.1953-2.6646-2.6646			C51.5571,19.4542,51.6838,19.0295,51.8881,18.6527z" />{' '}
          <path d="M53.9556,37.3613c2.5723,0,4.6646-2.0923,4.6646-4.6646s-2.0923-4.6646-4.6646-4.6646s-4.6646,2.0923-4.6646,4.6646			S51.3833,37.3613,53.9556,37.3613z M53.9556,30.0322c1.4692,0,2.6646,1.1953,2.6646,2.6646c0,0.4595-0.1276,0.8856-0.3332,1.2634			l-3.1221-3.7946C53.4163,30.0867,53.6785,30.0322,53.9556,30.0322z M51.622,31.4374l3.1199,3.7919			c-0.2502,0.078-0.5108,0.132-0.7863,0.132c-1.4692,0-2.6646-1.1953-2.6646-2.6646C51.291,32.2389,51.4177,31.8142,51.622,31.4374z			" />{' '}
          <path d="M56.5171,41.4595h-5.123c-0.5522,0-1,0.4478-1,1s0.4478,1,1,1h5.123c0.5522,0,1-0.4478,1-1			S57.0693,41.4595,56.5171,41.4595z" />{' '}
          <path d="M62,8.5962H2c-0.5522,0-1,0.4478-1,1v39.9399c0,0.5522,0.4478,1,1,1h1.437v3.8677c0,0.5522,0.4478,1,1,1h8.1934			c0.5522,0,1-0.4478,1-1v-3.8677h36.7393v3.8677c0,0.5522,0.4478,1,1,1h8.1934c0.5522,0,1-0.4478,1-1v-3.8677H62			c0.5522,0,1-0.4478,1-1V9.5962C63,9.0439,62.5522,8.5962,62,8.5962z M11.6304,53.4038H5.437v-2.8677h6.1934V53.4038z			 M44.6138,48.5361H3V10.5962h41.6138V48.5361z M58.563,53.4038h-6.1934v-2.8677h6.1934V53.4038z M61,48.5361H46.6138V10.5962H61			V48.5361z" />{' '}
          <path d="M17.6743,29.6416c0.2559,0,0.5117-0.0977,0.707-0.293l3.813-3.8135c0.3906-0.3906,0.3906-1.0239,0-1.4141			c-0.3906-0.3906-1.0234-0.3906-1.4141,0l-3.813,3.8135c-0.3906,0.3906-0.3906,1.0239,0,1.4141			C17.1626,29.5439,17.4185,29.6416,17.6743,29.6416z" />{' '}
          <path d="M18.4053,34.9697c0.1953,0.1953,0.4512,0.293,0.707,0.293s0.5122-0.0977,0.707-0.293l9.3892-9.3931			c0.3906-0.3906,0.3906-1.0239,0-1.4141c-0.3906-0.3906-1.0244-0.3906-1.4141,0l-9.3892,9.3931			C18.0146,33.9463,18.0146,34.5796,18.4053,34.9697z" />{' '}
          <path d="M25.4194,33.5972c-0.3906,0.3906-0.3906,1.0234,0,1.4141c0.1953,0.1953,0.4512,0.293,0.707,0.293			s0.5117-0.0977,0.707-0.293l3.813-3.813c0.3906-0.3906,0.3906-1.0234,0-1.4141s-1.0234-0.3906-1.4141,0L25.4194,33.5972z" />{' '}
        </g>
      </g>
      <g></g>
    </SvgIcon>
  );
}
