import React, { useCallback } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import OneImageUploadArea from '../../../my-account/property/editDEPRECATED/file-upload/image/OneImageUploadArea';
import FormDialog from '../layout/FormDialog';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../../listNewDevelopment/context';
import {
  setImageFilesInput,
  uploadImageFailure,
} from '../../listNewDevelopment/actions';
import { ImageFile } from '../../../my-account/property/editDEPRECATED/file-upload/image/types';
import { v4 as uuidv4 } from 'uuid';
import useAuthToken from '../../../../../store/auth/hooks/useAuthToken';
import { uploadUserImages } from '../../listNewDevelopment/utils';

// ========== TYPES ========== //

interface ComponentProps {
  propertyId: string;
  dialogOpen: boolean;
  closeDialog: () => void;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      width: '100%',
      height: 500,

      [theme.breakpoints.up('sm')]: {
        width: theme.breakpoints.values.sm,
      },
    },
  })
);

// ========== COMPONENT ========== //

export default function FloorPlanDialog({
  propertyId,
  dialogOpen,
  closeDialog,
}: ComponentProps) {
  const classes = useStyles();

  const authToken = useAuthToken();

  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  const floorPlanInput = state.propertyInputs[propertyId].floorPlanFiles;

  const imageId =
    floorPlanInput.fileIds.length > 0 ? floorPlanInput.fileIds[0] : null;
  const imageFile = imageId ? floorPlanInput.fileDataById[imageId] : null;

  const setImageFile = (imageFile: ImageFile | null) => {
    if (imageFile) {
      dispatch(
        setImageFilesInput({
          flatId: propertyId,
          inputName: 'floorPlanFiles',
          fileData: [imageFile],
        })
      );
    } else {
      dispatch(
        setImageFilesInput({
          flatId: propertyId,
          inputName: 'floorPlanFiles',
          fileData: [],
        })
      );
    }
  };

  const idGenerator = () => `flp-${uuidv4()}`;

  const handleUploadImage = useCallback(
    (imageFile: ImageFile) => {
      if (!authToken) {
        dispatch(
          uploadImageFailure({
            flatId: propertyId,
            inputName: 'floorPlanFiles',
            imageFile,
            failureResponse: new Error(`Not logged in`),
          })
        );
        return;
      }

      void uploadUserImages(dispatch, {
        propertyId,
        inputName: 'floorPlanFiles',
        imageFile,
        authToken,
      });
    },
    [authToken, propertyId]
  );

  const handleError = (err: Error) => {
    console.error(err);
  };

  return (
    <FormDialog
      title="Upload floor plan"
      open={dialogOpen}
      handleClose={closeDialog}
    >
      <Box className={classes.ctn}>
        <OneImageUploadArea
          imageFile={imageFile}
          setImageFile={setImageFile}
          idGenerator={idGenerator}
          shouldUpload={true}
          uploadImage={handleUploadImage}
          onError={handleError}
        />
      </Box>
    </FormDialog>
  );
}
