import { OrgFileCategory } from '../../../../../../../api/orgs/types';

export enum ImageUploadStatus {
  NOT_UPLOADED,
  UPLOADING,
  UPLOADED,
  ERROR,
}

// This describes the File object that's created when the user drops (or select)
// a file from their filesystem into our upload area.
export interface ImageFile {
  id: string; // UUID

  // This is the data URL of the image. It helps us display the image preview.
  // It could also be a public URL for an image. Essentially this is passed to
  // the underlying <img>'s 'src' property.
  preview: string;

  // Metadata for our database
  category?: OrgFileCategory;
  priority?: number;
  description?: string;

  // The actual File
  file?: File;

  // TODO: deprecate
  uploadStatus: ImageUploadStatus;
}
