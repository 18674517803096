import * as React from 'react';
import TabPanel from '../../../../atoms/Tabs/TabPanel/TabPanel';
import { newPropertyTabsInfo } from './TabsSelectionPanel';
import DevelopmentForm from './DevelopmentForm';
import NewPropertyFormTextInput from '../inputs/NewPropertyFormTextInput';
import { DevelopmentInputs } from '../../listNewDevelopment/models';

// ========== TYPES ========== //

interface ComponentProps {
  currentTab: string | number;
}

// ========== COMPONENT ========== //

const category: keyof DevelopmentInputs = 'legal';

const Tab6 = ({ currentTab }: ComponentProps) => {
  return (
    <TabPanel
      currentTabIndex={currentTab}
      thisTabIndex={Object.keys(newPropertyTabsInfo)[5]}
    >
      <DevelopmentForm title="Terms of tenancy">
        <NewPropertyFormTextInput
          category={category}
          label="Notice period"
          name="noticePeriod"
        />
        <NewPropertyFormTextInput
          category={category}
          label="Key loss"
          name="keyLossFee"
        />
        <NewPropertyFormTextInput
          category={category}
          label="Change of tenant"
          name="tenantChangeFee"
        />
        <NewPropertyFormTextInput
          category={category}
          label="Late payment interest"
          name="latePaymentFee"
        />
      </DevelopmentForm>
    </TabPanel>
  );
};

export default Tab6;
