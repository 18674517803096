import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Water = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="m512 255.498c0-24.25-19.728-43.979-43.978-43.979h-1.373v-161.481c0-27.591-22.446-50.038-50.038-50.038s-50.038 22.447-50.038 50.038v35.722h30v-35.722c0-11.049 8.989-20.038 20.038-20.038s20.038 8.989 20.038 20.038v105.774h-26.845v30h26.845v25.707h-392.671c-24.25.001-43.978 19.729-43.978 43.979v45.841h30.601v36.429c0 52.761 29.813 98.682 73.473 121.825l-9.668 22.407h-34.055v30h53.784l17.837-41.338c11.606 3.184 23.815 4.894 36.417 4.894h175.223c12.603 0 24.812-1.71 36.417-4.894l17.837 41.338h53.784v-30h-34.055l-9.669-22.407c43.66-23.143 73.473-69.064 73.473-121.825v-36.429h30.601zm-261.155-13.978v131.146h-99.318v-131.146zm-220.845 29.819v-15.841c0-7.708 6.271-13.979 13.978-13.979h77.549v29.819h-90.926-.601zm421.399 66.429c0 59.435-48.354 107.788-107.788 107.788h-175.222c-59.434 0-107.788-48.354-107.788-107.788v-36.429h60.926v101.326h159.318v-101.326h170.554zm30.601-66.429h-.601-200.554v-29.819h187.177c7.707 0 13.978 6.271 13.978 13.979z" />
    </SvgIcon>
  );
};

export default Water;
