import React, { useCallback } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../../listNewDevelopment/context';
import {
  addImages,
  makeImagePrimary,
  removeImages,
  uploadImageFailure,
} from '../../listNewDevelopment/actions';
import { ImageFile } from '../../../my-account/property/editDEPRECATED/file-upload/image/types';
import { v4 as uuidv4 } from 'uuid';
import useAuthToken from '../../../../../store/auth/hooks/useAuthToken';
import MultipleImagesUploadArea from '../../../my-account/property/editDEPRECATED/file-upload/imageDEPRECATED/MultipleImagesUploadArea';
import { uploadUserImages } from '../../listNewDevelopment/utils';

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      width: '100%',
    },
  })
);

// ========== COMPONENT ========== //

export default function DevelopmentImagesSection() {
  const classes = useStyles();

  const authToken = useAuthToken();

  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  const imageFilesInput = state.developmentInputs.imageFiles;

  // Notice how we go from fileIds. This maintain the order of the images
  const imageFiles = imageFilesInput.fileIds.map(
    (id) => imageFilesInput.fileDataById[id]
  );

  const handleAddImageFiles = (imageFiles: ImageFile[]) => {
    dispatch(
      addImages({
        inputName: 'imageFiles',
        fileData: imageFiles,
      })
    );
  };

  const handleRemoveImageFiles = (imageFiles: ImageFile[]) => {
    dispatch(
      removeImages({
        inputName: 'imageFiles',
        fileData: imageFiles,
      })
    );
  };

  const handleSetPrimaryImage = (imageFile: ImageFile) => {
    dispatch(
      makeImagePrimary({
        inputName: 'imageFiles',
        fileData: imageFile,
      })
    );
  };

  const idGenerator = () => `dvl-${uuidv4()}`;

  const handleUploadImage = useCallback(
    (imageFile: ImageFile) => {
      if (!authToken) {
        dispatch(
          uploadImageFailure({
            inputName: 'imageFiles',
            imageFile,
            failureResponse: new Error(`Not logged in`),
          })
        );
        return;
      }

      void uploadUserImages(dispatch, {
        inputName: 'imageFiles',
        imageFile,
        authToken,
      });
    },
    [authToken]
  );

  const handleError = (err: Error) => {
    console.error(err);
  };

  return (
    <Box className={classes.ctn}>
      <MultipleImagesUploadArea
        imageFiles={imageFiles}
        addImageFiles={handleAddImageFiles}
        removeImageFiles={handleRemoveImageFiles}
        setPrimaryImage={handleSetPrimaryImage}
        idGenerator={idGenerator}
        shouldUpload={true}
        uploadImage={handleUploadImage}
        onError={handleError}
      />
    </Box>
  );
}
