import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function Cycle(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <path
          className="fil-1"
          d="M4.84997 2.66457c0.280732,0 0.534909,0.113807 0.718898,0.297795 0.183992,0.183992 0.297799,0.438165 0.297799,0.718894 0,0.280732 -0.113807,0.534909 -0.297795,0.718894 -0.183988,0.183984 -0.438165,0.297791 -0.718902,0.297791 -0.280728,0 -0.534902,-0.113803 -0.718886,-0.297791 -0.183984,-0.183988 -0.297787,-0.438161 -0.297787,-0.718894 0,-0.280728 0.113803,-0.534906 0.297791,-0.718894 0.183984,-0.183988 0.438154,-0.297795 0.718882,-0.297795zm0.568063 0.44863c-0.14537,-0.14537 -0.346217,-0.235291 -0.568063,-0.235291 -0.221843,0 -0.422681,0.0899213 -0.568047,0.235291 -0.14537,0.14537 -0.235287,0.346217 -0.235287,0.568059 0,0.221846 0.0899173,0.422689 0.235283,0.568059 0.145366,0.14537 0.346209,0.235287 0.568051,0.235287 0.22185,0 0.422697,-0.0899213 0.568067,-0.235287 0.14537,-0.145366 0.235291,-0.346213 0.235291,-0.568059 0,-0.221843 -0.0899213,-0.422685 -0.235295,-0.568059z"
        />
        <path
          className="fil-1"
          d="M1.75432 2.66457c0.561492,0 1.01668,0.455209 1.01668,1.01669 0,0.280732 -0.113803,0.534906 -0.297791,0.718894 -0.183984,0.183988 -0.438157,0.297791 -0.71889,0.297791 -0.280736,0 -0.534909,-0.113803 -0.718898,-0.297791 -0.183988,-0.183988 -0.297791,-0.438161 -0.297791,-0.718894 0,-0.280728 0.113803,-0.534906 0.297795,-0.718898 0.183988,-0.183988 0.438161,-0.297791 0.718894,-0.297791zm0.803343 1.01669c0,-0.443657 -0.359673,-0.80335 -0.803343,-0.80335 -0.221846,0 -0.422689,0.0899173 -0.568059,0.235287 -0.145374,0.145374 -0.235291,0.34622 -0.235291,0.568063 0,0.221846 0.0899173,0.422689 0.235287,0.568059 0.14537,0.14537 0.346213,0.235287 0.568063,0.235287 0.221846,0 0.422689,-0.0899173 0.568055,-0.235287 0.14537,-0.14537 0.235287,-0.346213 0.235287,-0.568059z"
        />
        <path
          className="fil-1"
          d="M2.20935 2.34339l1.84677 0c0.0589094,0 0.106669,0.0477598 0.106669,0.106669 0,0.0169094 -0.00394488,0.0328976 -0.0109488,0.0471063l-0.532693 1.27846c-0.0225512,0.0543071 -0.0848622,0.0800512 -0.139169,0.0575 -0.0128701,-0.00534252 -0.0241339,-0.0129252 -0.0335315,-0.0221024l-1.33479 -1.22746c-0.0355,-0.0326654 -0.0437441,-0.083878 -0.0235669,-0.125106l0.0245945 -0.0529764c0.0179528,-0.0389252 0.056437,-0.0618583 0.0966654,-0.0618858l0 -0.000200787zm1.68698 0.213339l-1.49931 0 1.08397 0.996811 0.415343 -0.996811z"
        />
        <path
          className="fil-1"
          d="M5.00134 3.67563c0.0326732,0.0490157 0.0194291,0.115244 -0.0295866,0.147917 -0.0490157,0.0326732 -0.115244,0.0194291 -0.147917,-0.0295866l-0.856472 -1.28473c-0.0326732,-0.0490157 -0.0194291,-0.115244 0.0295866,-0.147917 0.0490157,-0.0326732 0.115244,-0.0194291 0.147917,0.0295866l0.856472 1.28473z"
        />
        <path
          className="fil-1"
          d="M4.91259 3.62812c0.0589094,0 0.106669,0.0477598 0.106669,0.106669 0,0.0589094 -0.0477598,0.106669 -0.106669,0.106669l-1.39178 0c-0.0589094,0 -0.106669,-0.0477598 -0.106669,-0.106669 0,-0.0589094 0.0477598,-0.106669 0.106669,-0.106669l1.39178 0z"
        />
        <path
          className="fil-1"
          d="M1.79869 3.66939c-0.0230079,0.0540748 -0.0855079,0.0792598 -0.139583,0.056252 -0.0540748,-0.0230079 -0.0792598,-0.0855079 -0.056252,-0.139583l0.591602 -1.38077 -0.00469685 -0.000783465c-0.038185,-0.00629921 -0.0765354,-0.0108268 -0.112453,-0.0124882 -0.0596063,-0.00276772 -0.100992,0.00572835 -0.127673,0.0207992 -0.0142638,0.00805512 -0.0232205,0.0180079 -0.0277008,0.0284567 -0.00453543,0.0105787 -0.00533071,0.0224685 -0.00300787,0.0340079 0.00283465,0.0140787 0.0101378,0.0275118 0.0209449,0.0378701 0.0423386,0.0407283 0.0436417,0.108079 0.00291339,0.150417 -0.0407283,0.0423386 -0.108079,0.0436417 -0.150417,0.00291339 -0.0419252,-0.0401969 -0.0704291,-0.0932323 -0.0817717,-0.149531 -0.0105866,-0.0525236 -0.00638189,-0.107992 0.0155039,-0.159008 0.0219409,-0.0511535 0.0608465,-0.0970709 0.11937,-0.130126 0.0593898,-0.0335472 0.138976,-0.0530433 0.241004,-0.0483031 0.0455157,0.0021063 0.0922047,0.0075315 0.137453,0.0149921 0.0513307,0.00846457 0.10102,0.0194606 0.145173,0.0311614l0.000748031 0.00019685 0.000216535 5.90551e-005c0.00490945,0.00129528 0.00977559,0.00295669 0.0145827,0.005 0.0540748,0.0230079 0.0792598,0.0855079 0.056252,0.139583l-0.642209 1.49888z"
        />
        <path
          className="fil-1"
          d="M4.15404 2.49131c-0.0227795,0.0540748 -0.0850945,0.0794449 -0.139169,0.0566654 -0.0540748,-0.0227795 -0.0794449,-0.0850945 -0.0566654,-0.139169l0.136028 -0.321185c0.0227795,-0.0540748 0.0850945,-0.0794449 0.139169,-0.0566654 0.0540748,0.0227795 0.0794449,0.0850945 0.0566654,0.139169l-0.136028 0.321185z"
        />
        <path
          className="fil-1"
          d="M3.85245 2.23554c-0.0589094,0 -0.106669,-0.0477598 -0.106669,-0.106669 0,-0.0589094 0.0477598,-0.106669 0.106669,-0.106669l0.538138 0c0.0589094,0 0.106669,0.0477598 0.106669,0.106669 0,0.0589094 -0.0477598,0.106669 -0.106669,0.106669l-0.538138 0z"
        />
      </g>
      <g>
        <rect className="fil-0" width="6.82666" height="6.82666" />
      </g>
    </SvgIcon>
  );
}
