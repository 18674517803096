import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function DoubleBedI(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <path d="M0.933331 3.15167l4.96 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 1.40188c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-4.96 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.40188c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm4.88 0.16l-4.8 0 0 1.24188 4.8 0 0 -1.24188z" />
          <path d="M1.20219 1.7498l4.42228 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 1.40187c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-4.42228 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.40187c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm4.34228 0.16l-4.26228 0 0 1.24187 4.26228 0 0 -1.24187z" />
          <g>
            <path d="M1.4846 4.55355l0.532079 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 0.363315c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-0.532079 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -0.363315c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm0.452079 0.16l-0.372079 0 0 0.203315 0.372079 0 0 -0.203315z" />
            <path d="M4.80998 4.55355l0.532079 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 0.363315c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-0.532079 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -0.363315c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm0.452079 0.16l-0.372079 0 0 0.203315 0.372079 0 0 -0.203315z" />
          </g>
          <g>
            <path d="M1.91433 2.70076l0.909374 0c0.0705197,0 0.134575,0.0287953 0.180957,0.0751772 0.046378,0.046378 0.0751732,0.110437 0.0751732,0.180957l0 0.274776c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-1.26164 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -0.274776c0,-0.0705197 0.0287953,-0.134579 0.0751772,-0.180957 0.046378,-0.0463819 0.110437,-0.0751772 0.180957,-0.0751772zm0.909374 0.16l-0.909374 0c-0.0263504,0 -0.0503701,0.0108425 -0.0678307,0.0283031 -0.0174606,0.0174606 -0.0283031,0.0414803 -0.0283031,0.0678307l0 0.194776 1.10164 0 0 -0.194776c0,-0.0263504 -0.0108425,-0.050374 -0.0282992,-0.0678307 -0.0174606,-0.0174606 -0.0414803,-0.0283031 -0.0678307,-0.0283031z" />
            <path d="M4.00295 2.70076l0.909374 0c0.0705197,0 0.134575,0.0287953 0.180957,0.0751772 0.046378,0.046378 0.0751732,0.110437 0.0751732,0.180957l0 0.274776c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-1.26164 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -0.274776c0,-0.0705197 0.0287953,-0.134575 0.0751772,-0.180957 0.0463819,-0.0463819 0.110441,-0.0751772 0.180961,-0.0751772zm0.909374 0.16l-0.909374 0c-0.0263504,0 -0.050374,0.0108425 -0.0678346,0.0283031 -0.0174606,0.0174606 -0.0283031,0.0414803 -0.0283031,0.0678307l0 0.194776 1.10164 0 0 -0.194776c0,-0.0263504 -0.0108425,-0.050374 -0.0282992,-0.0678307 -0.0174606,-0.0174606 -0.0414803,-0.0283031 -0.0678307,-0.0283031z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
