import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function SGraduateCapII(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <path
            className="fil-1"
            d="M0.895205 2.9529l2.48 -1.35634 0.038126 -0.0208504 0.038126 0.0208504 2.48 1.35634 0.127988 0.07 -0.127988 0.07 -2.48 1.35634 -0.038126 0.0208504 -0.038126 -0.0208504 -2.48 -1.35634 -0.127988 -0.07 0.127988 -0.07zm2.51813 -1.19549l-2.31388 1.26549 2.31388 1.26549 2.31388 -1.26549 -2.31388 -1.26549z"
          />
          <path
            className="fil-1"
            d="M2.11042 3.62187l0 1.16719c0,0.0184724 -0.000208661,-0.00333071 -0.000413386,0.010626l0 0.000314961c-0.000145669,0.00933858 -0.000322835,0.0206772 0.000370079,0.0237362 0.0128228,0.0565354 0.152248,0.115756 0.370972,0.166264 0.241854,0.0558504 0.569756,0.0900984 0.93198,0.0900984 0.361492,0 0.688783,-0.0341063 0.930465,-0.089752 0.219,-0.0504213 0.358874,-0.109693 0.372543,-0.166472l-0.000291339 -7.08661e-005c0.00096063,-0.00404331 0.000759843,-0.0180118 0.000610236,-0.0285906 -0.000220472,-0.0145906 -0.000413386,0.00426378 -0.000413386,-0.0107953l0 -1.16255 0.16 0 0 1.16255c0,0.020874 -0.000318898,0.00155906 -0.000208661,0.00892126 0.000287402,0.0199331 0.000665354,0.0462283 -0.00436614,0.0673386l-0.000311024 0c-0.0289094,0.120992 -0.212937,0.221055 -0.491941,0.285291 -0.253472,0.0583583 -0.593492,0.0941299 -0.966087,0.0941299 -0.373366,0 -0.714,-0.0359134 -0.967602,-0.0944764 -0.279528,-0.0645512 -0.463366,-0.165169 -0.490972,-0.286886 -0.00474409,-0.0209567 -0.00440945,-0.0430512 -0.00412598,-0.0612402l3.93701e-006 0c8.66142e-005,-0.00600787 -0.000212598,0.0155669 -0.000212598,-0.00843701l0 -1.16719 0.16 0z"
          />
          <g>
            <circle className="fil-1" cx="1.653" cy="4.10968" r="0.135235" />
          </g>
          <path
            className="fil-1"
            d="M3.41333 2.75543c0.0738543,0 0.140724,0.0299449 0.189126,0.0783465 0.0484016,0.0484016 0.0783465,0.115272 0.0783465,0.189126 0,0.0738504 -0.0299449,0.14072 -0.0783465,0.189122 -0.0484016,0.0484016 -0.115276,0.0783465 -0.189126,0.0783465 -0.0738504,0 -0.140724,-0.0299449 -0.189126,-0.0783465 -0.0484016,-0.0484016 -0.0783465,-0.115272 -0.0783465,-0.189122 0,-0.0738543 0.0299449,-0.140724 0.0783465,-0.189126 0.0484016,-0.0484016 0.115272,-0.0783465 0.189126,-0.0783465zm0.076 0.191472c-0.0194409,-0.0194409 -0.046315,-0.0314724 -0.076,-0.0314724 -0.029685,0 -0.0565591,0.0120315 -0.076,0.0314724 -0.0194409,0.0194409 -0.0314724,0.046315 -0.0314724,0.076 0,0.0296811 0.0120315,0.0565551 0.0314724,0.0759961 0.0194409,0.0194409 0.0463189,0.0314724 0.076,0.0314724 0.0296811,0 0.0565591,-0.0120315 0.076,-0.0314724 0.0194409,-0.0194409 0.0314724,-0.046315 0.0314724,-0.0759961 0,-0.029685 -0.0120315,-0.0565591 -0.0314724,-0.076z"
          />
        </g>
      </g>
      <rect className="fil-0" width="6.82666" height="6.82666" />
    </SvgIcon>
  );
}
