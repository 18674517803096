import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function SWifi(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <path
            className="fil-1"
            d="M3.42051 3.80125c0.0825709,0 0.161717,0.0165906 0.234264,0.0466417 0.0755748,0.0312992 0.143205,0.0767992 0.199417,0.133016 0.0562126,0.0562126 0.101713,0.12385 0.13302,0.199421 0.0300512,0.0725472 0.0466378,0.151697 0.0466378,0.23426 0,0.0825669 -0.0165866,0.161717 -0.0466378,0.234264 -0.0313031,0.0755709 -0.0768031,0.143201 -0.13302,0.199413 -0.0562126,0.0562126 -0.12385,0.101713 -0.199421,0.13302 -0.0725472,0.0300512 -0.151697,0.0466378 -0.23426,0.0466378 -0.082563,0 -0.161713,-0.0165866 -0.23426,-0.0466378 -0.0755709,-0.0313071 -0.143209,-0.0768071 -0.199421,-0.13302 -0.0562165,-0.0562126 -0.101717,-0.123843 -0.133016,-0.199417 -0.0300512,-0.0725472 -0.0466417,-0.151689 -0.0466417,-0.23426 0,-0.082563 0.0165866,-0.161713 0.0466378,-0.23426 0.0313071,-0.0755709 0.0768071,-0.143209 0.13302,-0.199421 0.0562126,-0.0562126 0.12385,-0.101713 0.199421,-0.13302 0.0725472,-0.0300512 0.151697,-0.0466378 0.23426,-0.0466378zm0.173642 0.194146c-0.0531102,-0.022 -0.111744,-0.0341457 -0.173642,-0.0341457 -0.0618976,0 -0.120528,0.0121417 -0.173638,0.0341417 -0.0549409,0.0227598 -0.104783,0.0565079 -0.146917,0.0986417 -0.0421339,0.0421339 -0.0758819,0.0919764 -0.0986417,0.146917 -0.0219961,0.0531063 -0.0341417,0.11174 -0.0341417,0.173638 0,0.0618976 0.0121457,0.120528 0.0341457,0.173638 0.0227559,0.0549449 0.0565079,0.104787 0.0986378,0.146913 0.0421339,0.0421339 0.0919764,0.0758819 0.146917,0.0986417 0.0531102,0.022 0.11174,0.0341417 0.173638,0.0341417 0.0618976,0 0.120528,-0.0121417 0.173638,-0.0341417 0.0549409,-0.0227598 0.104783,-0.0565079 0.146917,-0.0986417 0.0421339,-0.0421299 0.0758819,-0.0919724 0.0986417,-0.146909 0.022,-0.0531102 0.0341417,-0.11174 0.0341417,-0.173642 0,-0.0618976 -0.0121457,-0.120531 -0.0341417,-0.173638 -0.0227598,-0.0549409 -0.0565079,-0.104783 -0.0986417,-0.146917 -0.042126,-0.0421299 -0.0919685,-0.0758819 -0.146913,-0.0986378z"
          />
          <g>
            <path
              className="fil-1"
              d="M2.14125 3.7688c0.0352756,-0.0638543 0.0774567,-0.124811 0.125933,-0.182157 0.0484213,-0.0572835 0.102756,-0.110669 0.16239,-0.159441 0.271972,-0.222433 0.627343,-0.333657 0.982035,-0.333657 0.354693,0 0.710063,0.111224 0.982035,0.333657 0.0608228,0.0497441 0.116067,0.104197 0.165091,0.162587 0.0486378,0.0579331 0.0910748,0.120059 0.126685,0.185626l-0.14 0.076252c-0.030063,-0.0553504 -0.0666417,-0.108693 -0.109189,-0.159374 -0.0421535,-0.0502087 -0.0902835,-0.0975433 -0.143839,-0.141343 -0.242421,-0.198268 -0.561268,-0.297406 -0.880783,-0.297406 -0.319516,0 -0.638362,0.0991378 -0.880783,0.297406 -0.0527441,0.0431339 -0.100177,0.0896142 -0.141768,0.138819 -0.0415315,0.0491339 -0.0776457,0.101307 -0.107807,0.155906l-0.14 -0.076874z"
            />
            <path
              className="fil-1"
              d="M1.48214 3.4665c0.0532205,-0.0963307 0.116862,-0.188299 0.190016,-0.274839 0.0730984,-0.0864803 0.155331,-0.16724 0.245772,-0.241209 0.412996,-0.337776 0.953205,-0.506665 1.49273,-0.506665 0.539524,0 1.07973,0.16889 1.49273,0.506665 0.0921811,0.0753937 0.175764,0.157744 0.249787,0.245909 0.0736299,0.0877087 0.137732,0.181488 0.191346,0.280205l-0.14 0.076252c-0.0480669,-0.0885 -0.106311,-0.1735 -0.17385,-0.253953 -0.0671496,-0.0799843 -0.143622,-0.155217 -0.228535,-0.224665 -0.383441,-0.313606 -0.887126,-0.470413 -1.39148,-0.470413 -0.504346,0 -1.00803,0.156807 -1.39148,0.470413 -0.0835512,0.0683307 -0.158882,0.142189 -0.22515,0.220587 -0.0662126,0.0783268 -0.123783,0.161516 -0.17189,0.248587l-0.14 -0.076874z"
            />
            <path
              className="fil-1"
              d="M0.921661 3.11692c0.0684724,-0.123941 0.150362,-0.242283 0.2445,-0.353646 0.0940866,-0.111303 0.200043,-0.215346 0.316685,-0.310744 0.532917,-0.435854 1.2303,-0.653783 1.927,-0.653783 0.696705,0 1.39409,0.217925 1.92701,0.65378 0.118854,0.0972047 0.226531,0.20328 0.321807,0.316768 0.0948898,0.113028 0.177409,0.233724 0.246331,0.36063l-0.14 0.076252c-0.0633701,-0.116685 -0.140031,-0.228602 -0.228835,-0.334378 -0.0884055,-0.105303 -0.188969,-0.204264 -0.300555,-0.295524 -0.503366,-0.411685 -1.16422,-0.617528 -1.82576,-0.617528 -0.661524,0 -1.32238,0.205846 -1.82575,0.617531 -0.109752,0.0897638 -0.208807,0.186898 -0.296063,0.290122 -0.0871929,0.10315 -0.163016,0.212709 -0.226374,0.327394l-0.14 -0.076874z"
            />
          </g>
        </g>
      </g>
      <rect className="fil-1" width="6.82666" height="6.82666" />
    </SvgIcon>
  );
}
