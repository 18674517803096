import { v4 as uuidv4 } from 'uuid';
import { createReducer } from '@reduxjs/toolkit';
import { getNewPropertyInputs, initialListNewDevelopmentState } from './state';
import * as actions from './actions';
import { removeArrayItem, swapArrayItem } from '../../../../utils/array';
import {
  ImageFile,
  ImageUploadStatus,
} from '../../my-account/property/editDEPRECATED/file-upload/image/types';

const reducer = createReducer(initialListNewDevelopmentState, (builder) =>
  builder
    // ----- ADD PROPERTY ----- //
    .addCase(actions.addProperty, (state) => {
      const newPropertyId = uuidv4();

      state.propertyInputs[newPropertyId] = getNewPropertyInputs();
    })

    // ----- REMOVE PROPERTY ----- //
    .addCase(actions.removeProperty, (state, action) => {
      const propertyId = action.payload;

      delete state.propertyInputs[propertyId];
    })

    // ----- SET INPUT ----- //
    .addCase(actions.setInput, (state, action) => {
      const {
        flatId,
        category,
        name,
        valueName = 'value',
        value,
      } = action.payload;

      if (flatId) {
        state.propertyInputs[flatId][name][valueName] = value;
      } else if (category) {
        state.developmentInputs[category][name][valueName] = value;
      } else {
        throw new Error(
          'Please provide either a flat ID or an input category when updating inputs'
        );
      }
    })

    // ----- SET IMAGE FILES INPUT ----- //
    .addCase(actions.setImageFilesInput, (state, action) => {
      const { flatId, inputName, fileData } = action.payload;

      const newFileIds = fileData.map((f) => f.id);
      const newFileDataById = fileData.reduce<Record<string, ImageFile>>(
        (o, f) => {
          o[f.id] = f;
          return o;
        },
        {}
      );

      if (flatId) {
        state.propertyInputs[flatId][inputName].fileIds = newFileIds;
        state.propertyInputs[flatId][inputName].fileDataById = newFileDataById;
      } else {
        state.developmentInputs[inputName].fileIds = newFileIds;
        state.developmentInputs[inputName].fileDataById = newFileDataById;
      }
    })

    // ----- ADD IMAGES ----- //
    .addCase(actions.addImages, (state, action) => {
      const { flatId, inputName, fileData } = action.payload;

      if (flatId) {
        fileData.forEach((f) => {
          state.propertyInputs[flatId][inputName].fileIds.push(f.id);
          state.propertyInputs[flatId][inputName].fileDataById[f.id] = f;
        });
      } else {
        fileData.forEach((f) => {
          state.developmentInputs[inputName].fileIds.push(f.id);
          state.developmentInputs[inputName].fileDataById[f.id] = f;
        });
      }
    })

    // ----- REMOVE IMAGE(S) ----- //
    .addCase(actions.removeImages, (state, action) => {
      const { flatId, inputName, fileData } = action.payload;

      const fileIds = fileData.map((f) => f.id);

      if (flatId) {
        state.propertyInputs[flatId][inputName].fileIds = removeArrayItem(
          state.propertyInputs[flatId][inputName].fileIds,
          fileIds
        );

        fileIds.forEach((id) => {
          // Revoke image preview on component unmount to avoid memory leaks
          URL.revokeObjectURL(
            state.propertyInputs[flatId][inputName].fileDataById[id].preview
          );

          // Delete all other data
          delete state.propertyInputs[flatId][inputName].fileDataById[id];
        });
      } else {
        state.developmentInputs[inputName].fileIds = removeArrayItem(
          state.developmentInputs[inputName].fileIds,
          fileIds
        );

        fileIds.forEach((id) => {
          // Revoke image preview on component unmount to avoid memory leaks
          URL.revokeObjectURL(
            state.developmentInputs[inputName].fileDataById[id].preview
          );

          // Delete all other data
          delete state.developmentInputs[inputName].fileDataById[id];
        });
      }
    })

    // ----- MAKE IMAGE PRIMARY ----- //
    .addCase(actions.makeImagePrimary, (state, action) => {
      const { flatId, inputName, fileData } = action.payload;

      if (flatId) {
        state.propertyInputs[flatId][inputName].fileIds = swapArrayItem(
          state.propertyInputs[flatId][inputName].fileIds,
          0,
          state.propertyInputs[flatId][inputName].fileIds.indexOf(fileData.id)
        );
      } else {
        state.developmentInputs[inputName].fileIds = swapArrayItem(
          state.developmentInputs[inputName].fileIds,
          0,
          state.developmentInputs[inputName].fileIds.indexOf(fileData.id)
        );
      }
    })

    // ----- CLEAR IMAGES ----- //
    .addCase(actions.clearImages, (state, action) => {
      const { flatId, inputName } = action.payload;

      if (flatId) {
        Object.values(
          state.propertyInputs[flatId][inputName].fileDataById
        ).forEach((f) => {
          URL.revokeObjectURL(f.preview);
        });

        state.propertyInputs[flatId][inputName].fileIds = [];
        state.propertyInputs[flatId][inputName].fileDataById = {};
      } else {
        Object.values(state.developmentInputs[inputName].fileDataById).forEach(
          (f) => {
            URL.revokeObjectURL((f as ImageFile).preview);
          }
        );

        state.developmentInputs[inputName].fileIds = [];
        state.developmentInputs[inputName].fileDataById = {};
      }
    })

    // ----- UPLOAD IMAGE (ASYNC) - REQUEST ----- //
    .addCase(actions.uploadImageRequest, (state, action) => {
      const { flatId, inputName, imageFile } = action.payload;

      console.log(`flatId = ${flatId}`);

      if (flatId) {
        state.propertyInputs[flatId][inputName].fileDataById[
          imageFile.id
        ].uploadStatus = ImageUploadStatus.UPLOADING;
      } else {
        state.developmentInputs[inputName].fileDataById[
          imageFile.id
        ].uploadStatus = ImageUploadStatus.UPLOADING;
      }
    })

    // ----- UPLOAD IMAGE (ASYNC) - SUCCESS ----- //
    .addCase(actions.uploadImageSuccess, (state, action) => {
      const { flatId, inputName, imageFile } = action.payload;

      if (flatId) {
        state.propertyInputs[flatId][inputName].fileDataById[
          imageFile.id
        ].uploadStatus = ImageUploadStatus.UPLOADED;
      } else {
        state.developmentInputs[inputName].fileDataById[
          imageFile.id
        ].uploadStatus = ImageUploadStatus.UPLOADED;
      }
    })

    // ----- UPLOAD IMAGE (ASYNC) - ERROR ----- //
    .addCase(actions.uploadImageFailure, (state, action) => {
      const { flatId, inputName, imageFile, failureResponse } = action.payload;

      console.error(failureResponse.message);

      if (flatId) {
        state.propertyInputs[flatId][inputName].fileDataById[
          imageFile.id
        ].uploadStatus = ImageUploadStatus.ERROR;
      } else {
        state.developmentInputs[inputName].fileDataById[
          imageFile.id
        ].uploadStatus = ImageUploadStatus.ERROR;
      }
    })

    // ----- CLEAR ALL INPUTS ----- //
    .addCase(actions.clearAllInputs, (state) => {
      // Revoke all image URLs
      Object.values(state.developmentInputs.imageFiles.fileDataById).forEach(
        (f) => {
          URL.revokeObjectURL(f.preview);
        }
      );
      Object.values(state.propertyInputs).forEach((propertyInput) => {
        Object.values(propertyInput.imageFiles.fileDataById).forEach((f) => {
          URL.revokeObjectURL(f.preview);
        });
        Object.values(propertyInput.floorPlanFiles.fileDataById).forEach(
          (f) => {
            URL.revokeObjectURL(f.preview);
          }
        );
      });

      // Clear all input data
      state.developmentInputs = {
        ...initialListNewDevelopmentState.developmentInputs,
      };
      state.propertyInputs = {
        [uuidv4()]: getNewPropertyInputs(),
      };
    })

    // ----- DEFAULT ----- //
    .addDefaultCase((state, action) => {
      throw new Error(`Invalid action ${action.type} provided!`);
    })
);

export default reducer;
