import { Status } from '../../../../api/useAsyncData';
import { ImageFile } from '../../my-account/property/editDEPRECATED/file-upload/image/types';
import {
  PlaceResult,
  AutocompletePrediction,
} from '../../../../api/googleMaps/gcpPlaces/types';

export enum RadiusType {
  null = 'This area only',
  QUARTER = 'Within ¼ km',
  HALF = 'Within ½ km',
  ONE = 'Within 1 km',
  THREE = 'Within 3 km',
  FIVE = 'Within 5 km',
  TEN = 'Within 10 km',
  FIFTEEN = 'Within 15 km',
  TWENTY = 'Within 20 km',
  THIRTY = 'Within 30 km',
  FORTY = 'Within 40 km',
}

export enum PropertyType {
  null = '',
  APARTMENT = 'Apartment',
  HOUSE = 'House',
}

export enum BedroomType {
  null = '',
  STUDIO = 'Studio',
  ONE = '1 Bedroom',
  TWO = '2 Bedrooms',
  THREE = '3 Bedrooms',
  FOUR = '4 Bedrooms',
}

export enum KitchenType {
  null = '',
  OPEN = 'Open plan',
  SEPARATE = 'Separated',
}

export enum EpcRatingType {
  null = '',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export enum CouncilTaxBandType {
  null = '',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
}

export enum HeatingType {
  null = '',
  GAS = 'Gas',
  ELECTRIC = 'Electric',
  UNDERFLOOR = 'Underfloor',
}

export enum HotWaterType {
  null = '',
  GAS = 'Gas',
  ELECTRIC = 'Electric',
  CENTRAL = 'Central',
}

export enum BedSizeType {
  null = '',
  KING = 'King size',
  DOUBLE = 'Double',
  SINGLE = 'Single',
}

export enum HobType {
  null = '',
  CERAMIC = 'Ceramic',
  INDUCTION = 'Induction',
  GAS = 'Gas',
}

export enum TermLengthType {
  null = '',
  M3 = '3 months',
  M6 = '6 months',
  Y1 = '1 year',
  Y2 = '2 years',
  Y3 = '3 years',
}

export enum DepositLengthType {
  null = '',
  W0 = 'No deposit (subject to reference check)',
  W1 = '1 week',
  W2 = '2 weeks',
  W3 = '3 weeks',
  W4 = '4 weeks',
  W5 = '5 weeks',
  W6 = '6 weeks',
}

export interface PrimitiveInput<T> {
  value: T;
}

export interface AddressInput {
  value: string;
  autocomplete: AutocompletePrediction | null;
  predictions: AutocompletePrediction[] | null;
  placeDetails: PlaceResult | null;
}

export interface FileData {
  file: File;
}

export interface ImageFileData {
  file: File;
  src: string;
}

export interface FileUploadStatus {
  progress: number;
  error: Error | null;
  status: Status;
}

export interface SingleFile<FileType> {
  fileId: string;
  fileData: FileType;
  fileUploadStatus: FileUploadStatus;
}

export interface FileInput<FileType> {
  // These file IDs are used to locate the files on our storage. Being an array,
  // it also helps order the files.
  fileIds: string[];

  // This is file data by ID
  fileDataById: Record<string, FileType>;
}

export interface DevelopmentInputs {
  basicDetails: {
    title: PrimitiveInput<string>;
    address: AddressInput;
    owner: PrimitiveInput<string>;
    propertyManager: PrimitiveInput<string>;
    unitsCount: PrimitiveInput<number>;
    propertyType: PrimitiveInput<PropertyType>;
    completionYear: PrimitiveInput<number>;
    description: PrimitiveInput<string>;
  };

  developmentFacilities: {
    heatingType: PrimitiveInput<HeatingType>;
    hotWaterType: PrimitiveInput<HotWaterType>;
    epcType: PrimitiveInput<EpcRatingType>;
    hasConcierge: PrimitiveInput<boolean>;
    hasOnsiteManager: PrimitiveInput<boolean>;
    hasOnsiteMaintenance: PrimitiveInput<boolean>;
    hasGym: PrimitiveInput<boolean>;
    hasCommonArea: PrimitiveInput<boolean>;
    hasBikeStorage: PrimitiveInput<boolean>;
    hasLift: PrimitiveInput<boolean>;
    hasCctv: PrimitiveInput<boolean>;
    hasGateAccess: PrimitiveInput<boolean>;
    hasSmokeDetector: PrimitiveInput<boolean>;
    includeElectricityBill: PrimitiveInput<boolean>;
    includeWaterBill: PrimitiveInput<boolean>;
    includeInternetBill: PrimitiveInput<boolean>;
    includeCouncilTax: PrimitiveInput<boolean>;
  };

  propertyFacilities: {
    kitchenType: PrimitiveInput<KitchenType>;
    bedSizeType: PrimitiveInput<BedSizeType>;
    hobType: PrimitiveInput<HobType>;
    hasTv: PrimitiveInput<boolean>;
    hasBed: PrimitiveInput<boolean>;
    isEnsuite: PrimitiveInput<boolean>;
    hasWardrobe: PrimitiveInput<boolean>;
    hasDrawer: PrimitiveInput<boolean>;
    hasSofa: PrimitiveInput<boolean>;
    hasTeaTable: PrimitiveInput<boolean>;
    hasDiningTable: PrimitiveInput<boolean>;
    hasFridge: PrimitiveInput<boolean>;
    hasOven: PrimitiveInput<boolean>;
    hasMicrowave: PrimitiveInput<boolean>;
    hasWashingMachine: PrimitiveInput<boolean>;
    hasDishwasher: PrimitiveInput<boolean>;
  };

  livingAtmosphere: {
    isPetFriendly: PrimitiveInput<boolean>;
    isFamilyFriendly: PrimitiveInput<boolean>;
    isStudentFriendly: PrimitiveInput<boolean>;
    isNoSmoking: PrimitiveInput<boolean>;
    canShortRent: PrimitiveInput<boolean>;
    canZeroDeposit: PrimitiveInput<boolean>;
    minTermLengthType: PrimitiveInput<TermLengthType>;
    maxTermLengthType: PrimitiveInput<TermLengthType>;
    depositLengthType: PrimitiveInput<DepositLengthType>;
  };

  legal: {
    noticePeriod: PrimitiveInput<string>;
    keyLossFee: PrimitiveInput<string>;
    tenantChangeFee: PrimitiveInput<string>;
    latePaymentFee: PrimitiveInput<string>;
  };

  imageFiles: FileInput<ImageFile>;
}

export interface PropertyInputs {
  title: PrimitiveInput<string>;
  level: PrimitiveInput<number>;
  bedroomCount: PrimitiveInput<BedroomType>;
  maxPax: PrimitiveInput<number>;
  Sqm: PrimitiveInput<number>;
  longTermRent: PrimitiveInput<number>;
  shortTermRent: PrimitiveInput<number>;
  availableDate: PrimitiveInput<Date | null>;
  furnished: PrimitiveInput<boolean>;
  councilTaxBandType: PrimitiveInput<CouncilTaxBandType>;

  imageFiles: FileInput<ImageFile>;
  floorPlanFiles: FileInput<ImageFile>;
}

export type DevelopmentBasicDetailsInputName =
  keyof DevelopmentInputs['basicDetails'];
export type DevelopmentDevelopmentFacilitiesInputName =
  keyof DevelopmentInputs['developmentFacilities'];
export type DevelopmentPropertyDetailsInputName =
  keyof DevelopmentInputs['propertyFacilities'];
export type DevelopmentLivingAtmosphereInputName =
  keyof DevelopmentInputs['livingAtmosphere'];
export type DevelopmentLegalInputName = keyof DevelopmentInputs['legal'];
export type PropertyInputName = keyof PropertyInputs;
export type ListNewDevelopmentInputName =
  | DevelopmentBasicDetailsInputName
  | DevelopmentDevelopmentFacilitiesInputName
  | DevelopmentPropertyDetailsInputName
  | DevelopmentLivingAtmosphereInputName
  | DevelopmentLegalInputName
  | PropertyInputName;
