import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  DevelopmentInputs,
  ListNewDevelopmentInputName,
} from '../../listNewDevelopment/models';
import { setInput } from '../../listNewDevelopment/actions';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../../listNewDevelopment/context';

// ========== TYPES ========== //

interface ComponentProps {
  flatId?: string;
  category?: keyof DevelopmentInputs;
  name: ListNewDevelopmentInputName;
  label?: string;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      maxWidth: theme.breakpoints.values.sm,
    },
    datePicker: {
      width: '100%',
    },
  })
);

// ========== COMPONENT ========== //

const NewPropertyFormDateInput = ({
  flatId,
  category,
  label,
  name,
}: ComponentProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  const value = flatId
    ? state.propertyInputs[flatId][name].value
    : category
    ? state.developmentInputs[category][name].value
    : null;

  const handleChange = (date: Date | null) => {
    dispatch(
      setInput({
        flatId,
        category,
        name,
        value: date,
      })
    );
  };

  return (
    <Box className={classes.ctn}>
      <KeyboardDatePicker
        className={classes.datePicker}
        clearable
        format="dd/MM/yyyy"
        label={label}
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
};

export default NewPropertyFormDateInput;
