import * as React from 'react';
import TabPanel from '../../../../atoms/Tabs/TabPanel/TabPanel';
import { newPropertyTabsInfo } from './TabsSelectionPanel';
import DevelopmentForm from './DevelopmentForm';
import DevelopmentImagesSection from '../inputs/DevelopmentImagesSection';

// ========== TYPES ========== //

interface ComponentProps {
  currentTab: string | number;
}

// ========== COMPONENT ========== //

const Tab4 = ({ currentTab }: ComponentProps) => {
  return (
    <TabPanel
      currentTabIndex={currentTab}
      thisTabIndex={Object.keys(newPropertyTabsInfo)[3]}
    >
      <DevelopmentForm title="Photos">
        <DevelopmentImagesSection />
      </DevelopmentForm>
    </TabPanel>
  );
};

export default Tab4;
