/**
 * Given an array and 2 index values, swap the position of the elements at the
 * 2 index values.
 *
 * Will perform this action on a copied version of the original array and return
 * the copied version.
 *
 * If the array has length < 2, will return the original array.
 */
export function swapArrayItem<T = any>(
  arr: T[],
  indexA: number,
  indexB: number
): T[] {
  if (arr.length < 2) {
    return arr;
  }

  const arrClone = [...arr];

  arrClone[indexA] = arrClone.splice(indexB, 1, arrClone[indexA])[0];

  return arrClone;
}

/**
 * Given an array and a value or an array of values, remove the value or array
 * of values from the array.
 *
 * Will mutate the provided array.
 */
export function removeArrayItemMutate<T = unknown>(
  arr: T[],
  val: T | T[]
): T[] {
  if (Array.isArray(val)) {
    val.forEach((v) => {
      const i = arr.indexOf(v);

      if (i !== -1) {
        arr.splice(i, 1);
      }
    });
  } else {
    const i = arr.indexOf(val);

    if (i !== -1) {
      arr.splice(i, 1);
    }
  }

  return arr;
}

/**
 * Given an array and a value or an array of values, remove the value or array
 * of values from the array.
 *
 * Will perform this action on a copied version of the original array and return
 * the copied version.
 */
export function removeArrayItem<T = unknown>(arr: T[], val: T | T[]): T[] {
  const arrClone = [...arr];

  if (Array.isArray(val)) {
    val.forEach((v) => {
      const i = arrClone.indexOf(v);

      if (i !== -1) {
        arrClone.splice(i, 1);
      }
    });
  } else {
    const i = arrClone.indexOf(val);

    if (i !== -1) {
      arrClone.splice(i, 1);
    }
  }

  return arrClone;
}

/**
 * Remove all duplicates from an array. Will return a new array.
 * NOTE: primitives only. Doesn't work with object elements.
 */
export function removeDuplicatesFromArray<T = unknown>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

/**
 * Given a start and an end integer, return an array of continuous numbers with
 * 1 step between each number, starting from the start and ending at the
 * provided end integers.
 */
export function getRangeArray(start: number, end: number): number[] {
  const arr: number[] = [];

  for (let i = start; i <= end; i++) {
    arr.push(i);
  }

  return arr;
}
