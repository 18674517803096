import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function SmallTable(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <path d="M2.29502 2.94067c0,-0.0440079 -0.0356811,-0.079689 -0.079689,-0.079689 -0.0440079,0 -0.079689,0.0356811 -0.079689,0.079689 -0.00073622,0.252984 -0.0239843,0.620783 -0.122669,1.01485 -0.0360984,0.144146 -0.0823268,0.291681 -0.141287,0.438146 0.0443346,-0.442228 0.0924528,-1.10601 0.0360157,-1.51766 -0.00586614,-0.0436614 -0.0460276,-0.0743031 -0.089689,-0.068437 -0.0436614,0.00586614 -0.0743031,0.0460276 -0.068437,0.089689 0.0842244,0.614346 -0.0747677,1.82816 -0.0875,1.92343 -0.0069685,0.0324567 0.00686614,0.0671535 0.036874,0.0852756 0.0378189,0.0228386 0.087,0.0106969 0.109839,-0.027122l-0.0684803 -0.0413583 0.068437 0.041252c0.172965,-0.286386 0.286063,-0.592303 0.359228,-0.884469 0.102201,-0.408106 0.126283,-0.790374 0.127047,-1.0536z" />
          <path d="M4.69102 2.94067c0,-0.0440079 -0.0356811,-0.079689 -0.079689,-0.079689 -0.0440079,0 -0.079689,0.0356811 -0.079689,0.079689 0.000649606,0.224815 0.0183858,0.53678 0.0875394,0.877969 0.0695866,0.343327 0.190972,0.71611 0.398732,1.0601l0.068437 -0.041252 -0.0684803 0.0413583c0.0228386,0.0378189 0.0720197,0.0499606 0.109839,0.027122 0.0300079,-0.018122 0.0438425,-0.0528189 0.036874,-0.0852756 -0.0127323,-0.0952717 -0.17172,-1.30909 -0.0875,-1.92343 0.00586614,-0.0436614 -0.0247756,-0.0838228 -0.068437,-0.089689 -0.0436614,-0.00586614 -0.0838228,0.0247756 -0.089689,0.068437 -0.0564449,0.411744 -0.00829528,1.07574 0.0360472,1.51797 -0.0823189,-0.204252 -0.139815,-0.410453 -0.179571,-0.606594 -0.066685,-0.329004 -0.0837835,-0.629882 -0.0844134,-0.846717z" />
          <path d="M3.41333 1.90917c0.689346,0 1.31511,0.0575 1.76957,0.150449 0.488366,0.0998937 0.790433,0.253398 0.790433,0.436823 0,0.183421 -0.302067,0.336929 -0.790433,0.436823 -0.454453,0.0929488 -1.08022,0.150449 -1.76957,0.150449 -0.689346,0 -1.31511,-0.0575 -1.76957,-0.150449 -0.488366,-0.0998937 -0.790433,-0.253402 -0.790433,-0.436823 0,-0.183425 0.302067,-0.336929 0.790433,-0.436823 0.454453,-0.0929488 1.08022,-0.150449 1.76957,-0.150449zm1.73769 0.306701c-0.44313,-0.0906339 -1.05738,-0.146701 -1.73769,-0.146701 -0.680315,0 -1.29456,0.0560669 -1.73769,0.146701 -0.409201,0.0837008 -0.662307,0.183843 -0.662307,0.280571 0,0.0967283 0.253106,0.19687 0.662307,0.280571 0.44313,0.0906339 1.05738,0.146701 1.73769,0.146701 0.680315,0 1.29456,-0.0560669 1.73769,-0.146701 0.409201,-0.0837008 0.662307,-0.183843 0.662307,-0.280571 0,-0.0967283 -0.253106,-0.19687 -0.662307,-0.280571z" />
          <path d="M3.41333 2.14917c0.561783,0 1.07224,0.0302756 1.44339,0.0792205 0.409803,0.0540433 0.663283,0.147665 0.663283,0.268055 0,0.120386 -0.25348,0.214012 -0.663283,0.268055 -0.371142,0.0489449 -0.881602,0.0792205 -1.44339,0.0792205 -0.561783,0 -1.07224,-0.0302756 -1.44339,-0.0792205 -0.409803,-0.0540433 -0.663283,-0.147669 -0.663283,-0.268055 0,-0.12039 0.25348,-0.214012 0.663283,-0.268055 0.371142,-0.0489449 0.881602,-0.0792205 1.44339,-0.0792205zm1.42276 0.237346c-0.362362,-0.0477835 -0.865252,-0.0773465 -1.42276,-0.0773465 -0.557512,0 -1.0604,0.029563 -1.42276,0.0773465 -0.323693,0.042685 -0.523906,0.0827087 -0.523906,0.109929 0,0.0272244 0.200213,0.0672441 0.523906,0.109929 0.362362,0.0477835 0.865252,0.0773465 1.42276,0.0773465 0.557512,0 1.0604,-0.029563 1.42276,-0.0773465 0.323693,-0.042685 0.523906,-0.0827047 0.523906,-0.109929 0,-0.0272205 -0.200213,-0.0672441 -0.523906,-0.109929z" />
          <path d="M3.71836 3.00692c0.00293307,-0.0440079 -0.0303701,-0.0820669 -0.074378,-0.085 -0.0440079,-0.00293307 -0.0820669,0.0303701 -0.085,0.074378 -0.0173268,0.250161 -0.0432244,0.477701 -0.0796142,0.70387 -0.0190315,0.118276 -0.0409567,0.236496 -0.0660433,0.35765 -0.0250866,-0.121154 -0.0470079,-0.239374 -0.0660394,-0.35765 -0.0363858,-0.226169 -0.0622835,-0.453709 -0.0796102,-0.70387 -0.00293307,-0.0440079 -0.0409921,-0.077311 -0.085,-0.074378 -0.0440079,0.00293307 -0.077311,0.0409921 -0.074378,0.085 0.0175512,0.253425 0.044063,0.485642 0.0814843,0.718248 0.0369488,0.229634 0.0844646,0.458543 0.144469,0.707854 0.00481496,0.0310945 0.0278346,0.0577283 0.060315,0.065563 0.0429488,0.0103583 0.0861693,-0.0160591 0.0965276,-0.0590079l-0.000255906 -6.29921e-005c0.0607441,-0.251827 0.108768,-0.48272 0.146035,-0.714346 0.0374252,-0.232606 0.063937,-0.464819 0.0814882,-0.718248z" />
        </g>
      </g>
    </SvgIcon>
  );
}
