import React, { useState } from 'react';
import {
  Box,
  colors,
  createStyles,
  Fab,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Add, Delete, AddPhotoAlternate } from '@material-ui/icons';
import clsx from 'clsx';
import { addProperty, removeProperty } from '../../listNewDevelopment/actions';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../../listNewDevelopment/context';
import NewPropertyFormTextInput from '../inputs/NewPropertyFormTextInput';
import NewPropertyFormNumberInput from '../inputs/NewPropertyFormNumberInput';
import NewPropertyFormDateInput from '../inputs/NewPropertyFormDateInput';
import NewPropertyFormCheckboxInput from '../inputs/NewPropertyFormCheckboxInput';
import Sofa from '../../../../../images/icons/Sofa';
import NewPropertyFormSelect from '../inputs/NewPropertyFormSelect';
import {
  BedroomType,
  CouncilTaxBandType,
} from '../../listNewDevelopment/models';
import FloorPlanDialog from '../inputs/FloorPlanDialog';
import PropertyImagesDialog from '../inputs/PropertyImagesDialog';

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentCtn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      padding: `0 ${theme.spacing(4)}px`,

      '& > *': {
        width: '100%',
      },

      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },

    titleCtn: {
      display: 'flex',

      marginBottom: theme.spacing(1),

      '& > *:first-child': {
        flexGrow: 1,
      },
    },

    formCtn: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),

      maxWidth: '100%',

      overflow: 'scroll',
    },

    rowCtn: {
      display: 'flex',

      '& > *': {
        flex: '0 0 auto',
        // outline: '1px solid #212121',
      },
    },

    headerCell: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      fontSize: '0.875rem',
      textAlign: 'center',

      '& > svg': {
        marginRight: theme.spacing(1),
      },
    },

    bodyCell: {
      display: 'flex',
      alignItems: 'center',

      padding: theme.spacing(0.5),

      '& > *': {
        width: '100%',
      },
    },

    longCell: {
      width: theme.spacing(25),
    },

    mediumCell: {
      width: theme.spacing(20),
    },

    shortCell: {
      width: theme.spacing(15),
    },

    btnCtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      color: theme.palette.grey['600'],
    },

    uploadBtnHasContent: {
      color: colors.blue['900'],
    },
  })
);

// ========== COMPONENT ========== //

const PropertiesForm = () => {
  const classes = useStyles();

  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  const [propertyIdOfFloorPlanDialog, setPropertyIdOfFloorPlanDialog] =
    useState('');

  const [
    propertyIdOfPropertyImagesDialog,
    setPropertyIdOfPropertyImagesDialog,
  ] = useState('');

  // ---------- Event handlers ---------- //

  const handleAddNewFlat = () => {
    dispatch(addProperty());
  };

  const openFloorPlanDialog = (propertyId: string) => {
    setPropertyIdOfFloorPlanDialog(propertyId);
  };

  const closeFloorPlanDialog = () => {
    setPropertyIdOfFloorPlanDialog('');
  };

  const openPropertyImagesDialog = (propertyId: string) => {
    setPropertyIdOfPropertyImagesDialog(propertyId);
  };

  const closePropertyImagesDialog = () => {
    setPropertyIdOfPropertyImagesDialog('');
  };

  // ---------- Render ---------- //

  // Each of these is a row containing property details.
  // NOTE: the amount of "columns" here should match the amount of headers
  // further below
  const propertyRows = Object.keys(state.propertyInputs).map((propertyId) => {
    const handleRemoveFlat = () => {
      dispatch(removeProperty(propertyId));
    };

    const hasPropertySpecificImages =
      state.propertyInputs[propertyId].imageFiles.fileIds.length > 0;
    const hasFloorPlanImages =
      state.propertyInputs[propertyId].floorPlanFiles.fileIds.length > 0;

    return (
      <Box key={propertyId} className={classes.rowCtn}>
        <Box className={clsx(classes.bodyCell, classes.longCell)}>
          <NewPropertyFormTextInput flatId={propertyId} name="title" />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <NewPropertyFormNumberInput
            flatId={propertyId}
            name="level"
            min={-10}
            max={99}
          />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <NewPropertyFormSelect
            flatId={propertyId}
            name="bedroomCount"
            id="bedroom-count"
            menuItems={Object.values(BedroomType)}
            label="Bedrooms"
          />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <NewPropertyFormNumberInput
            flatId={propertyId}
            name="maxPax"
            min={0}
            max={99}
          />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <NewPropertyFormNumberInput
            flatId={propertyId}
            name="Sqm"
            min={0}
            max={10000}
          />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <NewPropertyFormNumberInput
            flatId={propertyId}
            name="longTermRent"
            min={0}
            max={9999999}
          />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <NewPropertyFormNumberInput
            flatId={propertyId}
            name="shortTermRent"
            min={0}
            max={99999}
          />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.mediumCell)}>
          <NewPropertyFormDateInput flatId={propertyId} name="availableDate" />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <NewPropertyFormCheckboxInput
            flatId={propertyId}
            name="furnished"
            icon={<Sofa />}
          />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <NewPropertyFormSelect
            flatId={propertyId}
            name="councilTaxBandType"
            id="council-tax-band-type"
            menuItems={Object.values(CouncilTaxBandType)}
          />
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <Box
            className={clsx(
              classes.btnCtn,
              hasPropertySpecificImages && classes.uploadBtnHasContent
            )}
          >
            <IconButton
              onClick={() => openPropertyImagesDialog(propertyId)}
              color="inherit"
            >
              <AddPhotoAlternate color="inherit" />
            </IconButton>
          </Box>
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <Box
            className={clsx(
              classes.btnCtn,
              hasFloorPlanImages && classes.uploadBtnHasContent
            )}
          >
            <IconButton
              onClick={() => openFloorPlanDialog(propertyId)}
              color="inherit"
            >
              <AddPhotoAlternate color="inherit" />
            </IconButton>
          </Box>
        </Box>
        <Box className={clsx(classes.bodyCell, classes.shortCell)}>
          <Box className={classes.btnCtn}>
            <IconButton onClick={handleRemoveFlat}>
              <Delete />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  });

  return (
    <Box className={classes.contentCtn}>
      <Box className={classes.titleCtn}>
        <Typography variant="h4">Create property</Typography>
        <Fab
          color="primary"
          aria-label="Add another property"
          onClick={handleAddNewFlat}
        >
          <Add fontSize="large" />
        </Fab>
      </Box>

      <Box className={classes.formCtn}>
        <Box className={classes.rowCtn}>
          <Typography className={clsx(classes.headerCell, classes.longCell)}>
            Flat No.
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Level
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Bedroom
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Max pax
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Sq m
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Long-term rent (pcm)
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Short-term rent (pcm)
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.mediumCell)}>
            Date available
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Furnished
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Council tax band
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Upload property-specific images
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Upload floor plan
          </Typography>
          <Typography className={clsx(classes.headerCell, classes.shortCell)}>
            Action
          </Typography>
        </Box>

        {propertyRows}
      </Box>

      {propertyIdOfFloorPlanDialog && (
        <FloorPlanDialog
          propertyId={propertyIdOfFloorPlanDialog}
          dialogOpen={true}
          closeDialog={closeFloorPlanDialog}
        />
      )}

      {propertyIdOfPropertyImagesDialog && (
        <PropertyImagesDialog
          propertyId={propertyIdOfPropertyImagesDialog}
          dialogOpen={true}
          closeDialog={closePropertyImagesDialog}
        />
      )}
    </Box>
  );
};

export default PropertiesForm;
