import * as React from 'react';
import {
  Grid,
  OutlinedTextFieldProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import parse from 'autosuggest-highlight/parse';
import {
  useGooglePlacesAutocomplete,
  UseGooglePlacesAutocompleteProps,
} from '../../../api/googleMaps/gcpPlaces/hooks';
import { useCurrentLocation } from '../../../utils/geolocation';
import { usePrev } from '../../../utils/hook';
import SearchIcon from '@material-ui/icons/Search';
import { combineQueryParams, parseQueryParamsString } from '../../../utils/url';
import { getUrlSearchString, navigate } from '../../../utils/dom';
import useStyles from './ConnectedAddressAutocomplete.styles';
import {
  AutocompletePrediction,
  PredictionSubString,
} from '../../../api/googleMaps/gcpPlaces/types';

export interface ConnectedAddressAutocompleteProps
  extends OutlinedTextFieldProps,
    UseGooglePlacesAutocompleteProps {
  inputValue: string;
  setInputValue: (newInputValue: string) => void;
  autocompleteValue: AutocompletePrediction | null;
  setAutocompleteValue: (newValue: AutocompletePrediction | null) => void;
  disabled?: boolean;
}

const DEBOUNCE_TIME = 150; // ms

export default function ConnectedAddressAutocomplete({
  autocompleteService,
  placesService,
  inputValue,
  setInputValue,
  autocompleteValue,
  setAutocompleteValue,
  predictions,
  setPredictions,
  placeDetails,
  setPlaceDetails,
  disabled,
  ...propsFromParent
}: ConnectedAddressAutocompleteProps) {
  const classes = useStyles();

  const currentLocation = useCurrentLocation();

  const { clearPredictions, getPredictions, getPlaceDetails } =
    useGooglePlacesAutocomplete({
      autocompleteService,
      placesService,
      predictions,
      setPredictions,
      placeDetails,
      setPlaceDetails,
    });

  const [dbTimerRunning, setDbTimerRunning] = React.useState(false);

  const DISTANCE_FROM_LOCATION_CAP = 64; // km
  const bedroomTypes = {
    Any: -1,
    Studio: 0,
    '1 Bedroom': 1,
    '2 Bedroom': 2,
    '3 Bedroom': 3,
    '4 Bedroom': 4,
  };
  const filters = {
    radius: -1,
    bedroomType: bedroomTypes.Any,
    maxRent: 0,
  };
  let pathName = '';
  if (typeof location !== 'undefined') {
    pathName = location.pathname;
  }
  const search = getUrlSearchString(true);
  let searchData: Record<string, string> = {};
  if (search) {
    searchData = parseQueryParamsString(search);
  }
  const prevInputValue = usePrev(inputValue);

  React.useEffect(() => {
    if (!inputValue && predictions && predictions.length > 0) {
      clearPredictions(autocompleteValue ?? undefined);
      setPlaceDetails(null);
      return;
    }
  }, [inputValue, autocompleteValue, setPlaceDetails, predictions]);

  React.useEffect(() => {
    if (!dbTimerRunning && inputValue && inputValue !== prevInputValue) {
      // Debounce place prediction execution
      setDbTimerRunning(true);
      window.setTimeout(() => {
        setDbTimerRunning(false);
      }, DEBOUNCE_TIME);

      getPredictions(
        inputValue,
        currentLocation ? currentLocation : undefined,
        currentLocation ? 5000 : undefined
      );
    }
  }, [
    inputValue,
    currentLocation,
    getPredictions,
    setPredictions,
    setDbTimerRunning,
  ]);

  // Input means the actual text input
  const handleInputChange = (e: any, newInputValue: string) => {
    setInputValue(newInputValue);
    if (e && e.key === 'Enter') {
      setInputValue(e.target.value);
      initiateSearch(e.target.value);
    }
  };

  // Value means the selected prediction result. This is called when a
  // prediction result is selected.
  const handleValueChange = (
    e: any,
    newValue: AutocompletePrediction | null
  ) => {
    setAutocompleteValue(newValue);
    if (newValue) {
      getPlaceDetails(newValue.place_id);
    }
  };
  const initiateSearch = (newInputValue: string) => {
    const urlComponents = {
      address: newInputValue,
      radius:
        filters.radius > 0
          ? Math.min(filters.radius, DISTANCE_FROM_LOCATION_CAP) * 1000
          : 5000,
      maxRentPcm: filters.maxRent === 0 ? undefined : filters.maxRent,
      ['bedroom']: filters.bedroomType === -1 ? undefined : filters.bedroomType,
    };

    // a.k.a. "search"
    const urlQueryString = combineQueryParams(urlComponents);
    if (pathName === '/map/') {
      void navigate(`/map/?${urlQueryString}`);
    } else {
      void navigate(`/properties/?${urlQueryString}`);
    }
  };

  return (
    <Autocomplete
      className={classes.autocomplete}
      // Behaviour props. These are props related to the behaviours of the
      // Autocomplete itself.
      freeSolo
      disabled={disabled}
      // Input props. These are props related to the <input> component of the
      // Autocomplete
      includeInputInList
      onInputChange={handleInputChange}
      renderInput={(localProps: any) => {
        localProps.inputProps.value = inputValue;
        return (
          <>
            <SearchIcon className={classes.iconSearch} />
            <TextField
              {...localProps}
              placeholder="SE1, London, or Waterloo Station..."
              className={classes.textSearch}
              onChange={(e) => setInputValue(e.target.value)}
              {...propsFromParent}
            />
          </>
        );
      }}
      // Value props. These are props related to the selected option component
      // of the Autocomplete
      value={autocompleteValue ?? null}
      // inputValue={inputValue}
      autoComplete
      onChange={handleValueChange}
      // Options props. These are props related to the drop down box of the
      // Autocomplete
      options={predictions ?? []}
      getOptionLabel={(option: AutocompletePrediction) =>
        option.description ?? searchData.address
      }
      getOptionSelected={(
        option: AutocompletePrediction,
        value: AutocompletePrediction
      ) => (option && value ? option.place_id === value.place_id : false)}
      filterOptions={(x) => x}
      filterSelectedOptions
      renderOption={(option: AutocompletePrediction) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: PredictionSubString) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOn className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
