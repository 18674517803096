import * as React from 'react';
import { Box, TextField, useTheme } from '@material-ui/core';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../../listNewDevelopment/context';
import { setInput } from '../../listNewDevelopment/actions';
import {
  DevelopmentInputs,
  ListNewDevelopmentInputName,
} from '../../listNewDevelopment/models';

// ========== TYPES ========== //

interface ComponentProps {
  flatId?: string;
  category?: keyof DevelopmentInputs;
  name: ListNewDevelopmentInputName;
  label?: string;
}

// ========== COMPONENT ========== //

const NewPropertyFormTextAreaInput = ({
  flatId,
  category,
  name,
  label,
}: ComponentProps) => {
  const theme = useTheme();

  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  const value = flatId
    ? state.propertyInputs[flatId][name].value
    : category
    ? state.developmentInputs[category][name].value
    : '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setInput({
        flatId,
        category,
        name,
        value: e.target.value,
      })
    );
  };

  return (
    <Box maxWidth={theme.breakpoints.values.sm}>
      <TextField
        label={label}
        name={name}
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        multiline
        rowsMax={5}
        onChange={handleChange}
        value={value}
      />
    </Box>
  );
};

export default NewPropertyFormTextAreaInput;
