import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    autocomplete: {
      width: '100%',
      position: 'relative',
      '& .MuiAutocomplete-inputRoot': {
        '& .MuiAutocomplete-input:first-child': {
          paddingLeft: 40,
        },
      },
    },

    iconSearch: {
      position: 'absolute',
      color: theme.palette.gray.main,
      left: 20,
      top: 'calc(50% - 0.5em)',
    },

    textSearch: {
      '& input': {
        paddingLeft: '40px',
      },
      '& fieldset': {
        borderColor: `${theme.palette.primary.main}`,
        borderWidth: 2,
      },
      '& .MuiOutlinedInput-root:hover fieldset': {
        borderColor: `${theme.palette.primary.main}`,
      },
    },

    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
  })
);
