import { createAction } from '@reduxjs/toolkit';
import {
  DevelopmentInputs,
  ImageFileData,
  ListNewDevelopmentInputName,
  SingleFile,
} from './models';
import { ImageFile } from '../../my-account/property/editDEPRECATED/file-upload/image/types';

export interface WithFlatId {
  flatId?: string; // If not provided, will assume it's for the development
}

/**
 * ADD PROPERTY
 * ------------
 */

export const addProperty = createAction(
  '@LIST_NEW_DEVELOPMENT/ADD_NEW_PROPERTY'
);

/**
 * REMOVE PROPERTY
 * ---------------
 * Remove a property. Need to provide the property's ID as payload.
 */

export const removeProperty = createAction<string>(
  '@LIST_NEW_DEVELOPMENT/REMOVE_PROPERTY'
);

/**
 * SET INPUT
 * ---------
 */

export interface SetInputProps extends WithFlatId {
  // This is required for updating a development input
  category?: keyof DevelopmentInputs;

  // Name of the input itself
  name: ListNewDevelopmentInputName;

  // If not provided, will default to 'value'. Some inputs can have various
  // values within it. So this is required in those case.
  valueName?: string;

  // The new value of the input. The previous value will be overwritten so be
  // careful when dealing with non-scalar values (arrays and objects).
  value:
    | number
    | string
    | boolean
    | null
    | Date
    | unknown[]
    | Record<string, unknown>;
}

export const setInput = createAction<SetInputProps>(
  '@LIST_NEW_DEVELOPMENT/SET_INPUT'
);

/**
 * SET IMAGE FILES INPUT
 * ---------------------
 */

export interface SetImageFilesInputProps extends WithFlatId {
  inputName: 'floorPlanFiles' | 'imageFiles';
  fileData: ImageFile[];
}

export const setImageFilesInput = createAction<SetImageFilesInputProps>(
  '@LIST_NEW_DEVELOPMENT/SET_IMAGE_FILES_INPUT'
);

/**
 * ADD IMAGES
 * ------------
 */

export interface AddImagesProps extends WithFlatId {
  inputName: 'floorPlanFiles' | 'imageFiles';
  fileData: ImageFile[];
}

export const addImages = createAction<AddImagesProps>(
  '@LIST_NEW_DEVELOPMENT/ADD_IMAGES'
);

/**
 * REMOVE IMAGES
 * -------------
 */

export interface RemoveImagesProps extends WithFlatId {
  inputName: 'floorPlanFiles' | 'imageFiles';
  fileData: ImageFile[];
}

export const removeImages = createAction<RemoveImagesProps>(
  '@LIST_NEW_DEVELOPMENT/REMOVE_IMAGES'
);

/**
 * MAKE IMAGE PRIMARY
 * ------------------
 */

export interface MakeImagePrimaryProps extends WithFlatId {
  inputName: 'floorPlanFiles' | 'imageFiles';
  fileData: ImageFile;
}

export const makeImagePrimary = createAction<MakeImagePrimaryProps>(
  '@LIST_NEW_DEVELOPMENT/MAKE_IMAGE_PRIMARY'
);

/**
 * CLEAR IMAGES
 * ------------
 * Remove all images.
 */

export interface ClearImagesProps extends WithFlatId {
  inputName: 'floorPlanFiles' | 'imageFiles';
}

export const clearImages = createAction<ClearImagesProps>(
  '@LIST_NEW_DEVELOPMENT/CLEAR_IMAGES'
);

/**
 * UPLOAD IMAGE (ASYNC)
 * --------------------
 * Upload an image. This puts the image into the user's Storage "folder".
 */

export interface UploadImageRequestProps extends WithFlatId {
  inputName: 'floorPlanFiles' | 'imageFiles';
  imageFile: ImageFile;
}

export const uploadImageRequest = createAction<UploadImageRequestProps>(
  '@LIST_NEW_DEVELOPMENT/UPLOAD_IMAGE_REQUEST'
);

export interface UploadImageSuccessProps extends WithFlatId {
  inputName: 'floorPlanFiles' | 'imageFiles';
  imageFile: ImageFile;
  successResponse: {
    data: any;
    meta: {
      originalname: string;
      clientId: string;
      propertyId?: string;
    };
  };
}

export const uploadImageSuccess = createAction<UploadImageSuccessProps>(
  '@LIST_NEW_DEVELOPMENT/UPLOAD_IMAGE_SUCCESS'
);

export interface UploadImageFailureProps extends WithFlatId {
  inputName: 'floorPlanFiles' | 'imageFiles';
  imageFile: ImageFile;
  failureResponse: Error;
}

export const uploadImageFailure = createAction<UploadImageFailureProps>(
  '@LIST_NEW_DEVELOPMENT/UPLOAD_IMAGE_FAILURE'
);

/**
 * CLEAR ALL INPUTS
 * ----------------
 */

export const clearAllInputs = createAction(
  '@LIST_NEW_DEVELOPMENT/CLEAR_ALL_INPUTS'
);
