/**
 * An image upload area but for only 1 image.
 *
 * Included features:
 * - Add image
 * - Remove image
 */

import React, { useEffect } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ImageFile, ImageUploadStatus } from './types';
import Image from '../imageDEPRECATED/Image';
import ImageUploadSuggestionArea from './ImageUploadSuggestionArea';

// ========== TYPES ========== //

interface OneImageUploadAreaProps {
  imageFile: ImageFile | null;
  setImageFile: (imageFile: ImageFile | null) => void;

  idGenerator: () => string;

  shouldUpload: boolean;
  uploadImage: (imageFile: ImageFile) => void;

  onError: (err: Error) => void;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      position: 'relative',

      width: '100%',
      height: '100%',
      padding: theme.spacing(1),

      border: `3px dashed ${theme.palette.grey['400']}`,
      backgroundColor: theme.palette.grey['200'],
    },
  })
);

// ========== COMPONENT ========== //

const OneImageUploadArea = ({
  imageFile,
  setImageFile,
  idGenerator,
  shouldUpload,
  uploadImage,
  onError,
}: OneImageUploadAreaProps) => {
  const classes = useStyles();

  // Upload the image after it's loaded, if all conditions are met
  useEffect(() => {
    if (
      imageFile &&
      shouldUpload &&
      imageFile.uploadStatus === ImageUploadStatus.NOT_UPLOADED
    ) {
      uploadImage(imageFile);
    }
  }, [imageFile, shouldUpload, uploadImage]);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      if (onError) {
        onError(new Error(`No files were accepted`));
      } else {
        console.error('No files were accepted');
      }

      return;
    }
    // We only consider 1 image
    const f = acceptedFiles[0];

    const id = idGenerator();

    setImageFile({
      id,
      preview: URL.createObjectURL(f),
      uploadStatus: ImageUploadStatus.NOT_UPLOADED,
      file: f,
    });
  };

  const handleDeleteImage = () => {
    if (imageFile) {
      setImageFile(null);
    }
  };

  return (
    <Box className={classes.ctn}>
      {imageFile ? (
        <Image
          imageFile={imageFile}
          imageAlt={`image ${
            imageFile && imageFile.file ? imageFile.file.name : 'unknown'
          }`}
          deleteImage={handleDeleteImage}
        />
      ) : (
        <ImageUploadSuggestionArea
          onDrop={onDrop}
          dropzoneOpts={{ multiple: false }}
        />
      )}
    </Box>
  );
};

export default OneImageUploadArea;
