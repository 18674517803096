import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Dishwasher = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="M0,0v512h512V0H0z M480,480H32V160h448V480z M480,128H32V32h448V128z" />
      <rect x="64" y="64" width="32" height="32" />
      <rect x="128" y="64" width="32" height="32" />
      <rect x="192" y="64" width="32" height="32" />
      <rect x="288" y="64" width="160" height="32" />
      <path
        d="M448,192H64v256h384V192z M176,352c-8.837,0-16-7.163-16-16v-48h32v48C192,344.837,184.837,352,176,352z M304.56,416
				c-10.66-13.737-16.481-30.612-16.56-48c0.056-38.048,26.861-70.81,64.144-78.4c-33.018,33.443-41.511,84.004-21.232,126.4H304.56
				z M416,416h-47.44c-10.66-13.737-16.481-30.612-16.56-48c0.048-38.002,26.777-70.745,64-78.4V416z M416,257.28
				c-5.676,0.771-11.282,1.985-16.768,3.632c-59.071-17.499-121.143,16.201-138.643,75.272c-7.842,26.471-5.589,54.91,6.323,79.816
				H192v-34.944c19.11-6.756,31.916-24.787,32-45.056v-80h-96v80c0.084,20.269,12.89,38.3,32,45.056V416H96V224h320V257.28z"
      />
    </SvgIcon>
  );
};

export default Dishwasher;
