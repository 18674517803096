import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function HomeAppliance_Television(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 64 64" {...props}>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g>
        {' '}
        <g>
          {' '}
          <path d="M57.9941,15.54H38.1652c-0.1632-1.0089-0.5668-1.9358-1.1527-2.7228l8.2961-10.1859			c0.3486-0.4282,0.2842-1.0581-0.1436-1.4067c-0.4287-0.3486-1.0596-0.2837-1.4072,0.144l-8.1834,10.0475			c-1.0146-0.7103-2.2464-1.1306-3.5764-1.1306c-1.3295,0-2.561,0.4202-3.5754,1.1304l-7.8904-9.688			c-0.3477-0.4277-0.9775-0.4932-1.4072-0.144c-0.4277,0.3486-0.4922,0.9785-0.1436,1.4067l8.0031,9.8265			c-0.5859,0.787-0.9895,1.7139-1.1527,2.7228H6.002c-2.0957,0-3.7998,1.7065-3.7998,3.8037v35.5381			c0,1.7474,1.1912,3.2076,2.7998,3.6498V62c0,0.5522,0.4473,1,1,1h51.9922c0.5527,0,1-0.4478,1-1v-3.468			c1.6107-0.4419,2.8037-1.9024,2.8037-3.6501V19.3438C61.7979,17.2466,60.0918,15.54,57.9941,15.54z M31.998,12.2856			c2.002,0,3.6855,1.3896,4.1357,3.2544h-8.2705C28.3135,13.6753,29.9971,12.2856,31.998,12.2856z M56.9941,61H7.002v-2.3179			h49.9922V61z M59.7979,54.8818c0,0.9927-0.8096,1.8003-1.8037,1.8003H6.002c-0.9922,0-1.7998-0.8076-1.7998-1.8003V19.3438			c0-0.9946,0.8076-1.8037,1.7998-1.8037h51.9922c0.9941,0,1.8037,0.8091,1.8037,1.8037V54.8818z" />{' '}
          <path d="M55.4844,20.3975H8.5117c-0.5527,0-1,0.4478-1,1V49.458c0,0.5522,0.4473,1,1,1h46.9727c0.5527,0,1-0.4478,1-1V21.3975			C56.4844,20.8452,56.0371,20.3975,55.4844,20.3975z M54.4844,48.458H9.5117V22.3975h44.9727V48.458z" />{' '}
          <path d="M26.0801,35.9038c0.2559,0,0.5127-0.0977,0.707-0.2935l3.5361-3.54c0.3906-0.3906,0.3906-1.0239,0-1.4141			c-0.3906-0.3896-1.0254-0.3906-1.4141,0.001l-3.5361,3.54c-0.3906,0.3906-0.3906,1.0239,0,1.4141			C25.5684,35.8062,25.8242,35.9038,26.0801,35.9038z" />{' '}
          <path d="M29.3447,39.2026c0.1953,0.1953,0.4512,0.293,0.707,0.293s0.5117-0.0977,0.707-0.293l7.8438-7.8438			c0.3906-0.3906,0.3906-1.0234,0-1.4141s-1.0234-0.3906-1.4141,0l-7.8438,7.8438C28.9541,38.1792,28.9541,38.812,29.3447,39.2026z" />{' '}
          <path d="M54.9209,52.4609h-3.5605c-0.5527,0-1,0.4478-1,1s0.4473,1,1,1h3.5605c0.5527,0,1-0.4478,1-1			S55.4736,52.4609,54.9209,52.4609z" />{' '}
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
}
