import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function HomeAppliance_Refrigerator(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 64 64" {...props}>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g>
        {' '}
        <g>
          {' '}
          <path d="M21.083,35.0068c-1.6968,0-3.0776,1.3809-3.0776,3.0776v4.0967c0,1.6968,1.3809,3.0776,3.0776,3.0776			s3.0776-1.3809,3.0776-3.0776v-4.0967C24.1606,36.3877,22.7798,35.0068,21.083,35.0068z M22.1606,42.1812			c0,0.584-0.4937,1.0776-1.0776,1.0776c-0.5942,0-1.0776-0.4834-1.0776-1.0776v-4.0967c0-0.5942,0.4834-1.0776,1.0776-1.0776			s1.0776,0.4834,1.0776,1.0776V42.1812z" />{' '}
          <path d="M21.083,24.7275c1.6968,0,3.0776-1.3809,3.0776-3.0776v-4.0967c0-1.6968-1.3809-3.0776-3.0776-3.0776			s-3.0776,1.3809-3.0776,3.0776v4.0967C18.0054,23.3467,19.3862,24.7275,21.083,24.7275z M20.0054,17.5532			c0-0.5942,0.4834-1.0776,1.0776-1.0776c0.584,0,1.0776,0.4937,1.0776,1.0776v4.0967c0,0.5942-0.4834,1.0776-1.0776,1.0776			s-1.0776-0.4834-1.0776-1.0776V17.5532z" />{' '}
          <path d="M50.2666,1H13.7334c-0.5522,0-1,0.4478-1,1v60c0,0.5522,0.4478,1,1,1h10.4399c0.5522,0,1-0.4478,1-1v-3.0654h13.6533V62			c0,0.5522,0.4478,1,1,1h10.4399c0.5522,0,1-0.4478,1-1V2C51.2666,1.4478,50.8188,1,50.2666,1z M14.7334,56.9346V30.7461h34.5332			v26.1885H14.7334z M49.2666,28.7461H14.7334V7.0654h34.5332V28.7461z M14.7334,3h34.5332v2.0654H14.7334V3z M23.1733,61h-8.4399			v-2.0654h8.4399V61z M49.2666,61h-8.4399v-2.0654h8.4399V61z" />{' '}
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
}
