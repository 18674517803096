import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CommonArea = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path
        d="M476.091,231.332c-1.654-3.318-4.343-6.008-7.662-7.662L24.695,1.804C16.264-2.41,6.013,1.01,1.8,9.442
			c-1.185,2.371-1.801,4.986-1.8,7.637v443.733c-0.004,9.426,7.633,17.07,17.059,17.075c2.651,0.001,5.266-0.615,7.637-1.8
			L468.429,254.22C476.865,250.015,480.295,239.768,476.091,231.332z"
      />
    </SvgIcon>
  );
};

export default CommonArea;
