import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function DoubleSofa(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <path d="M5.22947 4.72317c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-3.63226 0c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l3.63226 0z" />
          <path d="M3.39519 3.70447c0.0429724,0.0101811 0.0860669,-0.0164016 0.096248,-0.059374 0.0101811,-0.0429724 -0.0164016,-0.0860669 -0.059374,-0.096248 -0.344933,-0.0817323 -0.650421,-0.114114 -0.949094,-0.114559 -0.29772,-0.000444882 -0.585386,0.0307835 -0.897331,0.0763819 -0.0436614,0.00638583 -0.0738858,0.0469646 -0.0675,0.090626 0.00638583,0.0436614 0.0469646,0.0738858 0.090626,0.0675 0.306783,-0.0448465 0.588055,-0.0755551 0.874205,-0.0751299 0.285189,0.000425197 0.578567,0.0317441 0.91222,0.110803z" />
          <path d="M1.5972 3.98161c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l3.63227 0c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-3.63227 0z" />
          <path d="M1.07522 3.05696l0.522004 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 1.70433c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-0.663886 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.56245c0,-0.0610984 0.0249409,-0.116591 0.0651142,-0.156764 0.0401732,-0.0401732 0.0956732,-0.0651142 0.156768,-0.0651142zm0.442004 0.16l-0.442004 0c-0.0169331,0 -0.0323898,0.00698819 -0.0436417,0.0182402 -0.011252,0.011252 -0.0182402,0.0267087 -0.0182402,0.0436378l0 1.48245 0.503886 0 0 -1.54433z" />
          <path d="M5.75145 3.21696l-0.442004 0 0 1.54433 0.503886 0 0 -1.48245c0,-0.0169291 -0.00698819,-0.0323858 -0.0182402,-0.0436378 -0.011252,-0.011252 -0.0267087,-0.0182402 -0.0436417,-0.0182402zm-0.522004 -0.16l0.522004 0c0.0610945,0 0.116594,0.0249409 0.156768,0.0651142 0.0401732,0.0401732 0.0651142,0.0956654 0.0651142,0.156764l0 1.56245c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-0.663886 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.70433c0,-0.0441811 0.0358189,-0.08 0.08,-0.08z" />
          <path d="M3.39519 3.54885c-0.0429724,0.0101811 -0.0695551,0.0532756 -0.059374,0.096248 0.0101811,0.0429724 0.0532756,0.0695551 0.096248,0.059374 0.333654,-0.0790591 0.627031,-0.110378 0.91222,-0.110803 0.28615,-0.000425197 0.567421,0.0302835 0.874205,0.0751299 0.0436614,0.00638583 0.0842402,-0.0238386 0.090626,-0.0675 0.00638583,-0.0436614 -0.0238386,-0.0842402 -0.0675,-0.090626 -0.311945,-0.0455984 -0.59961,-0.0768268 -0.897331,-0.0763819 -0.298673,0.000444882 -0.604161,0.0328268 -0.949094,0.114559z" />
          <path d="M1.18528 3.13696c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -0.867185c0,-0.056126 0.0230197,-0.107213 0.0601024,-0.144295 0.0370827,-0.0370827 0.0881693,-0.0601024 0.144295,-0.0601024l1.57927 0c0.056122,0 0.107209,0.0230236 0.144291,0.0601063 0.0370827,0.0370827 0.0601063,0.0881693 0.0601063,0.144291l0 1.34656c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -1.34656c0,-0.100291 -0.0409764,-0.191413 -0.10698,-0.257417 -0.066,-0.0660039 -0.157126,-0.10698 -0.257417,-0.10698l-1.57927 0c-0.100291,0 -0.191413,0.0409685 -0.257421,0.106976 -0.0660079,0.0660079 -0.106976,0.15713 -0.106976,0.257421l0 0.867185z" />
          <path d="M5.48141 3.13696c0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08l0 -0.867185c0,-0.100291 -0.0409685,-0.191413 -0.106976,-0.257421 -0.0660079,-0.0660079 -0.15713,-0.106976 -0.257421,-0.106976l-1.57927 0c-0.100291,0 -0.191417,0.0409764 -0.257417,0.10698 -0.0660039,0.0660039 -0.10698,0.157126 -0.10698,0.257417 0,0.0441811 0.0358189,0.08 0.08,0.08 0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.056122 0.0230236,-0.107209 0.0601063,-0.144291 0.0370827,-0.0370827 0.0881693,-0.0601063 0.144291,-0.0601063l1.57927 0c0.056126,0 0.107213,0.0230197 0.144295,0.0601024 0.0370827,0.0370827 0.0601024,0.0881693 0.0601024,0.144295l0 0.867185z" />
        </g>
      </g>
    </SvgIcon>
  );
}
