import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const NoSmoking = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path d="m75.387 75.235c-162.375 162.428-44.198 436.765 180.85 436.765 141.349 0 255.763-114.429 255.763-255.852 0-225.841-274.981-342.604-436.613-180.913zm180.85-44.954c55.287 0 107.533 19.756 148.706 55.908l-72.99 73.017h-120.674c-8.265 0-14.986-6.725-14.986-14.991s6.722-14.991 14.986-14.991h104.902v-49.972h-29.973v19.988h-74.93c-24.791 0-44.959 20.175-44.959 44.975 0 24.799 20.168 44.974 44.959 44.974h90.701l-31.969 31.981h-193.606v104.938h88.704l-78.771 78.799c-128.442-146.379-22.509-374.626 169.9-374.626zm-61.156 265.845h-13.775v-44.974h58.733zm87.345-44.974h64.227v44.974h-109.185zm-176.05 44.974v-44.974h44.959v44.974zm1.154 129.982 99.965-100h169.13v-104.938h-64.228l31.97-31.98c17.517.024 31.76 14.285 31.76 31.813h29.972c0-25.253-15.227-47.003-36.974-56.579l57.013-57.033c36.142 41.188 55.891 93.451 55.891 148.758-.002 193.332-229.074 297.653-374.499 169.959z" />
      <path d="m406.098 221.17h29.973v104.938h-29.973z" />
    </SvgIcon>
  );
};

export default NoSmoking;
