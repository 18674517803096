import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function Cabinets(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <path d="M1.70667 0.853331l3.41333 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 1.20667c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-3.41333 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.20667c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm3.33333 0.16l-3.25333 0 0 1.04667 3.25333 0 0 -1.04667z" />
          <path d="M1.78667 3.26666l3.25333 0 0 -1.04667 -3.25333 0 0 1.04667zm3.33333 0.16l-3.41333 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.20667c0,-0.0441811 0.0358189,-0.08 0.08,-0.08l3.41333 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 1.20667c0,0.0441811 -0.0358189,0.08 -0.08,0.08z" />
          <path d="M1.70667 3.26666l3.41333 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 1.20667c0,0.0441811 -0.0358189,0.08 -0.08,0.08l-3.41333 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.20667c0,-0.0441811 0.0358189,-0.08 0.08,-0.08zm3.33333 0.16l-3.25333 0 0 1.04667 3.25333 0 0 -1.04667z" />
          <path d="M1.78667 5.68l3.25333 0 0 -1.04667 -3.25333 0 0 1.04667zm3.33333 0.16l-3.41333 0c-0.0441811,0 -0.08,-0.0358189 -0.08,-0.08l0 -1.20667c0,-0.0441811 0.0358189,-0.08 0.08,-0.08l3.41333 0c0.0441811,0 0.08,0.0358189 0.08,0.08l0 1.20667c0,0.0441811 -0.0358189,0.08 -0.08,0.08z" />
          <g></g>
          <path d="M3.09333 1.45667c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l0.64 0c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-0.64 0z" />
          <path d="M3.09333 2.66333c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l0.64 0c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-0.64 0z" />
          <path d="M3.09333 3.87c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l0.64 0c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-0.64 0z" />
          <path d="M3.09333 5.07666c-0.0441811,0 -0.08,0.0358189 -0.08,0.08 0,0.0441811 0.0358189,0.08 0.08,0.08l0.64 0c0.0441811,0 0.08,-0.0358189 0.08,-0.08 0,-0.0441811 -0.0358189,-0.08 -0.08,-0.08l-0.64 0z" />
        </g>
      </g>
    </SvgIcon>
  );
}
