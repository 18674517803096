import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function HomeAppliance_Stove(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 64 64" {...props}>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g>
        {' '}
        <g>
          {' '}
          <path d="M57.8506,4.9443h-3.5771V2c0-0.5522-0.4473-1-1-1H36.6396c-0.5527,0-1,0.4478-1,1v2.9443h-7.6455V2c0-0.5522-0.4478-1-1-1			H10.3599c-0.5522,0-1,0.4478-1,1v2.9443H6.1489c-0.5522,0-1,0.4478-1,1v52.519V62c0,0.5522,0.4478,1,1,1h7.9375			c0.5522,0,1-0.4478,1-1v-2.5366h33.8237V62c0,0.5522,0.4473,1,1,1h7.9404c0.5527,0,1-0.4478,1-1v-3.5366V5.9443			C58.8506,5.3921,58.4033,4.9443,57.8506,4.9443z M7.1489,57.4634V21.3672h49.7017v36.0962H7.1489z M37.6396,3h14.6338v1.9443			H37.6396V3z M11.3599,3h14.6343v1.9443H11.3599V3z M7.1489,6.9443h49.7017v12.4229H7.1489V6.9443z M13.0864,61H7.1489v-1.5366			h5.9375V61z M56.8506,61h-5.9404v-1.5366h5.9404V61z" />{' '}
          <path d="M17.9893,9.6045c-1.8154,0-3.292,1.4751-3.292,3.2886c0,1.8154,1.4766,3.292,3.292,3.292s3.292-1.4766,3.292-3.292			C21.2813,11.0796,19.8047,9.6045,17.9893,9.6045z M17.9893,14.1851c-0.7124,0-1.292-0.5796-1.292-1.292			c0-0.7104,0.5796-1.2886,1.292-1.2886s1.292,0.5781,1.292,1.2886C19.2813,13.6055,18.7017,14.1851,17.9893,14.1851z" />{' '}
          <path d="M34.043,14.3857H28.519c-0.5522,0-1,0.4478-1,1s0.4478,1,1,1h5.5239c0.5527,0,1-0.4478,1-1			S34.5957,14.3857,34.043,14.3857z" />{' '}
          <path d="M28.4219,12.2578h5.5205c0.5527,0,1-0.4478,1-1s-0.4473-1-1-1h-5.5205c-0.5522,0-1,0.4478-1,1			S27.8696,12.2578,28.4219,12.2578z" />{' '}
          <path d="M50.6191,29.5205H13.3809c-1.5386,0-2.7905,1.2505-2.7905,2.7871v18.9238c0,1.5386,1.252,2.7905,2.7905,2.7905h37.2383			c1.5371,0,2.7871-1.252,2.7871-2.7905V32.3076C53.4063,30.771,52.1563,29.5205,50.6191,29.5205z M51.4063,51.2314			c0,0.4287-0.3604,0.7905-0.7871,0.7905H13.3809c-0.436,0-0.7905-0.3545-0.7905-0.7905V32.3076			c0-0.4268,0.3618-0.7871,0.7905-0.7871h37.2383c0.4268,0,0.7871,0.3604,0.7871,0.7871V51.2314z" />{' '}
          <path d="M52.4063,24.8086H11.5903c-0.5522,0-1,0.4478-1,1s0.4478,1,1,1h40.8159c0.5527,0,1-0.4478,1-1			S52.959,24.8086,52.4063,24.8086z" />{' '}
          <path d="M30.7329,37.2046c-0.3906-0.3906-1.0234-0.3906-1.4141,0L25.0635,41.46c-0.3906,0.3906-0.3906,1.0234,0,1.4141			c0.1953,0.1953,0.4512,0.293,0.707,0.293s0.5117-0.0977,0.707-0.293l4.2554-4.2554			C31.1235,38.228,31.1235,37.5952,30.7329,37.2046z" />{' '}
          <path d="M39.2715,36.3472l-9.4307,9.4307c-0.3906,0.3906-0.3906,1.0234,0,1.4141c0.1953,0.1953,0.4512,0.293,0.707,0.293			s0.5117-0.0977,0.707-0.293l9.4307-9.4307c0.3906-0.3906,0.3906-1.0234,0-1.4141S39.6621,35.9565,39.2715,36.3472z" />{' '}
          <path d="M44.6729,9.3555c-1.8154,0-3.292,1.4766-3.292,3.292s1.4766,3.292,3.292,3.292s3.292-1.4766,3.292-3.292			S46.4883,9.3555,44.6729,9.3555z M44.6729,13.9395c-0.7129,0-1.292-0.5796-1.292-1.292s0.5791-1.292,1.292-1.292			s1.292,0.5796,1.292,1.292S45.3857,13.9395,44.6729,13.9395z" />{' '}
        </g>
      </g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
}
