import * as React from 'react';
import SEO from '../../components/atoms/SEO';
import GenericPage from '../../components/layout/CommonLayout/GenericPage';
import Layout from '../../components/layout/CommonLayout/Layout';
import {
  DevelopmentInputs,
  PropertyInputs,
} from '../../components/pages/let/listNewDevelopment/models';
import { ListNewDevelopmentProvider } from '../../components/pages/let/listNewDevelopment/context';
import { ListNewDevelopmentForm } from '../../components/pages/let/ListNewDevelopmentForm';

export interface RequestBodyDevelopmentData extends DevelopmentInputs {
  geometry: { lat: number; lng: number } | undefined;
  imgIds: string[];
  flatsData: Record<string, PropertyInputs>;
}

// ========== COMPONENT ========== //

const ListNewDevelopmentPage = () => {
  return (
    <Layout>
      <SEO title="List new development" />
      <GenericPage>
        <ListNewDevelopmentProvider>
          <ListNewDevelopmentForm />
        </ListNewDevelopmentProvider>
      </GenericPage>
    </Layout>
  );
};

export default ListNewDevelopmentPage;
