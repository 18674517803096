import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function Weightlifting(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 6.82666 6.82666" {...props}>
      <g>
        <g>
          <path
            className="fil-1"
            d="M2.17683 3.46667c-0.055126,0 -0.099815,-0.0477598 -0.099815,-0.106669 0,-0.0589094 0.044689,-0.106669 0.099815,-0.106669l2.47294 0c0.055122,0 0.099815,0.0477598 0.099815,0.106669 0,0.0589094 -0.0446929,0.106669 -0.099815,0.106669l-2.47294 0z"
          />
          <path
            className="fil-1"
            d="M5.67035 3.46667c-0.059,0 -0.106831,-0.0477598 -0.106831,-0.106669 0,-0.0589094 0.0478307,-0.106669 0.106831,-0.106669l0.194028 0c0.0589961,0 0.106831,0.0477598 0.106831,0.106669 0,0.0589094 -0.0478346,0.106669 -0.106831,0.106669l-0.194028 0z"
          />
          <path
            className="fil-1"
            d="M0.958039 3.46667c-0.0589961,0 -0.106831,-0.0477598 -0.106831,-0.106669 0,-0.0589094 0.0478346,-0.106669 0.106831,-0.106669l0.198126 0c0.0589961,0 0.106831,0.0477598 0.106831,0.106669 0,0.0589094 -0.0478346,0.106669 -0.106831,0.106669l-0.198126 0z"
          />
          <path
            className="fil-1"
            d="M1.77134 2.23999l0.186 0c0.0760709,0 0.145622,0.029937 0.196374,0.0781575 0.0521417,0.0495394 0.0845157,0.118154 0.0845157,0.19387l0 1.69595c0,0.0757205 -0.032374,0.144335 -0.0845157,0.193874 -0.050752,0.0482205 -0.120303,0.0781575 -0.196374,0.0781575l-0.186 0c-0.0760866,0 -0.145638,-0.0299409 -0.196402,-0.0781535 -0.0521535,-0.0495433 -0.0845236,-0.118161 -0.0845236,-0.193878l0 -1.69595c0,-0.0757205 0.0323701,-0.144335 0.0845236,-0.193874 0.0507717,-0.0482165 0.120319,-0.0781535 0.196402,-0.0781535zm0.186 0.213339l-0.186 0c-0.019685,0 -0.037185,0.00727559 -0.0495079,0.0189803 -0.0109528,0.0104016 -0.017752,0.0244803 -0.017752,0.0397087l0 1.69595c0,0.0152283 0.00679921,0.029311 0.017752,0.0397126 0.0123228,0.0117087 0.0298268,0.0189803 0.0495079,0.0189803l0.186 0c0.0196654,0 0.0371575,-0.00727559 0.0494843,-0.0189843 0.0109449,-0.0103976 0.0177402,-0.0244803 0.0177402,-0.0397087l0 -1.69595c0,-0.0152283 -0.00679528,-0.0293071 -0.0177402,-0.0397047 -0.0123268,-0.0117087 -0.0298189,-0.0189843 -0.0494843,-0.0189843z"
          />
          <path
            className="fil-1"
            d="M1.45798 2.77316l-0.148795 0c-0.0133425,0 -0.0242913,0.00347638 -0.0311693,0.00906693 -0.00023622,0.000192913 -0.000444882,-0.000200787 -0.000625984,-0.000767717l-0.000637795 -0.000783465 0 1.15862 0.000629921 -0.000775591c0.000177165,-0.000570866 0.000393701,-0.00096063 0.000629921,-0.000767717 0.00688189,0.00559449 0.0178307,0.00907087 0.0311732,0.00907087l0.148795 0c0.0133425,0 0.0242913,-0.00348031 0.0311732,-0.00907087 0.00023622,-0.000192913 0.000448819,0.00019685 0.000625984,0.000767717l0.000629921 0.000775591 0 -1.15862 -0.000637795 0.000783465c-0.000181102,0.000566929 -0.000385827,0.00096063 -0.000622047,0.000767717 -0.00688189,-0.00559055 -0.0178268,-0.00906693 -0.0311693,-0.00906693zm-0.148795 -0.213339l0.148795 0c0.0632677,0 0.121957,0.0219882 0.165539,0.0574055 0.0497008,0.0403937 0.0805551,0.0977992 0.0805551,0.162429l0 1.16066c0,0.0646417 -0.0308465,0.122043 -0.0805512,0.162441 -0.0435866,0.0354134 -0.102268,0.0574094 -0.165543,0.0574094l-0.148795 0c-0.0632795,0 -0.121957,-0.0219961 -0.165543,-0.0574094 -0.0497047,-0.0403976 -0.0805512,-0.0977992 -0.0805512,-0.162441l0 -1.16066c0,-0.0646299 0.0308543,-0.122035 0.0805551,-0.162429 0.0435827,-0.0354173 0.102268,-0.0574055 0.165539,-0.0574055zm-0.0317953 0.221638c-0.000397638,-0.00128346 -0.000637795,-0.00346457 -0.000637795,-0.00180315l0 0.00101969 0.000637795 0.000783465zm-0.000637795 1.15784l0 0.00101575c0,0.00164961 0.000232283,-0.000511811 0.000629921,-0.00179134l-0.000629921 0.000775591zm0.213028 -0.000775591c0.000397638,0.00127953 0.000629921,0.00344094 0.000629921,0.00179134l0 -0.00101575 -0.000629921 -0.000775591zm0.000629921 -1.15785l0 -0.00101969c0,-0.00166142 -0.000240157,0.000519685 -0.000637795,0.00180315l0.000637795 -0.000783465z"
          />
          <path
            className="fil-1"
            d="M5.05535 2.45333l-0.186 0c-0.0196693,0 -0.0371614,0.00727165 -0.0494882,0.0189803 -0.0109449,0.0103976 -0.0177441,0.0244764 -0.0177441,0.0397047l0 1.69595c0,0.0152283 0.00679921,0.0293071 0.0177441,0.0397047 0.0123268,0.0117087 0.0298189,0.0189843 0.0494882,0.0189843l0.186 0c0.0196772,0 0.0371772,-0.00727165 0.0495039,-0.0189803 0.0109449,-0.0104016 0.017748,-0.0244803 0.017748,-0.0397087l0 -1.69595c0,-0.0152283 -0.00679921,-0.0293071 -0.017748,-0.0397047 -0.0123268,-0.0117087 -0.0298268,-0.0189803 -0.0495039,-0.0189803zm-0.186 -0.213339l0.186 0c0.0760827,0 0.145634,0.0299331 0.196398,0.0781535 0.0521496,0.0495315 0.0845157,0.11815 0.0845157,0.19387l0 1.69595c0,0.0757205 -0.0323701,0.144339 -0.0845157,0.193874 -0.0507638,0.0482205 -0.120315,0.0781535 -0.196398,0.0781535l-0.186 0c-0.0760748,0 -0.145618,-0.029937 -0.196382,-0.0781575 -0.0521457,-0.0495354 -0.0845118,-0.11815 -0.0845118,-0.19387l0 -1.69595c0,-0.0757205 0.0323701,-0.144331 0.0845118,-0.19387 0.0507598,-0.0482244 0.120307,-0.0781535 0.196382,-0.0781535z"
          />
          <path
            className="fil-1"
            d="M5.3687 2.55982l0.148795 0c0.0632677,0 0.121957,0.0219961 0.165539,0.0574055 0.0497008,0.0403937 0.0805551,0.0977992 0.0805551,0.162429l0 1.16066c0,0.0646417 -0.0308465,0.122043 -0.0805512,0.162441 -0.0435866,0.0354134 -0.102272,0.0574094 -0.165543,0.0574094l-0.148795 0c-0.0632795,0 -0.121957,-0.0219961 -0.165543,-0.0574094 -0.0497047,-0.0403976 -0.0805512,-0.0977992 -0.0805512,-0.162441l0 -1.16066c0,-0.0646299 0.0308543,-0.122035 0.0805551,-0.162429 0.0435827,-0.0354173 0.102268,-0.0574055 0.165539,-0.0574055zm0.148795 0.213339l-0.148795 0c-0.0133425,0 -0.0242913,0.00347638 -0.0311693,0.00906693 -0.00023622,0.000192913 -0.000444882,-0.000200787 -0.000625984,-0.000767717l-0.000637795 -0.000783465 0 1.15862 0.000629921 -0.000775591c0.000177165,-0.000570866 0.000393701,-0.00096063 0.000629921,-0.000767717 0.00687795,0.00559055 0.0178307,0.00907087 0.0311732,0.00907087l0.148795 0c0.0133386,0 0.0242874,-0.00347638 0.0311732,-0.00907087 0.00023622,-0.000192913 0.000448819,0.00019685 0.000625984,0.000767717l0.000633858 0.000775591 0 -1.15862 -0.000641732 0.000783465c-0.000181102,0.000566929 -0.000385827,0.00096063 -0.000622047,0.000767717 -0.00688189,-0.00559055 -0.0178307,-0.00906693 -0.0311693,-0.00906693zm0.0324331 0.00649606c0,-0.00166142 -0.000244094,0.000519685 -0.000641732,0.00180315l0.000641732 -0.000783465 0 -0.00101969zm0 1.16066l0 -0.00101575 -0.000633858 -0.000775591c0.000397638,0.00127953 0.000633858,0.00344094 0.000633858,0.00179134zm-0.213661 0c0,0.00164961 0.000232283,-0.000511811 0.000629921,-0.00179134l-0.000629921 0.000775591 0 0.00101575zm0 -1.16066l0 0.00101969 0.000637795 0.000783465c-0.000397638,-0.00128346 -0.000637795,-0.00346457 -0.000637795,-0.00180315z"
          />
        </g>
        <rect className="fil-0" width="6.82666" height="6.82666" />
      </g>
    </SvgIcon>
  );
}
