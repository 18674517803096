import * as React from 'react';
import {
  Box,
  BoxProps,
  colors,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { Create } from '@material-ui/icons';
import clsx from 'clsx';
import { useListNewDevelopmentState } from '../../listNewDevelopment/context';
import {
  CategoryStatus,
  getMissingInputs,
} from '../../listNewDevelopment/utils';

// ========== TYPES ========== //

interface ComponentProps {
  currentTab: string;
  setCurrentTab: (newTab: string) => void;

  tabsCtnProps?: BoxProps;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {},

    tab: {
      width: '100%',
      textTransform: 'none',
    },

    tabSelected: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },

    tabDefault: {
      color: theme.palette.grey['900'],
    },
    tabNotStarted: {
      color: theme.palette.grey['500'],
    },
    tabStarted: {
      color: colors.blue['900'],
    },
    tabCompleted: {
      color: theme.palette.success.main,
    },

    tabLabel: {
      fontWeight: 'inherit',
      color: 'inherit',
      textAlign: 'left',
    },

    tabIconCtn: {
      display: 'flex',
      alignItems: 'center',
    },

    tabLabelIcon: {
      minHeight: 'inherit',
    },

    tabLabelIconWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',

      '& > *:first-child': {
        paddingRight: theme.spacing(2),
      },
    },
  })
);

// ========== COMPONENT ========== //

export const newPropertyTabsInfo: Record<string, { label: string }> = {
  basicDetails: {
    label: 'Description',
  },
  developmentFacilities: {
    label: 'Development facilities',
  },
  propertyFacilities: {
    label: 'Property facilities',
  },
  media: {
    label: 'Visual media',
  },
  livingAtmosphere: {
    label: 'Living atmosphere',
  },
  legal: {
    label: 'Terms of tenancy',
  },
  flats: {
    label: 'Properties',
  },
};

const TabsSelectionPanel = ({
  currentTab,
  setCurrentTab,
  tabsCtnProps,
}: ComponentProps) => {
  const classes = useStyles();

  const listNewDevelopmentState = useListNewDevelopmentState();

  const handleChangeTab = (
    e: React.ChangeEvent<Record<string, unknown>>,
    newValue: string
  ) => {
    setCurrentTab(newValue);
  };

  const tabEls = Object.entries(newPropertyTabsInfo).map(
    ([name, { label }]) => {
      const { categoryStatus } = getMissingInputs({
        categoryName: name,
        state: listNewDevelopmentState,
      });

      const labelEl = (
        <Typography className={classes.tabLabel}>{label}</Typography>
      );

      return (
        <Tab
          className={clsx(
            classes.tab,
            categoryStatus === CategoryStatus.NOT_APPLICABLE &&
              classes.tabDefault,
            categoryStatus === CategoryStatus.NOT_STARTED &&
              classes.tabNotStarted,
            categoryStatus === CategoryStatus.STARTED && classes.tabStarted,
            categoryStatus === CategoryStatus.COMPLETED && classes.tabCompleted
          )}
          key={name}
          label={labelEl}
          value={name}
          icon={
            <Box className={classes.tabIconCtn}>
              <Create />
            </Box>
          }
          classes={{
            selected: classes.tabSelected,
            labelIcon: classes.tabLabelIcon,
            wrapper: classes.tabLabelIconWrapper,
          }}
        />
      );
    }
  );

  return (
    <Box {...tabsCtnProps}>
      <Tabs
        className={classes.tabs}
        aria-label="Select a tab"
        orientation="vertical"
        variant="scrollable"
        value={currentTab}
        onChange={handleChangeTab}
      >
        {tabEls}
      </Tabs>
    </Box>
  );
};

export default TabsSelectionPanel;
