import React, { useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Link } from '../../../atoms';
import {
  useListNewDevelopmentDispatch,
  useListNewDevelopmentState,
} from '../listNewDevelopment/context';
import useAuthToken from '../../../../store/auth/hooks/useAuthToken';
import useAuthProfile from '../../../../store/auth/hooks/useAuthProfile';
import Snackbar, { SnackbarType } from '../../../molecules/Snackbar';
import TabsSelectionPanel from './layout/TabsSelectionPanel';
import Tab1 from './layout/Tab1';
import Tab2 from './layout/Tab2';
import Tab3 from './layout/Tab3';
import Tab4 from './layout/Tab4';
import Tab5 from './layout/Tab5';
import Tab6 from './layout/Tab6';
import Tab7 from './layout/Tab7';
import ButtonWithProgress from '../../../molecules/ButtonWithProgress/ButtonWithProgress';
import { listNewDevelopment } from '../listNewDevelopment/utils';
import useSnackbar from '../../../molecules/Snackbar/useSnackbar';
import { clearAllInputs } from '../listNewDevelopment/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      minHeight: '100%',

      [theme.breakpoints.up('md')]: {
        width: theme.breakpoints.values.md,
      },
    },

    ctn: {
      display: 'grid',

      // In mobile view, we want to display the page as a pancake
      grid: `${theme.spacing(10)}px auto / auto`,

      // In desktop view, we split the page
      [theme.breakpoints.up('md')]: {
        grid: `${theme.spacing(10)}px auto / ${theme.spacing(30)}px auto`,
      },

      width: '100%',
    },

    // There are 3 sections:
    // - Tabs selection: the section where user selects tabs
    // - Tabs container: the section with tab contents
    // - Control panel: the section with main control buttons (e.g. Publish
    //   development, etc.)

    tabsSelectionCtn: {
      [theme.breakpoints.up('md')]: {
        gridColumn: '1 / 2',
        gridRow: '2 / 3',
      },
    },

    tabsCtn: {
      [theme.breakpoints.up('md')]: {
        gridColumn: '2 / 3',
        gridRow: '2 / 3',

        maxWidth: `calc(${theme.breakpoints.values.md}px - ${theme.spacing(
          30
        )}px)`,
      },

      maxWidth: '100vw',
    },

    controlPanelCtn: {
      [theme.breakpoints.up('md')]: {
        gridColumn: '1 / 3',
        gridRow: '1 / 2',
      },

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },

    // Misc.

    btn: {
      marginLeft: theme.spacing(1),
      width: theme.spacing(15),
    },

    bold: {
      fontWeight: 'bold',
    },
  })
);

export const ListNewDevelopmentForm = () => {
  const classes = useStyles();

  // ---------- Context ---------- //

  const state = useListNewDevelopmentState();
  const dispatch = useListNewDevelopmentDispatch();

  // ---------- Auth ---------- //

  const authToken = useAuthToken();
  const authProfile = useAuthProfile();

  // ---------- Snackbar ---------- //

  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  // ---------- List new development states ---------- //

  const [currentTab, setCurrentTab] = useState('basicDetails');

  const handlePublishDevelopment = async () => {
    try {
      const res = await listNewDevelopment(state, authToken, authProfile);

      if (res instanceof Error) {
        displaySnackbar(SnackbarType.ERROR, res.message);
        return;
      }

      const newDevelopmentUrl = `/property?developmentId=${res.data.newDevelopmentId}`;
      const snackbarContent = (
        <Typography>
          Development with ID {res.data.newDevelopmentId} created successfully
          🎉! Click <Link to={newDevelopmentUrl}>here</Link> to view.
        </Typography>
      );

      displaySnackbar(SnackbarType.SUCCESS, snackbarContent);

      dispatch(clearAllInputs());
    } catch (err) {
      displaySnackbar(SnackbarType.ERROR, (err as Error).message);
    }
  };

  const handleClearAll = () => {
    dispatch(clearAllInputs());
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.ctn}>
          {/* CONTROL PANEL */}

          <Box className={classes.controlPanelCtn}>
            <Button
              className={classes.btn}
              variant="outlined"
              onClick={handleClearAll}
            >
              Clear all
            </Button>

            <ButtonWithProgress
              className={classes.btn}
              variant="contained"
              color="primary"
              onClick={handlePublishDevelopment}
              isLoading={false}
              circularProgressProps={{ color: 'inherit' }}
            >
              Publish
            </ButtonWithProgress>
          </Box>

          {/* TAB SELECTION */}

          <TabsSelectionPanel
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabsCtnProps={{ className: classes.tabsSelectionCtn }}
          />

          {/* TAB CONTENT */}

          <Box className={classes.tabsCtn}>
            <Tab1 currentTab={currentTab} />
            <Tab2 currentTab={currentTab} />
            <Tab3 currentTab={currentTab} />
            <Tab4 currentTab={currentTab} />
            <Tab5 currentTab={currentTab} />
            <Tab6 currentTab={currentTab} />
            <Tab7 currentTab={currentTab} />
          </Box>
        </Box>
      </Box>

      <Snackbar
        type={snackbarState.type}
        show={snackbarState.show}
        hideSnackbar={hideSnackbar}
      >
        {typeof snackbarState.content === 'string' ? (
          <Typography>{snackbarState.content}</Typography>
        ) : (
          snackbarState.content
        )}
      </Snackbar>
    </>
  );
};
